import { Routes, Route } from 'react-router-dom';
import RouteHome from './components/routes/RouteHome';
import RouteHomeAppliances from './components/routes/RouteHomeAppliances';
import RouteSolar from './components/routes/RouteSolar';
import RouteComparator from './components/routes/RouteComparator';

function App() {
  return (
    <Routes>
      <Route path='/*' element={<RouteHome/>} />
      <Route path='/comparador/*' element={<RouteComparator/>} />
      <Route path='/electrodomesticos/*' element={<RouteHomeAppliances/>} />
      <Route path='/solar/*' element={<RouteSolar/>} />
    </Routes>
  );
}

export default App;
