import React from "react";
import rating from "../../../../assets/img/rating.svg";
import "bootstrap-icons/font/bootstrap-icons.css";
import ImgEnergyClass from "./ImgEnergyClass";
import Tooltip from '@mui/material/Tooltip';

export default function RatingResidentialVentilation({item}) {
  
    return (
        <section className="detalle container">
            <div className="marca">
                <Tooltip title="Marca" placement="top">
                    <h1>{item?.supplierOrTrademark}</h1>
                </Tooltip>
                <Tooltip title="Modelo" placement="top">
                    <p>{item?.modelIdentifier}</p>
                </Tooltip>
            </div>
            <div className="row">
                <div className="col-12 energyClass justify-content-between">
                    <Tooltip title="Clases de eficiencia energética" placement="top" className="clase-energetica-tooltip">
                        <img src={rating} alt="rating" />
                    </Tooltip>
                    <ImgEnergyClass energyClass={item?.energyClass} />
                </div>
            
                <div className="col-12 text-center">
                    <hr/>
                    <Tooltip title="Consumo de energía anual" placement="top">
                        <div>
                            <span className="fw-bold fs-5" >{item?.energyAnnual}  </span>
                                kWh electricidad/año 
                        </div>
                    </Tooltip>
                    <hr/>
                </div>
            
                <div className="col-md-6 col-12">
                    <Tooltip title="Potencia de entrada específica" placement="top">
                        <div className="text-center d-flex flex-column align-items-center">
                            <i className="bi bi-lightning-charge-fill fs-5"></i>
                            <p className="mb-0"> <span className="fw-bold fs-5" >{item?.specificPowerInput}</span> W/(m3/h)</p>
                        </div>
                    </Tooltip>
                </div>
                <div className="col-md-6 col-12 ">
                    <Tooltip title="Nivel de potencia acústica" placement="top">
                        <div className="d-flex flex-column align-items-center">
                            <span className="noiseClass"></span>
                            <p><span className="fw-bold fs-5" >{item?.soundPowerLevel}</span> dB</p>
                        </div>
                    </Tooltip>
                </div>

            </div>
            
        </section>

    )
}