import React, { useState } from 'react'
import { Steps } from 'primereact/steps';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import First from './First';
import Second from './Second';
import Confirmation from './Confirmation';

function SolarPanel() {
  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    { label: 'Ubicación' },
    { label: 'Consumo' },
    { label: 'Confirmación' }
  ];

  function handlePrev () {
    setActiveIndex(activeIndex - 1)
  }

  function handleNext () {
    setActiveIndex(activeIndex + 1)
  }

  return (
    <div className='panel-solar mt-3'>
      <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} className='steps'  />

      {activeIndex === 0 && 
        <First handlePrev={handlePrev} handleNext={handleNext} />
      }

      {activeIndex === 1 &&
        <Second handlePrev={handlePrev} handleNext={handleNext} />
      }

      {activeIndex === 2 &&
        <Confirmation handlePrev={handlePrev} setActiveIndex={setActiveIndex}/>
      }

    </div>
  )
}

export default SolarPanel