import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import RatingWashingMachines from "../ratingEnergyEfficient/RatingWashingMachines";
import { useNavigate } from "react-router-dom";


export default function WashingMachines({item, filtered}) {
    const navigate = useNavigate();
    return (
        <div className="row align-items-baseline">
            <h1 className="text-center mb-4">Lavadoras</h1>
            <section className="col-md-8 tabla-detalles">
                <table>
                    <thead>
                        <tr>
                            <th colSpan="2" className="fw-bold fs-4">Información General</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Dimensiones totales</td>
                            <td>{item?.dimensionHeight} (altura) x {item?.dimensionWidth} (anchura) x {item?.dimensionDepth} (profundidad) cm </td>
                        </tr>
                        <tr>
                            <td>Índice de eficiencia energética (IEE)</td>
                            <td>{item?.energyEfficiencyIndex}</td>
                        </tr>
                        <tr>
                            <td>Índice de eficiencia de lavado</td>
                            <td>{item?.washingEfficiencyIndexV2}</td>
                        </tr>
                        <tr>
                            <td>Eficacia de aclarado</td>
                            <td>{item?.rinsingEffectiveness}  g/kg</td>
                        </tr>
                        <tr>
                            <td>Consumo de energía</td>
                            <td>{item?.energyConsPerCycle} kWh</td>
                        </tr>
                        <tr>
                            <td>Consumo de energía (por 100 ciclos)</td>
                            <td>{item?.energyConsPer100Cycle}  kWh</td>
                        </tr>
                        <tr>
                            <td>Consumo de agua</td>
                            <td>{item?.waterCons} L</td>
                        </tr>
                        <tr>
                            <td>Temperatura máxima dentro de la ropa tratada (capacidad asignada)</td>
                            <td>{item?.maxTemperatureRated} °C</td>
                        </tr>
                        <tr>
                            <td>Temperatura máxima dentro de la ropa tratada (mitad)</td>
                            <td>{item?.maxTemperatureHalf} °C</td>
                        </tr>
                        <tr>
                            <td>Temperatura máxima dentro de la ropa tratada (cuarta parte)</td>
                            <td>{item?.maxTemperatureQuarter} °C</td>
                        </tr>
                        <tr>
                            <td>Contenido de humedad residual ponderado</td>
                            <td>{item?.moisture} %</td>
                        </tr>
                        <tr>
                            <td>Clase de eficiencia de centrifugado</td>
                            <td>{item?.spinClass} (A - G)</td>
                        </tr>
                        <tr>
                            <td>Duración del programa (capacidad asignada)</td>
                            <td>{item?.programmeDurationRated} (h:min)</td>
                        </tr>
                        <tr>
                            <td>Duración del programa (mitad)</td>
                            <td>{item?.programmeDurationHalf} (h:min)</td>
                        </tr>
                        <tr>
                            <td>Duración del programa (cuarta parte)</td>
                            <td>{item?.programmeDurationQuarter} (h:min)</td>
                        </tr>
                        <tr>
                            <td>Clase de ruido acústico aéreo emitido (fase de centrifugado)</td>
                            <td>{item?.noiseClass} (A - G)</td>
                        </tr>
                        <tr>
                            <td>Ruido acústico aéreo emitido (fase de centrifugado)</td>
                            <td>{item?.noise} dB(A) re 1 pW</td>
                        </tr>
                    </tbody>
                </table>
            </section>

            <section className="col-md-4">
                <RatingWashingMachines item={item}/>
            </section>

            <button className="col-12 mt-5 btn btn-primary" onClick={() => navigate(`/electrodomesticos/comparador`, {state: {item, filtered}})}> Comparar </button>

        </div>
    )
}