import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import KnowConsumption from "../globals/KnowConsumption";
import GasAppliances from "./GasAppliances";
import SelectorHome from "./SelectorHome";
import GasConsumption from "../globals/GasConsumption";
import PostalCode from "../globals/PostalCode";
import PostalCodeResult from "../results/PostalCode";
import Permanence from "../globals/Permanence";
import AdditionalServices from "../globals/AdditionalServices";
import * as ComparatorService from "../../../services/comparator.service";

function Gas({setLoading, data, isResult}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [knowConsumption, setKnowConsumption] = useState(data?.switchConsumo);
    const [postalCode, setPostalCode] = useState("");
    const [isSwitchOn, setIsSwitchOn] = useState(data?.switchConsumo);
    const [gas, setGas] = useState(0);
    const [valueFamily, setValueFamily] = useState(data?.valueFamilyData);
    const [valueMetros, setValueMetros] = useState(data?.valueMetrosData);
    const [gasConsumption, setGasConsumption] = useState(0);
    const [error, setError] = useState("");
    const [arrayGas, setArrayGas] = useState(data?.valueArrayGas);
    const [additionalServices, setAdditionalServices] = useState(true);
    const [permanence, setPermanence] = useState(true);

    useEffect(() => {
        setKnowConsumption(isSwitchOn);
    }, [isSwitchOn]);

    useEffect(() => {
        if(data !== undefined) {
            setGas(data.consumoAnualG);
            setKnowConsumption(data?.switchConsumo);
            setPostalCode(data.codigoPostal);
            setPermanence(data.permanencia);
            setAdditionalServices(data.serviciosAdicionales);
            if(data.switchConsumo) {
                setGasConsumption(data.consumoAnualG);
            } else {
                setArrayGas(data.valueArrayGas)
                setValueFamily(data.valueFamilyData);
                setValueMetros(data.valueMetrosData);
                setGas(data.consumoAnualG);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, location.state]);

    function onSubmit(e) {
        e.preventDefault();
        setLoading(true);
        if(postalCode === "" || postalCode.length !== 5 || postalCode <= 0){
            setError("Coloca un código postal válido");
            setLoading(false);
            return;
        }

        setError("");

        if(knowConsumption) {
            if(gasConsumption <= 0 || gasConsumption === ""){
                setError("El consumo total debe ser mayor a 0");
                setLoading(false);

                return;
            }

            setError("");
            let data = {
                tipoSuministro:"E",
                codigoPostal:postalCode,
                potencia:3.3,
                consumoAnualE:3000,
                tarifa:4,
                consumoAnualG:gasConsumption,
                serviciosAdicionales:1,
                permanencia:1,
                vivienda:true,
                potenciaPrimeraFranja:3.3,
                potenciaSegundaFranja:3.3,
                potenciaTerceraFranja:3.3,
                potenciaCuartaFranja:3.3,
                potenciaQuintaFranja:3.3,
                potenciaSextaFranja:3.3,
                consumoPrimeraFranja:0,
                consumoSegundaFranja:0,
                consumoTerceraFranja:0,
                consumoCuartaFranja:0,
                consumoQuintaFranja:0,
                consumoSextaFranja:0,
                switchConsumo: true,
                valueArrayGas: arrayGas,
                valueFamilyData: valueFamily,
                valueMetrosData: valueMetros,
                typeUser: 'hogar',
                service: 'gas'
            }

            ComparatorService.getOfferGas(data)
            .then((res) => {
                res.resultadoComparador.forEach((item, index) => {
                    ComparatorService.getDetailOfferGas(item.id, data)
                    .then((detalle) => {
                        res.resultadoComparador[index].detalle = detalle;
                        ComparatorService.getImgOffer(item.id)
                        .then((img) => {
                            if(img.length === 0) {
                                res.resultadoComparador[index].img = 0
                            } else {
                                res.resultadoComparador[index].img = img;
                            }
                            setLoading(false);
                            navigate("/comparador/resultados", {state: {data: data, results: res}});
                        })
                    })
                })
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setError("Ha ocurrido un error, inténtalo de nuevo más tarde");
            });

        } else if(!knowConsumption) {
            if(gas === 0){
                setError("Elige al menos un electrodoméstico");
                setLoading(false);
                return;
            }

            const consumoAnualG = gasConsumption + gas;

            setError("");
            let data = {
                tipoSuministro:"G",
                codigoPostal:postalCode,
                potencia:3.3,
                consumoAnualE:3000,
                tarifa:4,
                consumoAnualG:consumoAnualG,
                serviciosAdicionales:1,
                permanencia:1,
                vivienda:true,
                potenciaPrimeraFranja:3.3,
                potenciaSegundaFranja:3.3,
                potenciaTerceraFranja:3.3,
                potenciaCuartaFranja:3.3,
                potenciaQuintaFranja:3.3,
                potenciaSextaFranja:3.3,
                consumoPrimeraFranja:0,
                consumoSegundaFranja:0,
                consumoTerceraFranja:0,
                consumoCuartaFranja:0,
                consumoQuintaFranja:0,
                consumoSextaFranja:0,
                switchConsumo: false,
                valueArrayGas: arrayGas,
                valueFamilyData: valueFamily,
                valueMetrosData: valueMetros,
                
            }
            ComparatorService.getOfferGas(data)
            .then((res) => {
                res.resultadoComparador.forEach((item, index) => {
                    ComparatorService.getDetailOfferGas(item.id, data)
                    .then((detalle) => {
                        res.resultadoComparador[index].detalle = detalle;
                        ComparatorService.getImgOffer(item.id)
                        .then((img) => {
                            if(img.length === 0) {
                                res.resultadoComparador[index].img = 0
                            } else {
                                res.resultadoComparador[index].img = img;
                            }
                            setLoading(false);
                            navigate("/comparador/resultados", {state: {data: data, results: res}});
                        })
                    })
                })
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setError("Ha ocurrido un error, inténtalo de nuevo más tarde");
            });

        }
    }

    return (
        <div className="luz">
            <div className="container">
               
                <div className="row d-flex align-items-center">
                    <div className="col-md-5 px-0">
                        <KnowConsumption isSwitchOn={isSwitchOn} setIsSwitchOn={setIsSwitchOn} />
                    </div>
                    {isResult ?
                        (
                            <div className="col-md-7 align-self-center px-0">
                                <PostalCodeResult postalCode={postalCode} setPostalCode={setPostalCode} />
                            </div>
                        ) : (
                            <div className="col-md-7 align-self-center px-0">
                                <PostalCode postalCode={postalCode} setPostalCode={setPostalCode} />
                            </div>
                    )}   
                </div>
            </div>
            
            
            {knowConsumption && (
                <div>
                    <GasConsumption
                        gasConsumption={gasConsumption}
                        setGasConsumption={setGasConsumption}
                    />
                </div>

            )}
            {!knowConsumption && (
                <div>
                    <GasAppliances 
                        gas={gas} 
                        setGas={setGas}
                        arrayGas={arrayGas}
                        setArrayGas={setArrayGas}
                    />
                    <SelectorHome 
                        valueFamily={valueFamily}
                        valueMetros={valueMetros}
                        setValueFamily={setValueFamily} 
                        setValueMetros={setValueMetros} 
                    />
                </div>
            )}

            <div className="adicionales">
                <div>
                    <Permanence permanence={permanence} setPermanence={setPermanence} />
                </div>
                <div>
                    <AdditionalServices additionalServices={additionalServices} setAdditionalServices={setAdditionalServices} />
                </div>
            </div>  

            {error !== "" &&
                <div className="alert alert-danger text-center" role="alert">
                    {error}
                </div>
            }
            <div className="form-enviar">
                <button className="btn btn-secondary" type="submit" onClick={onSubmit} >Comparar</button>
            </div>
        </div>
    )
}

export default Gas;