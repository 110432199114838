import React from "react";
import Select from 'react-select';


export default function SelectComponent({options, defaultValue, onChange}) {
    return (
        <Select
            className="basic-single"
            classNamePrefix="Selecciona un producto"
            defaultValue={defaultValue ? defaultValue : null}
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            name="color"
            options={options}
            onChange={onChange}
        />
    );
}