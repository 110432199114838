async function getTerms() {
    const response = await fetch(`https://hirestadium.com/get-terms`);
    const data = await response.json();
    return data;
}

async function getPrivacy() {
    const response = await fetch(`https://hirestadium.com/get-privacy`);
    const data = await response.json();
    return data;
}
  
  export {
    getTerms,
    getPrivacy
  }