import React, { useState, useEffect } from "react";
import Pagination from "../pagination/Pagination";
import FilterMenu from "./FilterMenu";
import Characteristics from '../../characteristics/Characteristics';
import TableResults from "./resultsLists/TableResults";


function SideBar({ items, productGroup }) {
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(10);
    const [filtered, setFiltered] = useState(items);
    const [energyClass, setEnergyClass] = useState([]);
    const [characteristics, setCharacteristics] = useState([])
    const [order, setOrder] = useState("");

    const energyCons100 = items?.map((item) => item.energyCons100);
    const minenergyCons100 = Math.min(...energyCons100);
    const maxenergyCons100 = Math.max(...energyCons100);
    const [rangeCons, setRangeCons] = useState([minenergyCons100, maxenergyCons100]);

    useEffect(() => {
        setFiltered(items)
        let array = []
        Characteristics.forEach((characteristic) => {
            items.forEach((item) => {
                if (item[characteristic.name]) {
                    array.push(characteristic)
                }
            })
        }
        )
        const uniqueArray = [...new Set(array)]
        setCharacteristics(uniqueArray)

    }, [items])

    useEffect(() => {
        setEnergyClass(energyClass);
        setSearch(search);
        setRangeCons(rangeCons);
        filter(search, energyClass, rangeCons, order);
        setCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [energyClass, search, rangeCons, order])


    const indexOfLastPost = currentPage * productsPerPage;
    const indexOfFirstPost = indexOfLastPost - productsPerPage;
    const currentProducts = filtered?.slice(indexOfFirstPost, indexOfLastPost);


    const filter = (search, energyClass, range, order) => {
        let searchWithoutSpaces = search.trim();
        let energyClasses = energyClass.map((item) => {
            return item.value;
        })
        let filtered = items.filter((item) => {
            if (!isNaN(rangeCons[0]) && !isNaN(rangeCons[1])) {
                if (energyClasses.length === 0) {
                    return (
                        (item?.supplierOrTrademark.toLowerCase().includes(searchWithoutSpaces.toLowerCase()) ||
                            item.modelIdentifier.toLowerCase().includes(searchWithoutSpaces.toLowerCase()))
                        && (item?.energyCons100 >= range[0] && item?.energyCons100 <= range[1])
                    )
                } else {
                    return (
                        (item?.supplierOrTrademark.toLowerCase().includes(searchWithoutSpaces.toLowerCase()) ||
                            item.modelIdentifier.toLowerCase().includes(searchWithoutSpaces.toLowerCase()))
                        && energyClasses.includes(item?.energyClass)
                        && (item?.energyCons100 >= range[0] && item?.energyCons100 <= range[1])
                    )
                }
            } else {
                if (energyClasses.length === 0) {
                    return (
                        item?.supplierOrTrademark.toLowerCase().includes(searchWithoutSpaces.toLowerCase()) ||
                        item.modelIdentifier.toLowerCase().includes(searchWithoutSpaces.toLowerCase())
                    )
                } else {
                    return (
                        (item?.supplierOrTrademark.toLowerCase().includes(searchWithoutSpaces.toLowerCase()) ||
                            item.modelIdentifier.toLowerCase().includes(searchWithoutSpaces.toLowerCase()))
                        && energyClasses.includes(item?.energyClass)
                    )
                }
            }
        })

        if (order !== "") {
            if (order === "energyClass") {
                filtered.sort((a, b) => {
                    const orderEnergyClass = ['APPP', 'APP', 'AP', 'A', 'B', 'C', 'D', 'E', 'F', 'G']
                    return orderEnergyClass.indexOf(a.energyClass) - orderEnergyClass.indexOf(b.energyClass)
                })
            } else {
                filtered.sort((a, b) => {
                    if (a[order] < b[order]) {
                        return -1;
                    }
                    if (a[order] > b[order]) {
                        return 1;
                    }
                    return 0;
                })
            }
        }
        setFiltered(filtered);
    }

    return (
        <div className="row sidebar">
            <section className="col-12 row">
                <FilterMenu search={search} setSearch={setSearch} items={items} setEnergyClass={setEnergyClass} setRangeCons={setRangeCons} characteristics={characteristics} order={order} setOrder={setOrder} />
            </section>

            <section className="col-12 px-0 pt-0">
                {currentProducts.length > 0 && (
                    <>
                        <TableResults items={currentProducts} filtered={filtered} characteristics={characteristics} productGroup={productGroup}
                            order={order} setOrder={setOrder}
                        />
                        <p className="items">Mostrando {indexOfFirstPost === 0 ? indexOfFirstPost + 1 : indexOfFirstPost} - {indexOfLastPost < filtered.length ? indexOfLastPost : filtered.length} de {filtered.length} elementos </p>
                    </>
                )}
                {currentProducts.length === 0 ?
                    <div className="text-center h5 mt-5">
                        No se encontraron resultados
                    </div>
                    :
                    <Pagination
                        productsPerPage={productsPerPage}
                        totalProducts={filtered?.length}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            </section>
        </div>
    )
}

export default SideBar;