import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import LuzHogar from "../../components/comparator/home/Light";
import GasHogar from "../../components/comparator/home/Gas";
import LuzGasHogar from "../../components/comparator/home/LightGas";

import LuzNegocio from "../../components/comparator/company/Light";
import GasNegocio from "../../components/comparator/company/Gas";
import LuzGasNegocio from "../../components/comparator/company/LightGas";

import { CircularProgress } from "@mui/material";




function PageComparador() {
    const { user } = useParams();
    const [typeUser, setTypeUser] = useState(user);
    const [service, setService] = useState('luz');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setService(service);
    }, [service]);

    useEffect(() => {
        setTypeUser(typeUser);
    }, [typeUser]);

    useEffect(() => {
        setTypeUser(user);
    }, [user]);

    useEffect(() => {
        if (loading) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }, [loading]);



    function handleService(e) {
        setService(e.target.value);
    }

    return (
        <main className="container comparador min-vh-100">
            <h1 className="text-center">Comparador {user && user}</h1>
            {loading &&
                <div className="text-center mt-5">
                    <h2 className="h5">Estamos bucando las mejores ofertas para ti</h2>
                    <div className="text-center mt-5">
                        <CircularProgress color="inherit" />
                    </div>
                </div>
            }

            {!loading &&
                <section>
                    {!user &&
                        (<div>
                            <h2 className="h5 text-center">¿Eres cliente o empresa?</h2>
                            <div className='caja-servicios'>
                                <Link to={"/comparador/hogar"}>
                                    <div className='caja-hogar'>
                                        <p>Hogar</p>
                                    </div>
                                </Link>

                                <Link to={"/comparador/negocio"}>
                                    <div className='caja-negocio'>
                                        <p>Negocio</p>
                                    </div>
                                </Link>
                            </div>
                        </div>)
                    }
                    {user &&
                        <>
                            <div className="typeService">
                                <label className={service === 'luz' ? 'checked' : 'noChecked'}>
                                    <input type="radio" name="service" value='luz' onChange={handleService}
                                        checked={service === 'luz'} /> Luz
                                </label>
                                <label className={service === 'gas' ? 'checked' : 'noChecked'}>
                                    <input type="radio" name="service" value='gas' onChange={handleService}
                                        checked={service === 'gas'} /> Gas
                                </label>

                                <label className={service === 'luzGas' ? 'checked' : 'noChecked'}>
                                    <input type="radio" name="service" value='luzGas' onChange={handleService}
                                        checked={service === 'luzGas'} /> Luz + Gas
                                </label>
                            </div>

                            {typeUser === 'hogar' && service === 'luz' && <LuzHogar setLoading={setLoading} />}
                            {typeUser === 'hogar' && service === 'gas' && <GasHogar setLoading={setLoading} />}
                            {typeUser === 'hogar' && service === 'luzGas' && <LuzGasHogar setLoading={setLoading} />}

                            {typeUser === 'negocio' && service === 'luz' && <LuzNegocio setLoading={setLoading} />}
                            {typeUser === 'negocio' && service === 'gas' && <GasNegocio setLoading={setLoading} />}
                            {typeUser === 'negocio' && service === 'luzGas' && <LuzGasNegocio setLoading={setLoading} />}
                        </>
                    }
                </section>

            }

        </main>
    )
}

export default PageComparador