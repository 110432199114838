import React from "react";
import { useEffect } from "react";

function Pagination ({ productsPerPage, totalProducts, currentPage, setCurrentPage })  {
    const pageNumbers = [];

    const activePage = (e) => {
        const page = document.querySelectorAll(".page-link");
        page.forEach((item) => {
            item.classList.remove("active");
        });
        e.target.classList.add("active");
        window.scrollTo(0, 0)
    };

  for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++) {
    pageNumbers.push(i);
  }

  let maxPages = pageNumbers.length;

  let leftSide = currentPage - 5;
  if(leftSide <= 0 ) leftSide=1;
  let rightSide = currentPage + 5;
  if(rightSide>maxPages) rightSide = maxPages;

  function updateSides(page) {
    leftSide = page - 5;
    if(leftSide <= 0 ) leftSide=1;
    rightSide = page + 5;
    if(rightSide>maxPages) rightSide = maxPages;
  }

  pageNumbers.splice(0, leftSide-1);
  pageNumbers.splice(rightSide-leftSide+1, maxPages);

  const nextPage = (e) => {
    let current = currentPage+1; 
    if(current<maxPages){
      setCurrentPage(current)
      const page = document.querySelectorAll(".page-link");
      page.forEach((item) => {
        item.classList.remove("active");
        if (item.textContent === current.toString()) {
          item.classList.add("active");
        }
      });
      updateSides(current)
    }
  }
  
  const prevPage = () => {
    let current = currentPage-1;
    if(current>1){
      setCurrentPage(current)
      const page = document.querySelectorAll(".page-link");
      page.forEach((item) => {
        item.classList.remove("active");
        if (item.textContent === current.toString()) {
          item.classList.add("active");
        }
      });

      updateSides(current)
    
    }
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
    updateSides(pageNumber)
  }

  useEffect(() => {
    const page = document.querySelectorAll(".page-link");
    page.forEach((item) => {
      item.classList.remove("active");
      if (item.textContent === currentPage.toString()) {
        item.classList.add("active");
      }
    });
  }, [currentPage])
  
  return (
    <nav className="d-flex justify-content-center flex-wrap">
      <ul className='pagination '>
        {currentPage !== 1 &&
          <li className="page-item">
            <button
              onClick={prevPage}
              className="page-link"
            >
              Prev
            </button>
          </li>
        }
        {pageNumbers.map(number => (
          <li key={number} className="page-item">
            <button onClick={function(event) {
                paginate(number)
                activePage(event)
                
                }} className="page-link">
              {number}
            </button>
          </li>
        ))}
        {currentPage !== maxPages &&
          <li className="page-item">
            <button
              onClick={nextPage}
              className="page-link"
            >
              Next
            </button>
          </li>
        }
      </ul>
    </nav>
  );
}

export default Pagination