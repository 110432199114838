import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../assets/img/logo.png'

function Header() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [routeCurrent, setRouteCurrent] = useState('');

  function handleMenuClick() {
    setIsMenuOpen(!isMenuOpen);
  }

  function handleLinkClick() {
    setIsMenuOpen(false);
  }


  useEffect(() => {
    setRouteCurrent(location.pathname)
  }, [location.pathname])

  useEffect(() => {

    function handleScroll() {
      setIsMenuOpen(false);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <header>
        <Link to={"/"} id="logo">
          <img src={logo} alt="EnergyStar" />
        </Link>

        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container">
            <button className="navbar-toggler" type="button" onClick={handleMenuClick} aria-expanded={isMenuOpen} aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto">
                <li className='nav-item'>
                  <Link className={`nav-link ${routeCurrent === '/comparador/hogar' ? 'active' : ''}`} to={"/comparador/hogar"} onClick={handleLinkClick}>
                    Hogar
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className={`nav-link ${routeCurrent === '/comparador/negocio' ? 'active' : ''}`} to={"/comparador/negocio"} onClick={handleLinkClick}>
                    Negocio
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className={`nav-link ${routeCurrent === '/precio-luz' ? 'active' : ''}`} to={"/precio-luz"} onClick={handleLinkClick}>
                    Precios luz hoy
                  </Link>
                </li>
                <li className={`nav-item ${routeCurrent.includes('/electrodomesticos') ? 'active' : ''}`}>
                  <Link className="nav-link" to={"/electrodomesticos"} onClick={handleLinkClick}>
                    Compara electrodomésticos
                  </Link>
                </li>
                <li className={`nav-item ${routeCurrent.includes('/solar') ? 'active' : ''}`}>
                  <Link className="nav-link" to={"/solar"} onClick={handleLinkClick}>
                    Energía Solar
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="/#" role="button" aria-expanded="false">Nosotros</a>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to={"/conocenos"} onClick={handleLinkClick}>Conocenos</Link></li>
                    <li><Link className="dropdown-item" to={"/faq"} onClick={handleLinkClick}>FAQ</Link></li>
                    <li><Link className="dropdown-item" to={"/contacto"} onClick={handleLinkClick}>Contactanos</Link></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div id="wrapper"></div>
    </>
  )
}

export default Header