import React from "react";
import SolarPanel from "../../components/solarPanel/SolarPanel";

export default function PageSolar() {
    return(
        <main className="container text-center min-vh-100">
            <h1>Placas Solares</h1>
            <SolarPanel />
            
        </main>
    )
}