import React, { useEffect, useState } from "react";
import FilterPostalCode from '../globals/FilterPostalCode'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


function PostalCode({postalCode, setPostalCode}) {
    const [dataPostalCode, setDataPostalCode] = useState([]);
    const [changePostalCode, setChangePostalCode] = useState(false);

    useEffect(() => {
        const postalCodeStorage = localStorage.getItem("postalCode");
        if(postalCodeStorage){
            setPostalCode(postalCodeStorage);
        }
    }, [setPostalCode])

    const onSwitchAction = () => {
        setChangePostalCode(!changePostalCode);
    };

    const searchPostalCode = (e) => {
        const value = e.target.value;
        const filteredData = FilterPostalCode().filter((postalCode) => {
            return postalCode.cp.includes(value);
        }
        );
        setDataPostalCode(filteredData.slice(0, 5));

        if(value.length <= 5 && FilterPostalCode().some((postalCode) => postalCode.cp === value)){
            setPostalCode(e.target.value);
            localStorage.setItem("postalCode", e.target.value);
        } else {
            setPostalCode("");
            return
        }
    }

    const stylesSwitch = {
        "& .MuiSwitch-track": {
            backgroundColor: changePostalCode ? "var(--naranja) !important":"var(--azul) !important" 
        },
        "& .MuiSwitch-thumb": {
            background: "rgb(230, 126, 34) !important",
            // eslint-disable-next-line
            background: "radial-gradient(circle, rgba(230, 126, 34, 1) 27%, rgba(0, 42, 87, 1) 27%) !important"
        },
        "& .MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: "var(--naranja) !important"
        },
        "& .MuiFormControlLabel-root .MuiFormControlLabel-label": {
            fontFamily: "Jost, sans-serif !important"
        },
        "& .MuiTypography-root": {
            fontFamily: "Jost, sans-serif !important",
            fontSize: ".9em !important"
        },
    }

    return (
        <div className="codigo-postal">
             <FormGroup
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
             >
                <FormControlLabel control={<Switch color="primary" defaultValue={changePostalCode} />} label="Cambiar Código Postal" onChange={onSwitchAction} checked={changePostalCode ? true : false} sx={stylesSwitch}/>
            </FormGroup>

            {changePostalCode && (
                <div className="form-floating my-2">
                    <input type="number" className="form-control" id="floatingInput" list="postalCodeList" onChange={searchPostalCode} defaultValue={postalCode}/>
                    <label htmlFor="floatingInput">Código Postal</label>
                    <datalist id="postalCodeList">
                        {dataPostalCode.map((postalCode, i) => (
                            <option key={i} value={postalCode.cp}>
                                {postalCode.cp} - {postalCode.town} ({postalCode.province})
                            </option>
                        ))}
                    </datalist>
                </div>
            )}

        </div>
    )

}

export default PostalCode;