import React from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

function KnowConsumption({ isSwitchOn, setIsSwitchOn }) {

    const onSwitchAction = () => {
        setIsSwitchOn(!isSwitchOn);
    };


    const stylesSwitch = {
        "& .MuiSwitch-track": {
            backgroundColor: isSwitchOn ? "var(--naranja) !important" : "var(--azul) !important"
        },
        "& .MuiSwitch-thumb": {
            background: "rgb(230, 126, 34) !important",
            // eslint-disable-next-line
            background: "radial-gradient(circle, rgba(230, 126, 34, 1) 27%, rgba(0, 42, 87, 1) 27%) !important"
        },
        "& .MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: "var(--naranja) !important"
        },
        "& .MuiFormControlLabel-root .MuiFormControlLabel-label": {
            fontFamily: "Jost, sans-serif !important",
            fontSize: ".9em !important"
        },
        "& .MuiTypography-root": {
            fontFamily: "Jost, sans-serif !important",
            fontSize: ".9em !important"
        },
    }



    return (
        <div className="consumo">
            <FormGroup sx={{ padding: '0px' }}>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            defaultValue={isSwitchOn}
                            sx={stylesSwitch}
                        />}
                    label="¿Conoces tu consumo?"
                    sx={
                        {
                            "& .MuiFormControlLabel-root .MuiFormControlLabel-label": {
                                fontFamily: "Jost, sans-serif !important",
                                fontSize: ".9em !important"
                            },
                            "& .MuiTypography-root": {
                                fontFamily: "Jost, sans-serif !important",
                                fontSize: ".9em !important"
                            },
                        }
                    }
                    onChange={onSwitchAction}
                    checked={isSwitchOn ? true : false} />
            </FormGroup>
        </div>
    )
}

export default KnowConsumption;