import React from 'react';
import A from '../../../../assets/img/energyClass/a_negro.svg';
import APPP from '../../../../assets/img/energyClass/appp_negro.svg';
import APP from '../../../../assets/img/energyClass/app_negro.svg';
import AP from '../../../../assets/img/energyClass/ap_negro.svg';
import B from '../../../../assets/img/energyClass/b_negro.svg';
import C from '../../../../assets/img/energyClass/c_negro.svg';
import D from '../../../../assets/img/energyClass/d_negro.svg';
import E from '../../../../assets/img/energyClass/e_negro.svg';
import F from '../../../../assets/img/energyClass/f_negro.svg';
import G from '../../../../assets/img/energyClass/g_negro.svg';

function ImgEnergyClass({ energyClass }) {
    if (energyClass === "APPP") {
        return (
            <div className='imgEnergyClass'>
                <p className='tooltip'>Clase energética</p>
                <img src={APPP} alt="Clase energética A+++" />
            </div>
        )
    } else if (energyClass === "APP") {
        return (
            <div className='imgEnergyClass'>
                <p className='tooltip'>Clase energética</p>
                <img src={APP} alt="Clase energética A++" />
            </div>
        )
    }
    else if (energyClass === "AP") {
        return (
            <div className='imgEnergyClass'>
                <p className='tooltip'>Clase energética</p>
                <img src={AP} alt="Clase energética A+" />
            </div>
        )
    }
    else if (energyClass === "A") {
        return (
            <div className='imgEnergyClass'>
                <p className='tooltip'>Clase energética</p>
                <img src={A} alt="Clase energética A" />
            </div>
        )
    }
    else if (energyClass === "B") {
        return (
            <div className='imgEnergyClass'>
                <p className='tooltip'>Clase energética</p>
                <img src={B} alt="Clase energética B" />
            </div>
        )
    }
    else if (energyClass === "C") {
        return (
            <div className='imgEnergyClass'>
                <p className='tooltipEnergy'>Clase energética</p>
                <img src={C} alt="Clase energética C" />
            </div>
        )
    }
    else if (energyClass === "D") {
        return (
            <div className='imgEnergyClass'>
                <p className='tooltipEnergy'>Clase energética</p>
                <img src={D} alt="Clase energética D" />

            </div>
        )
    }
    else if (energyClass === "E") {
        return (
            <div className='imgEnergyClass'>
                <p className='tooltipEnergy'>Clase energética</p>
                <img src={E} alt="Clase energética E" />

            </div>
        )
    }
    else if (energyClass === "F") {
        return (
            <div className='imgEnergyClass'>
                <p className='tooltipEnergy'>Clase energética</p>
                <img src={F} alt="Clase energética F" />

            </div>
        )
    }
    else if (energyClass === "G") {
        return (
            <div className='imgEnergyClass'>
                <p className='tooltipEnergy'>Clase energética</p>
                <img src={G} alt="Clase energética G" />

            </div>
        )
    }


}

export default ImgEnergyClass;