import React from "react";

let faq = [
    {
        title: ' ¿Qué hacemos en Energystar?',
        text: "Comparamos los precios de las distintas comercializadoras eléctricas y de gas,ayudamos al usuario a conseguir el precio de la luz y precio de gas de manera clara y transparente.",
        id: 'panel-uno',
        idData: 'collapse-uno'
    },
    {
        title: '¿Puedo comunicarme con ustedes?',
        text: 'Claro que sí, podrás contactarnos a través del 911 309 222 o al 935 565 000 y nuestros asesores te ayudaran en todo lo que necesites. Recuerda que también podrás enviarnos un correo electrónico a info@energystar.es y responderemos tu consulta a la brevedad.',
        id: 'panel-dos',
        idData: 'collapse-dos'
    },
    {
        title: '¿Cómo se estructura el mercado eléctrico?',
        text: `En España, existen dos tipos de mercados: • Mercado Regulado Disponible para todos los consumidores que tengan una potencia contratada inferior a 10 kilovatios (kW) y ofrece el Precio Voluntario al Pequeño Consumidor (PVPC) como tarifa regulada. El precio es fijado todos los días por el mercado mayorista existiendo 2 tipos de modalidades el precio de luz por horas, disponible para usuarios con contador digital, y Precio Medio Ponderado (PMP) disponible para usuarios con contador analógico. • Mercado Libre El usuario puede contratar una tarifa de electricidad, independientemente de la potencia que tenga contratada. 
        Las tarifas son libres, es decir, podrá pactar con la compañía el precio, los descuentos y condiciones comerciales.`,
        id: 'panel-tres',
        idData: 'collapse-tres'
    }, 
    {
        title: `Comercializadoras y distribuidoras, ¿en qué
        se diferencian?`,
        text: `La empresa comercializadora se dedica a facturar la energía consumida, mientras
        que la distribuidora se encarga de hacer llegar la energía a cada punto de
        suministro. Conocer las diferencias entre ambas es fundamental para conocer el
        mercado energético español.`,
        id: 'panel-cuatro',
        idData: 'collapse-cuatro'
    },
    {
        title: `¿Cómo se estructura el mercado de gas
        natural?`,
        text: `Existen dos tipos de mercados: Mercado Regulado Se encuentran los usuarios que
        consumen menos de 50.000 kWh anuales en su instalación. Se ofrece la TUR o
        Tarifa de Ultimo Recurso donde el precio es fijado de manera trimestral a través
        de subastas de gas natural. Mercado Libre Los usuarios poseen diferentes ofertas
        de tarifas de gas natural por parte de las compañías, en donde obtienen
        descuentos y condiciones comerciales con beneficios.`,
        id: 'panel-cinco',
        idData: 'collapse-cinco'
    },
    {
        title: `¿Como comparamos el precio de la luz?`,
        text: ` Usamos un potente comparador que mantiene las tarifas de energia actualizadas en
        tiempo real para que puedas elegir la mejor opción. Con tan solo tu código
        postal y seleccionando los electrodomesticos que poseen en el domicilio de
        instalacion podremos realizar una estimación de consumo y brindar los precios
        disponibles en el mercado.`,
        id: 'panel-seis',
        idData: 'collapse-seis'
    },
    {
        title: `¿Cómo sé si estoy eligiendo correctamente?`,
        text: `En Energystar nos enfocamos a que el usuario acceda a un servicio de calidad por
        ello nuestros asesores estarán ayudándo en todo el proceso para que tu única
        elección sea ahorrar.`,
        id: 'panel-siete',
        idData: 'collapse-siete'
    },
    {
        title: `¿Qué es una distribuidora de luz y gas?`,
        text: `Las distribuidoras de luz y gas son las empresas que se encargan de llevar la
        energía hasta el punto de suministro a través de la red de distribución. se
        encargan del mantenimiento y gestión de la red, ocupándose de solucionar las
        posibles averías que puedan surgir en la misma.`,
        id: 'panel-ocho',
        idData: 'collapse-ocho'
    },
    {
        title: `¿Cómo puedo saber el precio de la luz de hora a
        hora?`,
        text: `Para conocer el precio de la luz en tiempo real puede ingresar a tarifaluzhoy.com
        Tendrás información actualizada y además puedes ver el precio de la luz mañana.`,
        id: 'panel-nueve',
        idData: 'collapse-nueve'
    },
    {
        title: `¿Cuáles son las principales compañías
        energéticas?`,
        text: `Las principales compañias de luz y gas son: Endesa Iberdrola Naturgy (Gas Natural
            Fenosa) EDP Repsol (Viesgo).`,
        id: 'panel-diez',
        idData: 'collapse-diez'
    },
]

function Faq() {
    return (
        <div className="accordion" id="accordionExample">
            {faq.map((question, i) => (
                <div className="accordion-item" key={i}>
                    <h2 className="accordion-header mb-0" id={question.id}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#" + question.idData} aria-expanded="false" aria-controls={question.idData}>
                            {question.title}
                        </button>
                    </h2>
                    <div id={question.idData} className="accordion-collapse collapse" aria-labelledby={question.id}
                    data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            {question.text}
                        </div>
                    </div>
                </div>
            ))}
      </div>
    );
}

export  default Faq;