import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import ScrollToTop from '../../utilities/ScrollTop';
import Home from '../../pages/home/PageHome';
import Contacto from '../../pages/home/PageContacto.jsx';
import Conocenos from '../../pages/home/PageConocenos.jsx';
import Faq from '../../pages/home/PageFaq.jsx';
import PageTerms from '../../pages/home/PageTerms.jsx';
import PagePrivacy from '../../pages/home/PagePrivacy.jsx';
import PageLightPrice from '../../pages/lightPrice/PageLightPrice.jsx';


export default function RouteHome() {
    return (
        <>
        <Header/>
        <ScrollToTop/>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/conocenos' element={<Conocenos/>} />
          <Route path='/faq' element={<Faq/>} />
          <Route path='/contacto' element={<Contacto/>} />
          <Route path='/terms' element={<PageTerms/>} />
          <Route path='/privacy' element={<PagePrivacy/>} />
          <Route path='/precio-luz' element={<PageLightPrice/>} />
        </Routes>
        <Footer/>
        </>
    )
}