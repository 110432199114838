import React from 'react'
import { Link} from 'react-router-dom'
import BtnAction from '../../components/btn/BtnAction'
import Faq from '../../components/faq/Faq'
import Marcas from '../../components/home/Brands'
//FOTOS
import portada from '../../assets/img/portada.svg'
import preciosLuz from '../../assets/img/precios_luz.png'
import energiaVerde from '../../assets/img/energia_verde.png'
import consumoElectrodomesticos from '../../assets/img/consumo_electrodomesticos.png'
import blog from '../../assets/img/blog.png'
import facturas from '../../assets/img/facturas.svg'
import faq from '../../assets/img/faq.svg'




function Home() {
    return (
        <main className='min-vh-100'>
            <section className='container home'>
                <h1 className='visually-hidden'>EnergyStar</h1>
                <div className="row d-flex align-items-center justify-content-center ">
                    <h2 className='col-12'>Tarifas de luz y gas adaptadas a ti</h2>
                    <div className='col-md-6'>
                        <p className='h5 text-center'>¿Quieres ahorrar en tu factura de la luz y gas?</p>

                        <div className='caja-servicios'>
                            <Link to={"/comparador/hogar"}>  
                                <div className='caja-hogar'>
                                    <p>Hogar</p>
                                </div>
                            </Link>

                            <Link to={"/comparador/negocio"}>  
                                <div className='caja-negocio'>
                                    <p>Negocio</p>
                                </div>
                            </Link>
                        </div>

                        <Link to={"/comparador"} className="btn btn-secondary text-center btn-escoge">
                            ¿Qué deseas comparar?
                        </Link>

                    </div>
                    <div className="col-md-6 px-0">
                        <img src={portada} alt="Personas haciendo cuentas para ahorrar en luz y gas." />
                        <div></div>
                    </div>
                </div>
               
            </section>

            <section>
                <Marcas />
            </section>

            <section className='servicios'>
                <h2>Nuestros Servicios</h2>
                <div className="container">
                    <div className="row text-center  justify-content-between">
                        <div className="col-xl-3 col-12 shadow bg-white py-4 px-4 d-flex flex-column" >
                            <div className="contenedor-img">
                                <img src={preciosLuz} alt="Precios luz de hoy" width="100" className="img-fluid mb-3"/>
                            </div>
                            <h3 className="mb-2 h4">Precios luz hoy</h3>
                            <p className=" text-muted">
                                    Conozca el precio de luz por hora, manténgase informado y conozca a que hora es mas barata la luz hoy y mañana.
                            </p>
                            <div className='mt-md-auto'>
                                <BtnAction buttonText={"Ver precios"} route="/precio-luz"></BtnAction>
                            </div>
                        </div>
                        
                        <div className="col-xl-3 col-12 shadow bg-white py-4 px-4 d-flex flex-column" >
                            <div className="contenedor-img">
                                <img src={consumoElectrodomesticos} alt="Consumo electrodomesticos" width="100" className="img-fluid mb-3"/>
                            </div>
                            <h3 className="mb-2 h4">Electrodomésticos</h3>
                            <p className=" text-muted">
                                    Compare los consumos de sus electrodomésticos, ¿sabe cuanto consume su lavadora? ¿Qué equipo consume menos? 
                            </p>
                            <div className="mt-md-auto">
                                <BtnAction buttonText={"Comparar"} route="/electrodomesticos"></BtnAction>
                            </div>
                        </div>
                        
                        <div className="col-xl-3 col-12 shadow bg-white  py-4 px-4 d-flex flex-column" >
                            <div className="contenedor-img">
                                <img src={energiaVerde} alt="Energía Verde" width="100" className="img-fluid mb-3"/>
                            </div>
                            <h3 className="mb-2 h4">Energía Verde</h3>
                            <p className=" text-muted">
                                    Conozca todas las ventajas de la energía renovable, ahorre en consumo energético. Conozca nuestro sitio exclusivo en energía verde.
                            </p>
                            <div className="mt-md-auto">
                                <BtnAction buttonText={"Ir al sitio web"} route="/"></BtnAction>
                            </div>
                        </div>
                      
                        <div className="col-xl-3 col-12 shadow bg-white py-4 px-4 d-flex flex-column" >
                            <div className="contenedor-img">
                                <img src={blog} alt="Blog Energystar" width="100" className="img-fluid mb-3"/>
                            </div>
                            <h3 className="mb-2 h4">Blog Energystar</h3>

                            <p className=" text-muted">
                                    Nuestro exclusivo blog de Energystar te permitirá estar informado de los tramites de luz y gas. Vea nuestros consejos para ahorrar dinero en su factura de luz y gas.
                            </p>
                            <div className='mt-md-auto'>
                                <BtnAction buttonText={"Ir al Blog"} route="/"></BtnAction>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </section>


            <section className='factura row align-items-center justify-content-center bg-gray'> 
                <div className="col-md-6 text-right">
                    <img src={facturas} alt="Foto de la factura energética" />
                </div>

                <div className="col-md-6 text-center">
                    <h2 className='h2'>¡Conoce tu <span className='naranja'>factura</span>  energética!</h2>
                    <p>Esta guía simple, te ayuda a comprender tu factura eléctrica.</p>
                    <Link className='btn btn-secondary' to={'/'}> Aprende a leer tu factura</Link>
                </div>
            </section>

            <section className='faq container'>
                <h2>Preguntas Frecuentes</h2>
                <div className="row align-items-center">
                    <div className="col-md-10">
                        <Faq/>
                    </div>
                    <div className="col-md-3 img">
                        <div></div>
                        <img src={faq} alt="Ilustración de una persona con un signo de pregunta"/>
                    </div>
                </div>
            </section>


            


        </main>


    )
}

export default Home


