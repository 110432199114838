import React, { useEffect, useState } from "react";
import ElectricalAppliances from "./ElectricalAppliances";
import { MapContainer, TileLayer, Marker, Polygon } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

delete L?.Icon?.Default?.prototype._getIconUrl;
L?.Icon?.Default?.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
  iconUrl: require("leaflet/dist/images/marker-icon.png").default,
  shadowUrl: require("leaflet/dist/images/marker-shadow.png").default,
});

function Second({ handlePrev, handleNext }) {
  const [electric, setElectric] = useState(localStorage.getItem("electric") ? localStorage.getItem("electric") : 0)
  const [arrayElectric, setArrayElectric] = useState(JSON.parse(localStorage.getItem("arrayElectric")) ? JSON.parse(localStorage.getItem("arrayElectric")) : []);
  const [isBtnDisable, setIsBtnDisable] = useState(true)
  const [loss, setLoss] = useState(localStorage.getItem("loss") ? localStorage.getItem("loss") : "0");
  const [orientation, setOrientation] = useState(JSON.parse(localStorage.getItem("orientation")) ? JSON.parse(localStorage.getItem("orientation")) : "south");
  const [inclination, setInclination] = useState(localStorage.getItem("inclination") ? localStorage.getItem("inclination") : 90);
  const [power, setPower] = useState(localStorage.getItem("power") ? localStorage.getItem("power") : 3000);

  const mapCenter = JSON.parse(localStorage.getItem("center"));
  const formattedCenter = [mapCenter?.lat, mapCenter?.lng];
  const mapZoom = 23;
  const mapCoords = JSON.parse(localStorage.getItem("coords"));

  const markerIcon = new L.Icon({
    iconUrl: 'https://cdn.jsdelivr.net/leaflet/1.0.0-rc.3/images/marker-icon.png',
    shadowUrl: 'https://cdn.jsdelivr.net/leaflet/1.0.0-rc.3/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  useEffect(() => {
    if (arrayElectric.length > 0 && loss !== "" && orientation !== "" && inclination !== 0 && power !== 0) {
      setIsBtnDisable(false)
    } else {
      setIsBtnDisable(true)
    }
  }, [arrayElectric, loss, orientation, inclination, power, setIsBtnDisable]);

  useEffect(() => {
    if (arrayElectric?.length > 0 && loss !== "" && orientation !== "" && inclination !== 0 && power !== 0) {
      localStorage.setItem("electric", electric);
      localStorage.setItem("arrayElectric", JSON.stringify(arrayElectric));
      localStorage.setItem("loss", loss);
      localStorage.setItem("orientation", JSON.stringify(orientation));
      localStorage.setItem("inclination", inclination);
      localStorage.setItem("power", power);
    }
  }, [arrayElectric, electric, loss, orientation, inclination, power]);

  useEffect(() => {
    if (!mapCenter || !mapCoords) {
      handlePrev();
    }
  }, [mapCenter, mapCoords, handlePrev]);

  return (
    <section>
      <h2>Ahora, selecciona tus electrodomésticos y responde las preguntas</h2>
      <ElectricalAppliances electric={electric} setElectric={setElectric} arrayElectric={arrayElectric} setArrayElectric={setArrayElectric} />

      <section className='preguntas container mt-3'>
        <div>
          <label className='form-label' htmlFor='perdida'>Porcentaje de Pérdida</label>
          <select id='perdida' placeholder="Porcentaje de Pérdida" className='form-control' value={loss} onChange={e => setLoss(e.target.value)} >
            <option value="" disabled>Seleccionar</option>
            <option value="1">SI</option>
            <option value="0">NO</option>
          </select>
        </div>
        <div>
          <label htmlFor='orientation' className='form-label'>Orientación de Placas (Grados)</label>
          <select id='orientation' className='form-control' value={orientation} onChange={e => setOrientation(e.target.value)} >
            <option value="" disabled>Seleccionar</option>
            <option value="south">Sur</option>
            <option value="north">Norte</option>
            <option value="east">Este</option>
            <option value="west">Oeste</option>
          </select>
        </div>
        <div>
          <label className='form-label' htmlFor='inclination'>Inclinación de Placas (Grados)</label>
          <input type="number" defaultValue={inclination} id='inclination' placeholder="Inclinación en Grados" className='form-control'
            onChange={e => setInclination(e.target.value)} />
        </div>
        <div>
          <label className='form-label' htmlFor='power'>Potencia de Instalación</label>
          <input id='power' defaultValue={power} type="number" placeholder="Potencia de Instalación" className='form-control' onChange={e => setPower(e.target.value)} />
        </div>
        <div className='btn-step'>
          <button className='btn btn-secondary' disabled={false} onClick={handlePrev}>Anterior</button>
          <button className='btn btn-primary' disabled={isBtnDisable} onClick={handleNext}>Siguiente</button>
        </div>
      </section>

      <div className="mapa-second">
        {mapCenter && mapCoords && formattedCenter[0] !== undefined && formattedCenter[1] !== undefined && (
          <MapContainer center={formattedCenter} zoom={mapZoom}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Polygon positions={mapCoords} color="blue" />
            {formattedCenter[0] !== 0 && (
              <Marker position={formattedCenter} icon={markerIcon} />
            )}
          </MapContainer>
        )}
      </div>


    </section>
  );
};

export default Second;