import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Header from '../layouts/Header'
import Footer from '../layouts/Footer'
import PageComparator from '../../pages/comparator/PageComparator'
import PageResults from '../../pages/comparator/PageResults';


function RouteComparator() {

    return (
        <>
        <Header />
        <Routes>
            <Route path='/' element={<PageComparator />} />
            <Route path='/:user' element={<PageComparator/>}/>
            <Route path='/resultados' element={<PageResults/>}/>
        </Routes>
        <Footer />
        </>

    )
}

export default RouteComparator;