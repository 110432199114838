import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CardResults from "../../components/comparator/results/CardResults";
import OrderResults from "../../components/comparator/results/OrderResults";
import Pagination from "../../components/comparator/results/Pagination";
import Comparator from "../../components/comparator/results/Comparator";

export default function PageResults() {
    const location = useLocation();
    const navigate = useNavigate();
    const [offer, setOffer] = useState([]);
    const [orden, setOrden] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);

    const handleChangeOrden = (e) => {
        const orden = e.target.value;
        setOrden(orden);
        if (orden === "mayorPrecio") {
            setOffer(offer.sort((a, b) => b.importePrimerAnio - a.importePrimerAnio));
        }
        if (orden === "menorPrecio") {
            setOffer(offer.sort((a, b) => a.importePrimerAnio - b.importePrimerAnio));
        }
        if (orden === "empresa") {
            setOffer(offer.sort((a, b) => a.comercializadora.localeCompare(b.comercializadora)));
        }
    }

    useEffect(() => {
        setCurrentPage(1);
    }, [orden]);

    useEffect(() => {
        if (!location.state || !location.state.results) {
            navigate('/comparador');
        }
        const { data, results } = location.state;
        if (data.service === "luzGas") {
            setOffer(results);
        } else {
            setOffer(results.resultadoComparador);
        }
    }, [location.state, navigate]);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = offer.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <main className="container resultados min-vh-100">
            <h1>Ofertas</h1>
            <p>Estas son las ofertas que hemos encontrado para ti</p>
            <OrderResults orden={orden} setOrden={setOrden} handleChangeOrden={handleChangeOrden} />

            <div className="row justify-content-between">
                <section className="col-md-3 comparador">
                    <h2>Comparador</h2>
                    <Comparator />
                </section>

                {offer.length === 0 ? (
                    <section className="text-center">
                        <p className="h2">No se han encontrado resultados</p>
                    </section>
                ) : (
                    <section className="col-md-9 container cardResultados">
                        {currentPosts.map((offer) => (
                            <CardResults key={offer.id} offer={offer} />
                        ))}
                        <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={offer.length}
                            paginate={paginate}
                            orden={orden}
                        />
                    </section>
                )}
            </div>
        </main>
    );
}
