import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';

import endesa from "../../assets/img/brands/mlg_endesa.jpg";
import iberdrola from "../../assets/img/brands/mlg_iberdr.jpg";
import factor from "../../assets/img/brands/mlg_factor.jpg";
import totalEnergies from "../../assets/img/brands/mlg_total_energy.jpg";
import ufenosa from "../../assets/img/brands/mlg_ufenosa.jpg";
import bassol from "../../assets/img/brands/mlg_bassol.jpg";
import lersa from "../../assets/img/brands/mlg_lersa.jpg";
import algini from "../../assets/img/brands/mlg_algini.jpg";
import escandina from "../../assets/img/brands/mlg_escandina.jpg";
import cardener from "../../assets/img/brands/mlg_cardener.jpg";
import scollernse from "../../assets/img/brands/mlg_sollerense.jpg";
import egl from "../../assets/img/brands/mlg_egl.jpg";
import lucera from "../../assets/img/brands/mlg_lucera.jpg";
import ceuta from "../../assets/img/brands/mlg_ceuta.jpg";
import repsol from "../../assets/img/brands/mlg_repsol.jpg";
import fortuluz from "../../assets/img/brands/mlg_fortuluz.jpg";
import estabanell from "../../assets/img/brands/mlg_estabanell.jpg";
import gesternova from "../../assets/img/brands/mlg_gesternova.jpg";
import ovoenergy from "../../assets/img/brands/mlg_ovoenergy.jpg";
import valira from "../../assets/img/brands/mlg_valira.jpg";
import gdf from "../../assets/img/brands/mlg_gdf.jpg";
import audax from "../../assets/img/brands/mlg_audax.jpg";
import eavellana from "../../assets/img/brands/mlg_eavellana.jpg";
import holaluz from "../../assets/img/brands/mlg_holaluz.jpg";
import aura from "../../assets/img/brands/mlg_aura.jpg";
import aldro from "../../assets/img/brands/mlg_aldro.jpg";
import podo from "../../assets/img/brands/mlg_podo.jpg";
import ganaenergia from "../../assets/img/brands/mlg_ganaenergia.jpg";
import bulb from "../../assets/img/brands/mlg_bulb.jpg";
import womwatt from "../../assets/img/brands/mlg_womwatt.jpg";
import nedgia from "../../assets/img/brands/es-logo-rh-nedgia.jpg"; 
import catgas from "../../assets/img/brands/es-logo-rh-catgas.jpg";



const data = [
    {"id":1,"title":"Endesa","slug":"endesa","image":endesa},
    {"id":2,"title":"Iberdrola","slug":"iberdrola","image": iberdrola},
    {"id":3,"title":"Factor Energia","slug":"factor-energia","image": factor},
    {"id":4,"title":"Total Energies","slug":"total-energies","image": totalEnergies},
    {"id":5,"title":"Naturgy","slug":"naturgy","image": ufenosa},
    {"id":6,"title":"Bassolls","slug":"bassolls","image": bassol},
    {"id":7,"title":"Lersa energia","slug":"lersa-energia","image": lersa},
    {"id":8,"title":"Suministros Especiales Alginetenses","slug":"suministros-especiales-alginetenses","image": algini},
    {"id":9,"title":"Compania Escandinava de Electricidad","slug":"compania-escandinava-de-electricidad","image": escandina},
    {"id":10,"title":"Electra Cardener","slug":"electra-cardener","image": cardener},
    {"id":11,"title":"Electrica Sollorense","slug":"electrica-sollorense","image": scollernse},
    {"id":12,"title":"AXPO","slug":"axpo","image":egl},
    {"id":13,"title":"Lucera","slug":"lucera","image": lucera},
    {"id":14,"title":"Electricidad de Ceuta","slug":"electricidad-de-ceuta","image": ceuta},
    {"id":15,"title":"Repsol","slug":"repsol","image": repsol},
    {"id":16,"title":"Fortuluz","slug":"fortuluz","image": fortuluz},
    {"id":17,"title":"Estabanell Energía","slug":"estabanell-energia","image": estabanell},
    {"id":18,"title":"Ovo Energy","slug":"ovo-energy","image": ovoenergy},
    {"id":19,"title":"Gesternova","slug":"gesternova","image":gesternova},
    {"id":20,"title":"Peusa","slug":"peusa","image": valira},
    {"id":21,"title":"Engie","slug":"engie","image": gdf},
    {"id":22,"title":"Audax","slug":"audax","image": audax},
    {"id":23,"title":"Electra Avellana Comercial","slug":"electra-avellana-comercial","image": eavellana},
    {"id":24,"title":"Hola Luz","slug":"hola-luz","image": holaluz},
    {"id":25,"title":"Aura Energia","slug":"aura-energia","image": aura},
    {"id":26,"title":"Aldro","slug":"aldro","image": aldro},
    {"id":27,"title":"Podo","slug":"podo","image": podo},
    {"id":28,"title":"Esfera Luz","slug":"esfera-luz","image": audax},
    {"id":29,"title":"Gana Energía","slug":"gana-energia","image": ganaenergia},
    {"id":30,"title":"Bulb","slug":"bulb","image": bulb},
    {"id":31,"title":"Wombbat","slug":"wombbat","image":womwatt},
    {"id":32,"title":"Nedgia","slug":"nedgia","image":nedgia},
    {"id":33,"title":"Catgas","slug":"catgas","image":catgas}
]


export default function Brands() {
    const responsive  = {
     0: {
        items: 3
      },
      600: {
        items: 4
      },
      1024: {
        items: 6
      }
    }

    return (
        <>
            <h1 className="visually-hidden">Marcas</h1>
            <div className="brands">
                <AliceCarousel
                    duration={400}
                    autoPlay={true}
                    startIndex = {1}
                    fadeOutAnimation={true}
                    mouseDragEnabled={true}
                    playButtonEnabled={true}
                    responsive={responsive}
                    autoPlayInterval={2000}
                    autoPlayActionDisabled={true}
                >
                    {data.map((item, i) => (
                        <img key={i} src={item.image} alt={item.title} />
                    ))}
                </AliceCarousel>
            </div>
        </>  
    );
}