import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Results from '../../components/solarPanel/Results'
import FormResults from '../../components/solarPanel/FormResults'
import * as SolarService from '../../services/solar.service'
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";


function PageResults() {
    const location = useLocation();
    let navigate = useNavigate();
    const data = location?.state?.data;
    const results = location?.state?.results;
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if (!data || !results) {
            navigate('/solar');
        }
    }, [data, results, navigate])


    function onSubmit(name, phone, email) {
        const dataToSend = {
            name,
            phone,
            email,
            data,
            results
        }
        setLoading(true)
        SolarService.sendContactSolarForm(dataToSend)
            .then(() => {
                setLoading(false)
                Swal.fire({
                    title: '¡Gracias!',
                    text: 'En breve nos pondremos en contacto contigo',
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#363E59',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/solar");
                    }
                })
            })
            .catch(() => {
                setLoading(false)
                Swal.fire({
                    title: '¡Error!',
                    text: 'Ha ocurrido un error, inténtelo de nuevo más tarde',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#363E59',
                })
            })

    }

    return (
        <main className="container panel-solar min-vh-100">
            <h1 className="text-center">Resultados</h1>
            <div className="row mt-3 justify-content-between">
                <div className="col-12 col-md-5 results">
                    <Results results={results} />
                </div>

                <div className="col-12 col-md-6 form-results">
                    <FormResults onSubmit={onSubmit} />
                </div>
                {loading &&
                    <div className="text-center">
                        <CircularProgress />
                    </div>
                }
            </div>
        </main>
    );
}

export default PageResults;