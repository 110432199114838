const Characteristics = [
    {
        name: 'energyClass',
        title: 'Clase de eficiencia energética',
        nameShort: 'Clase',

    },
    {
        name: 'seasonalHeatingEnergyEfficiency',
        title: 'Eficiencia energética de calefacción estacional',
        nameShort: 'Eficiencia energética',

    },
    [
        {
            name: 'dimensionWidth',
            title: 'Ancho',
            measurement: 'cm',
        },
        {
            name: 'dimensionHeight',
            title: 'Altura',
            measurement: 'cm',
        },
        {
            name: 'dimensionDepth',
            title: 'Profundidad',
            measurement: 'cm',
        },
    ],
    {
        name: 'ratedCapacity',
        title: 'Capacidad nominal para el programa eco',
        nameShort: 'Cap. nominal',
        measurement: 'kg'
    },
    {
        name: 'energyCons100',
        title: 'Consumo de energía (basado en el programa eco)',
        nameShort: 'Cons. de energía',
        measurement: 'kWh'
    },

    {
        name: 'waterCons',
        title: 'Consumo de agua',
        nameShort: 'Cons. de agua',
        measurement: 'L'
    },
    {
        name: 'energyEfficiencyIndex',
        title: 'Índice de ef. energética',
        nameShort: 'Eficiencia',
    },
    {
        name: 'noise',
        title: 'Nivel de ruido',
        nameShort: 'Ruido',
        measurement: 'dB'
    },
    {
        name: 'noiseClass',
        title: 'Clase de ruido',
        nameShort: 'Clase de ruido',

    },
    {
        name: 'energyCons',
        title: 'Consumo de energía',
        nameShort: 'Cons. de energía',
        measurement: 'kWh'
    },
    {
        name: 'ratedHeatOutput',
        title: 'Potencia nominal de calefacción',
        nameShort: 'Pot. nominal',
        measurement: 'W'
    },
    {
        name: 'annualHeatingSavedAverageClimate',
        title:'Ahorro anual de calefacción (clima promedio)',
        nameShort:'Ahorro clima prom.' ,
        measurement: 'W'


    },
    {
        name: 'annualHeatingSavedColdClimate',
        title:'Ahorro anual de calefacción (clima frío)',
        nameShort:'Ahorro clima frío',
        measurement: 'W'

    },
    {
        name: 'annualHeatingSavedWarmClimate',
        title:'Ahorro anual de calefacción (clima cálido)',
        nameShort:'Ahorro clima cálido',
        measurement: 'W'
    },
    {
        name: 'fanDrivePowerInput',
        title:'Potencia de entrada del ventilador',
        nameShort:'Pot. de entrada',
        measurement: 'W'
    },
    {
        name: 'maximumExternalLeakageRate',
        title:'Tasa máxima de fuga externa',
        nameShort:'Tasa máx. de fuga ext.',

    },
    {
        name: 'maximumFlowRate',
        title:'Flujo máximo',
        nameShort:'Flujo máximo',

    },
    {
        name: 'maximumInternalLeakageRate',
        title:'Tasa máxima de fuga interna',
        nameShort:'Tasa máx. de fuga int.'
    },
    {
        name: 'referenceFlowRate',
        title:'Flujo de referencia',
        nameShort:'Flujo de ref.'
    },
    {
        name: 'referencePressureDifference',
        title:'Diferencia de presión de referencia',
        nameShort:'Dif. de presión de ref.'
    },
    {
        name: 'soundPowerLevel',
        title:'Nivel de potencia sonora',
        nameShort:'Nivel de pot. sonora',
        measurement: 'dB'
    },
    {
        name: 'specificPowerInput',
        title:'Potencia de entrada específica',
        nameShort:'Pot. de entrada',
        measurement: 'W'
    },
    {
        name: 'thermalEfficiencyHeatRecovery',
        title:'Eficiencia térmica de recuperación de calor',
        nameShort:'Ef. térmica',


    },
    {
        name: 'collectorEfficiency',
        title:'Eficiencia del colector',
        nameShort:'Ef. del colector'
    },
    {
        name: 'packageEnergyEfficiencyIndex',
        title:'Índice de eficiencia energética del paquete',
        nameShort:'Eficiencia'
    },
    {
        name: 'packageEnergyEfficiencyClass',
        title:'Clase de eficiencia energética del paquete',
        nameShort:'Ef. del paquete'
    },
    {
        name: 'solarCollectorSize',
        title:'Tamaño del colector solar',
        nameShort:'Tamaño del colector',
        measurement: 'm2'

    },
    {
        name: 'solarContribution',
        title:'Contribución solar',
        nameShort:'Contribución solar',
        measurement: '%'
    },
    {
        name: 'solarTankRating',
        title:'Calificación del tanque solar',
        nameShort:'Cal. del tanque solar',
    },
    {
        name: 'solarTankVolume',
        title:'Volumen del tanque solar',
        nameShort:'Vol. del tanque',

    },
    {
        name: 'temperatureControlClass',
        title:'Clase de control de temperatura',
        nameShort:'Control de temperatura'
    },
    {
        name: 'energyClassWash',
        title:'Clase de energía de lavado',
        nameShort:'Clase lavado'
    },
    {
        name: 'energyClassWashAndDry',
        title:'Clase de energía de lavado y secado',
        nameShort:'Clase lavado y secado'
    },
    {
        name: 'energyConsumption100Wash',
        title:'Consumo de energía de lavado (por 100 ciclos)',
        nameShort:'Cons. lavado (por 100 ciclos)',
        measurement: 'kWh'
    },
    {
        name: 'energyConsumption100WashAndDry',
        title:'Consumo de energía de lavado y secado (por 100 ciclos)',
        nameShort:'Cons. lavado y secado (por 100 ciclos)',
        measurement: 'kWh'
    },
    {
        name: 'energyConsumptionWash',
        title:'Consumo de energía de lavado',
        nameShort:'Cons. de energía de lavado',
        measurement: 'kWh'
    },
    {
        name: 'energyConsumptionWashAndDry',
        title:'Consumo de energía de lavado y secado',
        nameShort:'Cons. de energía de lavado y secado',
        measurement: 'kWh'
    },
    {
        name: 'energyEfficiencyIndexWash',
        title:'Índice de eficiencia energética de lavado',
        nameShort:'Ef. de lavado',

    },
    {
        name: 'energyEfficiencyIndexWashAndDry',
        title:'Índice de eficiencia energética de lavado y secado',
        nameShort:'Ef. de lavado y secado'
    },
    {
        name: 'maxTempHalfWash',
        title:'Temperatura máxima de lavado a media carga',
        nameShort:'Temp. máx. de lavado (media carga)',
    },
    {
        name: 'maxTempHalfWashAndDry',
        title:'Temperatura máxima de lavado y secado a media carga',
        nameShort:'Temp. máx. de lavado y secado (media carga)'
    },
    {
        name: 'maxTempQuarterWash',
        title:'Temperatura máxima de lavado a cuarto de carga',
        nameShort:'Temp. máx. de lavado (cuarto de carga)'
    },
    {
        name: 'maxTempRatedWash',
        title:'Temperatura máxima de lavado a carga completa',
        nameShort:'Temp. máx. de lavado (carga completa)'
    },
    {
        name: 'maxTempRatedWashAndDry',
        title:'Temperatura máxima de lavado y secado a carga completa',
        nameShort:'Temp. máx. de lavado y secado (carga completa)'
    },
    {
        name: 'moisture',
        title:'Humedad',
        nameShort:'Humedad',
        measurement: '%'
    },
    {
        name: 'programDurationHalfWash',
        title:'Duración del programa de lavado a media carga',
        nameShort:'Dur. lavado (media carga)',
        measurement: 'min'
    },
    {
        name: 'programDurationHalfWashAndDry',
        title:'Duración del programa de lavado y secado a media carga',
        nameShort:'Dur. lavado y secado (media carga)',
        measurement: 'min'
    },
    {
        name: 'programDurationRatedWash',
        title:'Duración del programa de lavado a carga completa',
        nameShort:'Dur. lavado (carga completa)',
        measurement: 'min'
    },
    {
        name: 'programDurationRatedWashAndDry',
        title:'Duración del programa de lavado y secado a carga completa',
        nameShort:'Dur. lavado y secado (carga completa)',
        measurement: 'min'
    },
    {
        name: 'ratedCapacityWash',
        title:'Capacidad de lavado',
        nameShort:'Cap. de lavado',
    },
    {
        name: 'ratedCapacityWashAndDry',
        title:'Capacidad de lavado y secado',
        nameShort:'Cap. de lavado y secado',
    },
    {
        name: 'spinClass',
        title:'Clase de centrifugado',
        nameShort:'Clase centrifugado'
    },
    {
        name: 'waterConsumptionWash',
        title:'Consumo de agua de lavado',
        nameShort:'Cons. de agua de lavado',
        measurement: 'L'
    },
    {
        name: 'waterConsumptionWashAndDry',
        title:'Consumo de agua de lavado y secado',
        nameShort:'Cons. de agua de lavado y secado',
        measurement: 'L'
    },
    {
        name: 'programmeDurationHalf',
        title:'Duración del programa a media carga',
        nameShort:'Duración (media carga)',
        measurement: 'min'
    },
    {
        name: 'programmeDurationQuarter',
        title:'Duración del programa a cuarto de carga',
        nameShort:'Duración (cuarto de carga)',
        measurement: 'min'
    },
    {
        name: 'programmeDurationRated',
        title:'Duración del programa a carga completa',
        nameShort:'Duración (carga completa)',
        measurement: 'min'
    },

    {
        name: 'storageVolume',
        title:'Volumen de almacenamiento',
        nameShort:'Vol. de almacenamiento',

    },
    {
        name: 'zeroLossEfficiency',
        title:'Rendimiento a cero pérdidas',
        nameShort:'Rend. a cero pérdidas'
    },
    {
        name: 'coolingDesignLoad',
        title:'Carga de diseño de refrigeración',
        nameShort:'Carga de refrigeración',
    },
    {
        name: 'coolingEnergyClass',
        title:'Clase energética de refrigeración',
        nameShort:'Clase de refrigeración'
    },
    {
        name: 'heatingDesignLoad',
        title:'Carga de diseño de calefacción',
        nameShort:'Carga de calefacción'
    },
    {
        name: 'heatingEnergyClass',
        title:'Clase energética de calefacción',
        nameShort:'Clase de calefacción'
    },
    {
        name: 'refrigerantGwp',
        title:'GWP del refrigerante',
        nameShort:'GWP del refrigerante',
        measurement: 'GWP'
        
    },
    {
        name: 'refrigerantName',
        title:'Nombre del refrigerante',
        nameShort:'Refrigerante'
        
    },
    {
        name: 'type',
        title:'Tipo',
        nameShort:'Tipo'
    },
]

export default Characteristics


