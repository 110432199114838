import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const styles = {
    "& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen": {
        backgroundColor: 'var(--naranja) !important',
    },
    "& .MuiSlider-valueLabelLabel": {
        fontSize: '1rem',
        fontWeight: '500',
    },
    "& .MuiTypography-root": {
        fontFamily: "Jost, sans-serif !important"
    },
    "& .MuiSlider-thumb": {
        background: 'rgb(230, 126, 34) !important',
        // eslint-disable-next-line
        background: 'radial-gradient(circle, rgba(230, 126, 34, 1) 27%, rgba(0, 42, 87, 1) 27%) !important',
    },
    "& .MuiSlider-markLabel, & .MuiSlider-markLabel": {
        fontSize: '0.6rem !important',
        fontWeight: '400 !important',
        top: '25px',
        fontFamily: "Jost, sans-serif !important"
    },
    "& .MuiSlider-root": {
        color: 'var(--naranja) !important',
        maxWidth: '100% !important',
    },
    "& .MuiSlider-track": {
        color: 'var(--azul) !important',
    },
    "& .MuiSlider-rail": {
        color: 'var(--azul) !important',
    },
    "& .MuiSlider-root.MuiSlider-sizeMedium.MuiSlider-marked": {
        marginBottom: '0px !important',
    }
}

const family = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 3,
        label: '3',
    },
    {
        value: 4,
        label: '4',
    },
    {
        value: 5,
        label: '5',
    },
];

const metros = [
    {
        value: 50,
        label: '50',
    },
    {
        value: 88,
        label: '88',
    },
    {
        value: 125,
        label: '125',
    },
    {
        value: 163,
        label: '163',
    },
    {
        value: 200,
        label: '200',
    },
];

function SelectorHome({ valueFamily, setValueFamily, valueMetros, setValueMetros, valueFamilyData, valueMetrosData }) {

    function valuetext(value) {
        return `${value}`;
    }

    function handleChangeFamily(event, newValue) {
        setValueFamily(newValue);
    }

    function handleChangeMetros(event, newValue) {
        setValueMetros(newValue);
    }

    useEffect(() => {
        if (valueFamilyData !== undefined) {
            setValueFamily(valueFamilyData);
        } else {
            setValueFamily(1);
        }
        if (valueMetrosData !== undefined) {
            setValueMetros(valueMetrosData);
        } else {
            setValueMetros(50);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueFamilyData, valueMetrosData])


    return (
        <div className="row selector-hogar">
            <div className="col-md-6">
                <h3>¿Cuántas personas viven en tu hogar?</h3>
                <Box sx={{ width: 290, margin: '0 auto'}}>
                    <Slider
                        key={`slider-${valueFamily}`}
                        aria-label="Small"
                        defaultValue={valueFamily}
                        getAriaValueText={valuetext}
                        step={1}
                        marks={family}
                        valueLabelDisplay="auto"
                        min={1}
                        max={5}
                        onChange={handleChangeFamily}
                        sx={styles}
                    />
                </Box>
            </div>

            <div className="col-md-6">
                <h3>¿Cuántos metros² tiene tu hogar?</h3>
                <Box sx={{ width: 290, margin: '0 auto' }}>
                    <Slider
                        key={`slider-${valueMetros}`}
                        aria-label="Small"
                        defaultValue={valueMetros}
                        getAriaValueText={valuetext}
                        step={1}
                        marks={metros}
                        valueLabelDisplay="auto"
                        min={50}
                        max={200}
                        onChange={handleChangeMetros}
                        sx={styles}
                    />
                </Box>
            </div>
        </div>


    )
}

export default SelectorHome;