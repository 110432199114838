import React from "react";
import RatingAirconditioners from "./RatingAirconditioners";
import RatingDishwashers from "./RatingDishwashers";
import RatingResidentialVentilation from "./RatingResidentialVentilation";
import RatingSolidFuelBoilers from "./RatingSolidFuelBoilers";
import RatingSolidFuelBoilersPackages from "./RatingSolidFuelBoilersPackages";
import RatingSpaceHeaterTemperatureControl from "./RatingSpaceHeaterTemperatureControl";
import RatingWaterHeaterSolarDevices from "./RatingWaterHeaterSolarDevices";

export default function RatingEnergyEfficient({ productGroup, item }) {
    switch (productGroup) {
        case "airconditioners": return <RatingAirconditioners item={item} />;
        case "dishwashers2019": return <RatingDishwashers item={item} />;
        case "residentialventilationunits": return <RatingResidentialVentilation item={item} />;
        case "solidfuelboilerpackages": return <RatingSolidFuelBoilersPackages item={item} />;
        case "solidfuelboilers": return <RatingSolidFuelBoilers item={item} />;
        case "spaceheatertemperaturecontrol": return <RatingSpaceHeaterTemperatureControl item={item} />;
        case "washerdriers2019": return <RatingDishwashers item={item} />;
        case "washingmachines2019": return <RatingDishwashers item={item} />;
        case "waterheatersolardevices": return <RatingWaterHeaterSolarDevices item={item} />;
        default: return <div></div>;
    }
}