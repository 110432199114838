import React, {useEffect, useState, useCallback} from 'react';
import { PieChart, Pie, Cell, Sector } from "recharts";
import svg from '../../assets/img/hour.svg';


export default function Chart({dayPrices, minDayPrice, maxDayPrice, betweenRange}) {
  const [styleHour, setStyleHour] = useState({});
  const [data, setData] = useState([]);
  const [hourColor, setColorHour] = useState('#3A3A3A')
  const [hourPrice, setHourPrice] = useState(0)
  const [styleDot, setStyleDot] = useState({})
  const [styleDotBefore, setStyleDotBefore] = useState({})
  const [currentTime, setCurrentTime] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );



  dayPrices.forEach((item) => {
    
      let horaInicio = item.time.split("-")[0];
      let horaFin = item.time.split("-")[1];
  
      item.HoraInicio = parseFloat(horaInicio);
      item.HoraFin = parseFloat(horaFin);
  
  
      if (item.price  < (minDayPrice + betweenRange)) {
          item.color = "#1C8C4D"; //verde
      } else if (item.price > minDayPrice + (betweenRange * 2)){ 
          item.color = "#FFC108"; //amarillo
      } else if (item.price >= (minDayPrice + betweenRange) && item.price <= (minDayPrice + (betweenRange * 2))) {
          item.color = "#D93D3D"; //rojo
      } else {
          item.color = "#808080"; //gris
      }

  })

  function styleDotAct(hourColor) {
    setStyleDot({
      backgroundColor: hourColor,
      borderRadius: '50%',
      position: 'absolute',
      zIndex: '2',
      width: '70px',
      height: '70px',
      top: 0,
      left: 1,
      boxShadow: '0 0 0 5px #3a3a3a',
    })

    setStyleDotBefore({
      color: 'white',
      // textShadow: '0 0 0.5px black',
      textAlign: 'center',
      position: 'absolute',
      transform: 'translate(-50%, 40%)',
      zIndex: '4',
      lineHeight: '1.2',
    })



  }

 
  function clock(date) {
    setStyleHour({
        transform: `rotate(${date.getHours() * 15}deg)`,
    })

    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    setCurrentTime(hour + ":" + minutes + ":" + seconds);

    // setStyleMinute({
    //   transform: `rotate(${date.getMinutes() * 6}deg)`,
    // })

    // setStyleSecond({
    //   transform: `rotate(${date.getSeconds() * 6}deg)`,
    // })

  }



  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    name,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.9;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {name}
      </text>
    );
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
  
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${payload.name} hs`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`${(payload.price * 0.001).toFixed(3)} €/kWh`}
        </text>
      </g>
    );
  };


  useEffect(() => {
    const interval = setInterval(() => {
      clock(new Date());
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [])  

  useEffect(() => {
    let date = new Date();
    if (data.length !== 0) {
      data.forEach((item) => {
        if (item.name === date.getHours()) {
          setColorHour(item.fill)
          styleDotAct(item.fill) 
          setHourPrice((item.price * 0.001).toFixed(3))
        }
      })      
    }
    
  }, [hourColor, data])

  useEffect(() => {
    let length = dayPrices.length;
    if (length !== 0) {
      // eslint-disable-next-line
      dayPrices = dayPrices.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.HoraInicio === item.HoraInicio
        ))
      )
      dayPrices = dayPrices.concat(dayPrices.filter((item, index, self) =>
        index !== self.findIndex((t) => (
          t.HoraInicio === item.HoraInicio
        ))
      ))

      dayPrices.forEach((item) => {
        if (item.HoraInicio === 0) {
          item.HoraInicio = 24
        }
      })

      dayPrices.sort((a, b) => {
        return a.HoraInicio - b.HoraInicio
      })

      let dayPrices2 = dayPrices.slice(0, 24);

      setData(dayPrices2.map((item) => {
        return {
          name: item.HoraInicio,
          value: 1,
          price: item.price,
          fill: item.color
        }
      }))
    } else if (length === 0) {
      setData(
        [...Array(24)].map((_, i) => {
          return {
            name: i + 1,
            value: 1,
            price: 0,
            fill: "#3A3A3A"
          }
        })
      )
    }
   // eslint-disable-next-line
  }, [dayPrices])
    


  return(
    <section className='contenedor-chart-precio-luz'>
      <div className='chart-precio-luz'>
        <div className="clock">
          <div className="wrap">
            <span className='hour'>
              <img 
                alt="Aguja del Reloj" 
                src={svg} 
                style={styleHour}
              />
            </span>
            <span className="dot">
              <span className="dot-inner" style={styleDot}>
                {hourPrice &&
                  <span className="dot-before" style={styleDotBefore}>
                    <span style={{fontSize: '.9em', fontWeight: 'bold'}}> {hourPrice} €</span> 
                    <span style={{fontSize: '.8em'}}> {currentTime} </span> <br/>
                  </span> 
                }
              </span>
            </span>
          </div>
        </div>   

        <PieChart width={600} height={400} >
          <Pie
            data={data}
            cx={300}
            cy={200}
            labelLine={false}
            outerRadius={130}
            fill="#3A3A3A"
            dataKey="value"
            label={renderCustomizedLabel}
            startAngle={82}
            endAngle={-360}
            tabIndex={200}

            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            onMouseEnter={onPieEnter}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} 
              fill={entry.fill} 
              />
            ))}
          </Pie>
        </PieChart>
      </div>
    </section>
  )
}



