import React from "react";
import LightPrice from "../../components/lightPrice/LightPrice";
import Notifications from "../../components/lightPrice/Notifications";

export default function PagePrecioLuz() {
    return(
        <main className="container-fluid text-center min-vh-100">
            <h1 className="h3">Precio de la luz según tramo</h1>
            <LightPrice />
            <Notifications />
           
        </main>
    )
}