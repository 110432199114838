import React from "react";

export default function OrderResults({orden, setOrden, handleChangeOrden}) {
    return (
        <div className="d-flex justify-content-end orderBy">
            <label htmlFor="orden" className="form-label">Ordenar por: </label>
            <select name="orden" id="orden" value={orden} onChange={handleChangeOrden} className="form-select">
                <option disabled value="">Seleccionar</option>
                <option value="mayorPrecio">Mayor Precio</option>
                <option value="menorPrecio">Menor Precio</option>
                <option value="empresa">Empresa</option>
            </select>
        </div>
    )

}