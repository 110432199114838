import React, { useState } from 'react';
import * as SolarService from '../../services/solar.service'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { CircularProgress } from "@mui/material";

export default function Confirmation({ handlePrev, setActiveIndex }) {
    let navigate = useNavigate();
    const address = JSON.parse(localStorage.getItem("address"));
    const center = JSON.parse(localStorage.getItem("center"))
    const electric = localStorage.getItem("electric");
    const arrayElectric = JSON.parse(localStorage.getItem("arrayElectric"));
    const loss = JSON.parse(localStorage.getItem("loss"));
    const orientation = JSON.parse(localStorage.getItem("orientation"));
    const inclination = JSON.parse(localStorage.getItem("inclination"));
    const power = JSON.parse(localStorage.getItem("power"));
    const [loading, setLoading] = useState(false)

    let array = [
        {
            id: 1,
            name: "Nevera",
            kwh: 662
        },
        {
            id: 2,
            name: "Lavavajillas",
            kwh: 225
        },
        {
            id: 3,
            name: "Lavadora",
            kwh: 255
        },
        {
            id: 4,
            name: "Ordenador",
            kwh: 172
        },
        {
            id: 5,
            name: "Secadora",
            kwh: 255
        },
        {
            id: 6,
            name: "Horno",
            kwh: 231
        },
        {
            id: 7,
            name: "Cocina",
            kwh: 662
        },
        {
            id: 8,
            name: "TV",
            kwh: 263
        }
    ];

    if (!address || !center || !electric || !arrayElectric || !orientation || !inclination || !power) {
        setActiveIndex(0)
    }

    let selectedItems = array.filter(item => arrayElectric?.includes(item.id)).map(item => item.name).join(', ');

    function deleteLocalStorage() {
        localStorage.removeItem("address");
        localStorage.removeItem("center");
        localStorage.removeItem("electric");
        localStorage.removeItem("arrayElectric");
        localStorage.removeItem("loss");
        localStorage.removeItem("orientation");
        localStorage.removeItem("inclination");
        localStorage.removeItem("power");
        localStorage.removeItem("street");
        localStorage.removeItem("city");
        localStorage.removeItem("number");
        localStorage.removeItem("coords");

    }

    function handleConfirm() {
        const data = {
            address,
            center,
            electric,
            loss,
            orientation,
            inclination,
            power
        }

        setLoading(true)
        SolarService.getSolar(center[0], center[1], orientation, inclination)
            .then(res => {
                setLoading(false)
                let results = res;
                deleteLocalStorage();
                navigate("/solar/resultados", { state: { data: data, results: results } });
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Algo salió mal, vuelve a intentarlo más tarde.',
                    confirmButtonColor: '#363E59'
                })
            })
    }

    function formatOrientation(orientation) {
        switch (orientation) {
            case 'south':
                return 'Sur'
            case 'north':
                return 'Norte'
            case 'east':
                return 'Este'
            case 'west':
                return 'Oeste'
            default:
                return 'Sur'
        }
    }

    return (
        <section className='confirmation'>
            <h2>Confirma la información</h2>
            <div>
                <h3>Dirección</h3>
                <p>{address}</p>
            </div>

            <div>
                <h3>Consumo</h3>
                <p>{electric} kWh <br /> <span> ({selectedItems})</span> </p>
            </div>

            <div>
                <h3>Porcentaje de Pérdida</h3>
                <p>{loss === 1 ? "Si" : "No"}</p>
            </div>

            <div>
                <h3>Orientación de Placas</h3>
                <p>{formatOrientation(orientation)}</p>
            </div>

            <div>
                <h3>Inclinación de Placas</h3>
                <p>{inclination} grados</p>
            </div>

            <div>
                <h3>Potencia de Instalación</h3>
                <p>{power} W</p>
            </div>

            <div className='btn-step mt-4'>
                <button className='btn btn-secondary' onClick={handlePrev}>Anterior</button>

                {loading ?
                    <div className="text-center my-1">
                        <CircularProgress />
                    </div>
                    :
                    <button className='btn btn-primary' onClick={handleConfirm}>Averigua tu cálculo</button>
                }
            </div>

        </section>
    )
}