async function getSolar(lat, lon, orientation, inclination) {
  const response = await fetch(`https://hirestadium.com/solarApi?lat=${lat}&lon=${lon}&azimuth=${orientation}&angle=${inclination}`);
  const data = await response.json();
  return data;
}


async function sendContactSolarForm(data) {
  const response = await fetch('https://hirestadium.com/api/contact-solar-form', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return await response.json();
}



export {
  getSolar,
  sendContactSolarForm,

}