import React from "react";

import conocenos from "../../assets/img/conocenos.svg";
import conocenos_ahorro from "../../assets/img/conocenos_ahorro.svg";

export default function PageConocenos() {
    return (
        <main className="container conocenos min-vh-100">
            <h1 className="text-center">Conocenos</h1>
            <article className="row d-flex align-items-center">
                <div className="col-md-6">
                    <h2>Energystar, un equipo joven con fuertes convicciones</h2>
                    <p>Somos un equipo joven interesados en proveer al público de información veraz y transparente sobre los servicios eléctricos.
                    <br /> Disponemos de personal experimentado, acceso a tecnología de punta y la convicción de que la información es necesaria. Actualizamos a diario nuestros datos y monitoreamos el mercado, para que sus decisiones se basen en lo que sucede hoy día.</p>
                </div>
                <div className="col-md-6">
                    <img src={conocenos} alt="Conocenos" />
                </div>
            </article>


            <article className="row  d-flex align-items-center">
                <div className="col-md-6">
                    <img src={conocenos_ahorro} alt="Conocenos Ahorro" />
                </div>
                <div className="col-md-6">
                    <h2>Cómo hacemos posible que elijas la tarifa de luz sea la más barata</h2>
                    <p>
                        <span className="fw-bold">Nos apoyamos en tres pilares fundamentales para el consumidor</span>
                        <strong>Conocimiento: </strong>Saber cómo se interpreta correctamente la factura de luz, como se contratan los servicios y como se migra a otra empresa. Hay muchos detalles relacionados con la discriminación horaria, los peajes y la potencia que pondremos a su disposición. En este ocupado mundo actual, seremos su fuente de información confiable.
                    </p>
                    <p>
                        <strong>Comparadores: </strong>Conocer cómo se comportan entre si las diferentes comercializadoras de energía no permite tomar la decisión que lleva al ahorro. Nuestros sistemas permiten comparar las diferentes ofertas del mercado y con unas respuestas sencillas, llegar a la mejor solución. Solo necesitamos conocer nuestro propio perfil de consumo.
                    </p>
                    <p>
                        <strong>Actualizaciones: </strong>Nuestro personal está atento a lo que sucede en el mercado de la energía para informarlo. Las fuentes de datos serán puestas a su disposición y podrá saber cómo tomar la mejor decisión. Las previsiones de los expertos y el movimiento diario serán de su conocimiento.
                    </p>
                </div>
            </article>
        </main>
    )
}