import React from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


export default function AdditionalServices({ additionalServices, setAdditionalServices }) {
    const onSwitchAction = () => {
        setAdditionalServices(!additionalServices);
    };


    const stylesSwitch = {
        "& .MuiSwitch-track": {
            backgroundColor: additionalServices ? "var(--naranja) !important":"var(--azul) !important" 
        },
        "& .MuiSwitch-thumb": {
            background: "rgb(230, 126, 34) !important",
            // eslint-disable-next-line
            background: "radial-gradient(circle, rgba(230, 126, 34, 1) 27%, rgba(0, 42, 87, 1) 27%) !important"
        },
        "& .MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: "var(--naranja) !important"
        },
        "& .MuiFormControlLabel-root .MuiFormControlLabel-label": {
            fontFamily: "Jost, sans-serif !important",
        },
        "& .MuiTypography-root": {
            fontFamily: "Jost, sans-serif !important",
            fontSize: ".9em !important"

        },
    }

    return (
        <FormGroup>
            <FormControlLabel control={<Switch color="primary" defaultValue={additionalServices} />} label="Servicios Adicionales" onChange={onSwitchAction} checked={additionalServices ? true : false} sx={stylesSwitch} />
        </FormGroup>
    )
}