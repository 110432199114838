import React from "react";

function Results({ results }) {

    function getMonth(number){
        switch (number) {
            case 1:
                return "Enero"
            case 2:
                return "Febrero"
            case 3:
                return "Marzo"
            case 4:
                return "Abril"
            case 5:
                return "Mayo"
            case 6:
                return "Junio"
            case 7:
                return "Julio"
            case 8:
                return "Agosto"
            case 9:
                return "Septiembre"
            case 10:
                return "Octubre"
            case 11:
                return "Noviembre"
            case 12:
                return "Diciembre"
            default:
                break;
        }
    }

    return (
       <div className="container">
        <div className="row">
            {results?.monthlyProductions?.map(({month, production}) => (
                <div className="col-md-4" key={month}>
                    <div className="card">
                        <p className="month">
                            {getMonth(month)}
                        </p>
                        <p className="production">
                            {production}
                        </p>
                    </div>
                </div>
            ))}
        </div>
       </div>
    );
}

export default Results;