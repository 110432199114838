import React, {useEffect, useState} from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';

export default function Characteristics({dayPrices, minDayPrice, maxDayPrice}) {
    const [averageHour, setAverageHour] = useState(0);
    const [maxHour, setMaxHour] = useState(0);
    const [minHour, setMinHour] = useState(0);


    useEffect(() => {
        setAverageHour((((maxDayPrice - minDayPrice) / 2) + minDayPrice) * 0.001);
        setMinHour(minDayPrice * 0.001);
        setMaxHour(maxDayPrice * 0.001);
      }, [dayPrices, minDayPrice, maxDayPrice]);


    return (
        <section className="caracteristicas-luz">
            {/* <h2 className='h2'>Características del día</h2> */}
            <div className="llano">
                <h3>Precio Llano</h3>
                <p>{averageHour.toFixed(3)} €/kWh <i className="bi bi-dash-lg" style={{color: '#FFC108'}}></i></p>
            </div>

            <div className="valle">
                <h3>Precio Valle</h3>
                <p>{minHour.toFixed(3)} €/kWh  <i className="bi bi-caret-down-fill" style={{color: '#1C8C4D'}}></i></p>
            </div>

            <div className="punta">
                <h3>Precio Punta</h3>
                <p>{maxHour.toFixed(3)} €/kWh  <i className="bi bi-caret-up-fill" style={{color: '#D93D3D'}}></i></p>
            </div>
        </section>
    )
}