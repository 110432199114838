import React, { useState } from 'react'

function FormResults({ onSubmit }) {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    function handleSubmit(e) {
        e.preventDefault()
        onSubmit(name, phone, email)
        
    }

    return (
        <form onSubmit={handleSubmit}>
            <h2>¿Quieres que te lo instalemos?</h2>
            <p>Envía tus datos</p>
            <div className='mb-3'>
                <label htmlFor="name" className='form-label'>Nombre</label>
                <input required defaultValue={name} type="text" name="name" id="name" className='form-control' onChange={e => setName(e.target.value)} />
            </div>
            <div className='mb-3'>
                <label htmlFor="phone" className='form-label'>Teléfono</label>
                <input required defaultValue={phone} type="text" name="phone" id="phone" className='form-control' onChange={e => setPhone(e.target.value)} />
            </div>
            <div className='mb-3'>
                <label htmlFor="email" className='form-label'>Email</label>
                <input required defaultValue={email} type="text" name="email" id="email" className='form-control' onChange={e => setEmail(e.target.value)} />
            </div>

            <div className='text-center'>
                <button className='btn btn-secondary'>Pedir presupuesto</button>
            </div>

        </form>
    )
}

export default FormResults;