import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavigate } from "react-router-dom";


import RatingAirconditioners from "../ratingEnergyEfficient/RatingAirconditioners";

export default function Airconditioners({item, filtered}) {
    const navigate = useNavigate();
    return (
        <div className="row align-items-baseline">
            <h1 className="text-center mb-4">Acondicionadores de aire</h1>
             <section className="col-md-8 tabla-detalles">
                <table>
                    <thead>
                        <tr>
                            <th colSpan="2" className="fw-bold fs-4">Información General</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Nombre del refrigerante</td> 
                            <td> {item?.refrigerantName} </td>
                        </tr>
                        <tr>
                            <td>GWP del refrigerante</td>
                            <td>{item?.refrigerantGwp} m2</td>
                        </tr>
                        <tr>
                            <th>MODO DE REFRIGERACIÓN</th>
                        </tr>
                        <tr>
                            <td>Niveles de potencia acústica interiores</td>
                            <td>{item?.coolingCharacteristics?.indoorSoundPower} dB</td>
                        </tr>
                        <tr>
                            <td>Niveles de potencia acústica exteriores</td>
                            <td>{item?.coolingCharacteristics?.outdoorSoundPower} dB</td>
                        </tr>
                        <tr>
                            <td>Ratio de eficiencia energética estacional (SEER)</td>
                            <td>{item?.coolingCharacteristics?.seasonalEnergyEfficiencyRatio}</td>
                        </tr>
                        <tr>
                            <td>Consumo anual de electricidad</td>
                            <td>{item?.coolingCharacteristics?.annualElectricityConsumption} kWh/año</td>
                        </tr>
                        <tr>
                            <td>Carga de diseño</td>
                            <td>{item?.coolingCharacteristics?.designLoad} kW</td>
                        </tr>
                        <tr>
                            <td>Clase de eficiencia energética</td>
                            <td>{item?.coolingEnergyClass}</td>  
                        </tr>

                        <tr>
                            <th>FUNCIÓN DE CALEFACCIÓN</th>
                        </tr>

                        <tr>
                            <td>Carga de diseño</td>
                            <td>{item?.heatingDesignLoad} </td>
                        </tr>

                        <tr>
                            <td>Clase de eficiencia energética</td>
                            <td>{item?.heatingEnergyClass}</td>
                        </tr>


                        
                    </tbody>
                </table>
            </section>
            <section className="col-md-4">
                <RatingAirconditioners item={item} />
            </section>
            <button className="mt-5 btn btn-primary" onClick={() => navigate(`/electrodomesticos/comparador`, {state: {item, filtered}})}> Comparar </button>

        </div>
    )
}

