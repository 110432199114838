import React from "react";
import {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import WasherDryers from './categories/WasherDryers';
import WashingMachines from "./categories/WashingMachines";
import Airconditioners from "./categories/Airconditioners";
import Dishwashers from "./categories/Dishwashers";
import ResidentialVentilation from "./categories/ResidentialVentilation";
import SolidFuelBoilers from "./categories/SolidFuelBoilers";
import SolidFuelBoilersPackages from "./categories/SolidFuelBoilersPackages";
import SpaceHeaterTemperatureControl from "./categories/SpaceHeaterTemperatureControl";
import WaterHeaterSolarDevices from "./categories/WaterHeaterSolarDevices";


export default function Details() {
    const location = useLocation();
    let navigate = useNavigate();
    const [item, setItem] = useState();
    const [productGroup, setProductGroup] = useState(location?.state?.item?.productGroup);
    const [filtered, setFiltered] = useState(location?.state?.filtered);
    const [nameProductGroup, setNameProductGroup] = useState("");


    useEffect(() => {
        if (location.state) {
            setItem(location.state.item);
            setProductGroup(location.state.item?.productGroup);
            setFiltered(location.state.filtered);
            switch (productGroup) {
                case "airconditioners": setNameProductGroup("acondicionadores de aire");
                    break;
                case "dishwashers2019": setNameProductGroup("lavavajillas");
                    break;
                case "residentialventilationunits": setNameProductGroup("unidades de ventilación residencial");
                    break;
                case "solidfuelboilerpackages": setNameProductGroup("equipos de caldera de combustible sólido");
                    break;
                case "solidfuelboilers": setNameProductGroup("calderas de combustible sólido");
                    break;
                case "spaceheatertemperaturecontrol": setNameProductGroup("controles de temperatura (calefacción)");
                    break;
                case "washerdriers2019": setNameProductGroup("lavadoras secadoras");
                    break;
                case "washingmachines2019": setNameProductGroup("lavadoras");
                    break;
                case "waterheatersolardevices": setNameProductGroup("dispositivos de calentador de agua solar");
                    break;
                default: setNameProductGroup("electrodomésticos");
            }
        } else {
            navigate("/electrodomesticos");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state, navigate]);

    return (
        <main className="container details">
            {nameProductGroup === "lavadoras secadoras" && (
                <WasherDryers item={item} filtered={filtered} />
            )}

            {nameProductGroup === "lavadoras" && (
                <WashingMachines item={item} filtered={filtered}  />
            )}

            {nameProductGroup === "acondicionadores de aire" && (
                <Airconditioners item={item} filtered={filtered} />
            )}

            {nameProductGroup === "lavavajillas" && (
                <Dishwashers item={item} filtered={filtered}  />
            )}

            {nameProductGroup === "unidades de ventilación residencial" && (
                <ResidentialVentilation item={item} filtered={filtered} />
            )}

            {nameProductGroup === "calderas de combustible sólido" && (
                <SolidFuelBoilers item={item} filtered={filtered} />
            )}

            {nameProductGroup === "equipos de caldera de combustible sólido" && (
                <SolidFuelBoilersPackages item={item} filtered={filtered} />
            )}

            {nameProductGroup === "controles de temperatura (calefacción)" && (
                <SpaceHeaterTemperatureControl item={item} filtered={filtered} />
            )}

            {nameProductGroup === "dispositivos de calentador de agua solar" && (
                <WaterHeaterSolarDevices item={item} filtered={filtered} />
            )}
        </main>
    );
}





       