import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SelectComponent from "../../components/homeAppliances/comparator/SelectComponent";
import ComparatorCharacteristics from "../../components/homeAppliances/comparator/ComparatorCharacteristics";
import RatingEnergyEfficient from "../../components/homeAppliances/details/ratingEnergyEfficient/RatingEnergyEfficient";
import Swal from "sweetalert2";


export default function PageComparator() {
    const location = useLocation();
    let navigate = useNavigate();
    const [item1, setItem1] = useState(location?.state?.item1);
    const [item2, setItem2] = useState(location?.state?.item2);
    const [productGroup, setProductGroup] = useState(location?.state?.productGroup);
    const [nameProductGroup, setNameProductGroup] = useState("");
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (location?.state) {
            setItem1(location?.state?.item);
            setProductGroup(location?.state?.productGroup);
            setOptions(location?.state?.filtered.map((item) => {
                return { value: item, label: item?.supplierOrTrademark + " " + item?.modelIdentifier }
            }));
            setItem1(location?.state?.item1);
            setItem2(location?.state?.item2);

            if (!item1 || !item2 ) {
                Swal.fire({
                    title: "¡Atención!",
                    text: "Debes seleccionar al menos dos electrodomésticos para compararlos.",
                    icon: "warning",
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: '#013D0B',
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            navigate("/electrodomesticos");
                        }
                    })
            }

            switch (productGroup) {
                case "airconditioners": setNameProductGroup("acondicionadores de aire");
                    break;
                case "dishwashers2019": setNameProductGroup("lavavajillas");
                    break;
                case "residentialventilationunits": setNameProductGroup("unidades de ventilación residencial");
                    break;
                case "solidfuelboilerpackages": setNameProductGroup("equipos de caldera de combustible sólido");
                    break;
                case "solidfuelboilers": setNameProductGroup("calderas de combustible sólido");
                    break;
                case "spaceheatertemperaturecontrol": setNameProductGroup("controles de temperatura (calefacción)");
                    break;
                case "washerdriers2019": setNameProductGroup("lavadoras secadoras");
                    break;
                case "washingmachines2019": setNameProductGroup("lavadoras");
                    break;
                case "waterheatersolardevices": setNameProductGroup("dispositivos de calentador de agua solar");
                    break;
                default: setNameProductGroup("electrodomésticos");
            }
        } else {
            Swal.fire({
                title: "¡Atención!",
                text: "Debes seleccionar al menos dos electrodomésticos para compararlos.",
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: '#013D0B',
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        navigate("/electrodomesticos");
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state]);

    return (
        <main className="container comparador-electrodomesticos min-vh-100">
            <h1>Comparador de {nameProductGroup}</h1>
            <div className="row select-comparadores">
                <section className="col-md-6 col-6">
                    <SelectComponent
                        options={options}
                        defaultValue={item1 ? { value: item1, label: item1?.supplierOrTrademark + " " + item1?.modelIdentifier } : null}
                        onChange={(e) => setItem1(e.value)}
                    />
                </section>

                <section className="col-md-6 col-6">
                    <SelectComponent
                        options={options}
                        defaultValue={item2 ? { value: item2, label: item2?.supplierOrTrademark + " " + item2?.modelIdentifier } : null}
                        onChange={(e) => setItem2(e.value)}
                    />
                </section>
            </div>

            <div className="row">
                <section className="col-md-6 col-6 item1">
                    <RatingEnergyEfficient productGroup={productGroup} item={item1} />
                    <ComparatorCharacteristics item={item1} />
                </section>
                {item2?.length !== 0 && (
                    <section className="col-md-6 col-6 item2">
                        <RatingEnergyEfficient productGroup={productGroup} item={item2} />
                        <ComparatorCharacteristics item={item2} />
                    </section>
                )}
            </div>


        </main>
    )
}
