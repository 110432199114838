import React from 'react'
import { Link} from 'react-router-dom'
import logo from '../../assets/img/logo-footer.png'


function Footer() {
    return (
        <>
        <footer>
            <section className="redes">
                <Link to={"/"}>
                    <img src={logo} alt="Logo EnergyStar" />
                    <p>Compara el mejor precio y empieza a ahorrar.</p>
                </Link>
                <nav>
                    <ul>
                        <li><a href={"https://www.facebook.com/"} rel="noreferrer noopener" id="fb" target="_blank">Facebook  </a></li>
                        <li><a href={"https://www.instagram.com/"} rel="noreferrer noopener" id="ig" target="_blank">Instagram </a></li>
                        <li><a href={"https://www.twitter.com/"} rel="noreferrer noopener" id="tw" target="_blank">Twitter </a></li>
                        <li><a href={"https://www.linkedin.com/"} rel="noreferrer noopener" id="in" target="_blank">LinkedIn </a></li>
                    </ul>
                </nav>
              
            </section>
            <section className="mt-3 info-footer links">
                <h2 className='h4'>Links Útiles</h2>
                <nav>
                    <ul className="links-container">
                        <li className="link left-link"><Link to={"/precio-luz"}>Precios Luz hoy</Link></li>
                        <li className="link left-link"><Link to={"/electrodomesticos"}>Electrodomesticos</Link></li>
                        <li className="link right-link"><Link to={"/#"}>Energía Verde</Link></li>
                        <li className="link right-link"><Link to={"/#"}>Blog EnergyStar</Link></li>
                    </ul>
                </nav>
            </section>
            <section className="mt-3 info-footer">
                <h2 className='h4'>Contacto</h2>
                <nav>
                    <ul>
                        <li className="footer-mail">
                            <Link 
                                onClick={(e) => {
                                    window.location = "mailto:info@energystar.es";
                                    e.preventDefault();
                                }}>info@energystar.es
                            </Link>
                        </li>
                        <li className='footer-telefono'>
                            <Link 
                                onClick={(e) => {
                                    window.location = "tel:911309222";
                                    e.preventDefault();
                                }}>911 309 222
                            </Link>
                        </li>
                        <li className='footer-tel'>
                            <Link 
                                onClick={(e) => {
                                    window.location = "tel:935565000";
                                    e.preventDefault();
                                }}>935 565 000
                            </Link>
                        </li>
                    </ul>
                </nav>
            </section>
        </footer>
        <section className='copyright'>
            <p>Copyright © 2022 - EnergyStar Todos los derechos reservados.</p>
            <nav>
                <ul>
                    <li><Link to={"/terms"}>Términos y condiciones  </Link></li>
                    <li><Link to={"/privacy"}>Políticas de Privacidad </Link></li>
                </ul>
            </nav>
        </section>
        </>
    )
}

export default Footer


