import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import RatingWaterHeaterSolarDevices from "../ratingEnergyEfficient/RatingWaterHeaterSolarDevices";
import { useNavigate } from "react-router-dom";


export default function WaterHeaterSolarDevices({item, filtered}) {
    const navigate = useNavigate();
    return (
            <div className="row">
                <h1 className="text-center mb-4">Dispositivos de calentadores de agua solar</h1>
                <section className="col-md-8 tabla-detalles">
                    <table>
                        <thead>
                            <tr>
                                <th colSpan="2" className="fw-bold fs-4">Información General</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Tamaño del colector solar</td>
                                <td>{item?.solarCollectorSize} m2</td>
                            </tr>
                            <tr>
                                <td>Eficiencia de pérdida cero</td>
                                <td>{item?.zeroLossEfficiency}</td>
                            </tr>
                            <tr>
                                <td>Coeficiente de primer orden</td>
                                <td>{item?.firstOrderCoefficient} W/(m2 K)</td>
                            </tr>
                            <tr>
                                <td>Coeficiente de segundo orden</td>
                                <td>{item?.secondOrderCoefficient} W/(m2 K)</td>
                            </tr>
                            <tr>
                                <td>Modificador del ángulo de incidencia</td>
                                <td>{item?.incidenceAngleModifier}</td>
                            </tr>
                            <tr>
                                <td>Volumen de almacenamiento</td>
                                <td>{item?.storageVolume} L</td>
                            </tr>
                        </tbody>
                    </table>

                </section>

                <section className="col-md-4">
                    <RatingWaterHeaterSolarDevices item={item}/>
                </section>
                <button className="col-12 mt-5 btn btn-primary" onClick={() => navigate(`/electrodomesticos/comparador`, {state: {item, filtered}})}> Comparar </button>

            </div>
    )
}