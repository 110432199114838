import React from 'react';
import { Link } from 'react-router-dom';
import lavadora from '../../../assets/img/lavadora.svg'
import lavavajillas from '../../../assets/img/lavavajillas.svg'
import secadora from '../../../assets/img/secadora.svg'
import aire from '../../../assets/img/aire.svg'
import ventilacion from '../../../assets/img/ventilacion.svg'
import equipos_caldera from '../../../assets/img/equipos_caldera.svg'
import caldera_combustible from '../../../assets/img/caldera_combustible.svg'
import control_temperatura from '../../../assets/img/control_temperatura.svg'
import calentadores_agua from '../../../assets/img/calentadores_agua.svg'
import arrow from '../../../assets/img/arrow_left.svg'

const categories = [
    {name: "Lavavajillas", id: 1, url: "lavavajillas", nameShort: "Lavavajillas", img: lavavajillas},
    {name: "Unidades de ventilación residencial", id: 2, url: "ventilacion-residencial", nameShort: "Unidades de ventilación residencial", img: ventilacion},
    {name: "Calderas de combustible sólido", id: 3, url: "calderas", nameShort: "Calderas de combustible sólido", img: caldera_combustible},
    {name: "Equipos de calderas de combustible sólido", id: 4, url: "equipos-calderas", nameShort: "Equipos de calderas de combustible sólido", img: equipos_caldera},
    {name: "Controles de temperatura para aparatos de calefacción de espacios", id: 5, url: "controles-temperatura", nameShort: "Controles de temperatura (calefacción)", img: control_temperatura},
    {name: "Lavadoras/Secadoras", id: 6, url: "lavadoras-secadoras", nameShort: "Lavadoras/Secadoras", img: secadora},
    {name: "Lavadoras", id: 7, url: "lavadoras", nameShort: "Lavadoras", img: lavadora},
    {name: "Dispositivos solares para calentadores de agua", id: 8, url: "calentadores-agua", nameShort: "Dispositivos solares para calentadores de agua", img: calentadores_agua},
    {name: "Acondicionadores de Aire", id: 9, url: "acondicionadores", nameShort: "Acondicionadores de Aire", img: aire},
    {name: "Ver todos", id: 10, nameShort: "Ver todos", img: arrow},
]

export default function Categories() {
    return (
        <section className='categorias'>
            <div className="row">
                <h2 className='h4 col-12 col-md-6'>Categorías</h2>  
                <div className="col-12 mt-4">
                    <div className="row justify-content-center">
                        {categories.slice(0, 10).map((category) => (
                            <div className="col-md-2 col-6 mb-3" key={category.id} id="slidecat1" >
                                <Link to={category.url}>
                                    <div className="card">
                                        <img src={category.img} alt={category.name} />
                                        <div className="card-body mt-auto">
                                            <h3>{category.nameShort}</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>

    )
}