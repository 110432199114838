import data from "../../../data/PostalCode.json";

export default function dataPostalCodeFilter() {
  const uniqueData = data.reduce((acc, postalCode) => {
    const key = `${postalCode.cp}-${postalCode.town}-${postalCode.province}`;
    if (!acc[key]) {
      acc[key] = postalCode;
    }
    return acc;
  }, {});
  
  return Object.values(uniqueData);
}