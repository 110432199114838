import React, { useState, useEffect } from "react";
import GasConsumption from "../globals/GasConsumption";
import PostalCode from "../globals/PostalCode";
import PostalCodeResult from "../results/PostalCode";
import * as ComparatorService from "../../../services/comparator.service";
import { useNavigate } from "react-router-dom";
import Permanence from "../globals/Permanence";
import AdditionalServices from "../globals/AdditionalServices";
import Swal from "sweetalert2";

function Gas({ setLoading, data, isResult }) {
    const navigate = useNavigate();
    const [gasConsumption, setGasConsumption] = useState(0);
    const [postalCode, setPostalCode] = useState("");
    const [additionalServices, setAdditionalServices] = useState(true);
    const [permanence, setPermanence] = useState(true);

    const [error, setError] = useState("");

    useEffect(() => {
        if (data !== undefined) {
            setGasConsumption(data.consumoAnualG);
            setPostalCode(data.codigoPostal);
            setPermanence(data.permanencia);
            setAdditionalServices(data.serviciosAdicionales);
        }
    }, [data]);

    function onSubmit(e) {
        e.preventDefault();
        setLoading(true);

        if (postalCode === 0 || postalCode === "") {
            setError("Debes introducir un código postal");
            setLoading(false);
            return;
        } else if (gasConsumption <= 0) {
            setError("Debes introducir un consumo de gas");
            setLoading(false);
            return;
        }

        setError("");
        let data = {
            tipoSuministro: "g",
            codigoPostal: postalCode,
            potencia: 3.3,
            consumoAnualE: 3000,
            tarifa: 4,
            consumoAnualG: gasConsumption,
            serviciosAdicionales: 1,
            permanencia: 1,
            vivienda: true,
            potenciaPrimeraFranja: 3.3,
            potenciaSegundaFranja: 3.3,
            potenciaTerceraFranja: 3.3,
            potenciaCuartaFranja: 3.3,
            potenciaQuintaFranja: 3.3,
            potenciaSextaFranja: 3.3,
            consumoPrimeraFranja: 0,
            consumoSegundaFranja: 0,
            consumoTerceraFranja: 0,
            consumoCuartaFranja: 0,
            consumoQuintaFranja: 0,
            consumoSextaFranja: 0,
            typeUser: 'negocio',
            service: 'gas'
        };

        ComparatorService.getOfferGas(data)
            .then((res) => {
                if (res.resultadoComparador.length > 0) {
                    res.resultadoComparador.forEach((item, index) => {
                        ComparatorService.getDetailOfferGas(item.id, data)
                            .then((detalle) => {
                                res.resultadoComparador[index].detalle = detalle;
                                ComparatorService.getImgOffer(item.id)
                                    .then((img) => {
                                        if (img.length === 0) {
                                            res.resultadoComparador[index].img = 0
                                        } else {
                                            res.resultadoComparador[index].img = img;
                                        }
                                        setLoading(false);
                                        navigate("/comparador/resultados", { state: { data: data, results: res } });
                                    })
                            })
                    })
                } else {
                    Swal.fire({
                        title: 'Hubo un error',
                        text: 'Inténtalo de nuevo más tarde',
                        icon: 'warning',
                        confirmButtonText: 'Cerrar',
                        confirmButtonColor: '#002A57',
                    })
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setError("Ha ocurrido un error, inténtalo de nuevo más tarde");
            }
            );
    }

    return (
        <div className="luz negocio">

            {isResult ? (
                <PostalCodeResult postalCode={postalCode} setPostalCode={setPostalCode} />
            ) : (
                <PostalCode postalCode={postalCode} setPostalCode={setPostalCode} />
            )}

            <GasConsumption
                gasConsumption={gasConsumption}
                setGasConsumption={setGasConsumption}
            />

            <div className="adicionales">
                <div>
                    <Permanence permanence={permanence} setPermanence={setPermanence} />
                </div>
                <div>
                    <AdditionalServices additionalServices={additionalServices} setAdditionalServices={setAdditionalServices} />
                </div>
            </div>

            {error !== "" &&
                <div className="alert alert-danger text-center" role="alert">
                    {error}
                </div>
            }
            <div className="form-enviar">
                <button className="btn btn-secondary" type="submit" onClick={onSubmit}>Comparar</button>
            </div>

        </div>
    )
}

export default Gas;