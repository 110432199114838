import React from 'react';
import Categories from '../../components/homeAppliances/globals/Categories';

export default function PageHomeAppliances() {
    return (
        <main className='container electrodomesticos min-vh-100'>
            <h1>Compare Electrodomésticos</h1>
            <Categories />
        </main>
    )
}