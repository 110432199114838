import React from "react";
import { Link } from "react-router-dom";

export default function PageContacto() {
    return (
        <main className="container contacto min-vh-100">
            <h1 className="text-center">Contacto</h1>
            <div className="row">
                <div className="col-md-6 mt-3">
                    <h2 className="visually-hidden">Formulario de contacto</h2>
                    <form>
                        <div>
                            <label htmlFor="nombre" className="form-label">Nombre</label>
                            <input type="text" className="form-control" id="nombre" required />
                        </div>
                        <div>
                            <label htmlFor="telefono" className="form-label">Teléfono</label>
                            <input type="number" className="form-control" id="telefono" required/>
                        </div>
                        <div>
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" className="form-control" id="email" required />
                        </div>
                        <div>
                            <label htmlFor="mensaje" className="form-label">Mensaje</label>
                            <textarea className="form-control" id="mensaje" rows="3" required></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary w-100">Enviar</button>
                    </form>
                </div>
                <div className="col-md-6 cards">
                    <section>
                        <h2>Nuestros Emails</h2>
                        <p>Puede comunicarse con nuestras oficinas en cualquier dia y horario por correo electrónico</p>
                        <Link to={'/'}>info@energystar.es</Link>
                    </section>
                    <section>
                        <h2>Nuestros Teléfonos</h2>
                        <p>Puedes llamarnos las 24Hs a los teléfonos.</p>
                        <Link to={'/'}>911 309 222</Link>
                        <Link to={'/'}>935 565 000</Link>
                    </section>  
                    <section>
                        <h2>Redes Sociales</h2>
                        <p>También podes contactar con nosotros por medio de nuestras redes sociales.</p>
                        <nav>
                            <ul>
                                <li><Link to={"#"} id="fb_contacto" target="_blank">Facebook  </Link></li>
                                <li><Link to={"#"} id="ig_contacto" target="_blank">Instagram </Link></li>
                                <li><Link to={"#"} id="tw_contacto" target="_blank">Twitter </Link></li>
                                <li><Link to={"#"} id="in_contacto" target="_blank">Linkedin </Link></li>
                            </ul>
                        </nav>

                    </section>
                </div>
            </div>
        </main>
    );
}   