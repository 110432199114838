import React from "react";

import heladera from "../../../assets/img/heladera.svg";
import lavavajillas from "../../../assets/img/lavavajillas.svg";
import lavadora from "../../../assets/img/lavadora.svg";
import ordenador from "../../../assets/img/ordenador.svg";
import secadora from "../../../assets/img/secadora.svg";
import horno from "../../../assets/img/horno.svg";
import cocina from "../../../assets/img/cocina.svg";
import tv from "../../../assets/img/tv.svg";

function ElectricalAppliances({electric, setElectric, arrayElectric, setArrayElectric}) {
    let array = [
        {
            id: 1,
            name: "Nevera",
            icon: heladera,
            kwh: 662
        },
        {
            id: 2,
            name: "Lavavajillas",
            icon: lavavajillas,
            kwh: 225
        },
        {
            id: 3,
            name: "Lavadora",
            icon: lavadora,
            kwh: 255
        },
        {
            id: 4,
            name: "Ordenador",
            icon: ordenador,
            kwh: 172
        },
        {
            id: 5,
            name: "Secadora",
            icon: secadora,
            kwh: 255
        },
        {
            id: 6,
            name: "Horno",
            icon: horno,
            kwh: 231
        },
        {
            id: 7,
            name: "Cocina",
            icon: cocina,
            kwh: 662
        },
        {
            id: 8,
            name: "TV",
            icon: tv,
            kwh: 263
        }
    ];

    const isChecked = (id) => {
        if (arrayElectric !== undefined && arrayElectric.length > 0) {
            return arrayElectric.includes(id);
        } else {
            return false;
        }
    }

    
    function handleChange(e) {
        if (e.target.checked) {
            setElectric(electric + parseInt(e.target.value));
            if (arrayElectric !== undefined) {
                setArrayElectric([...arrayElectric, parseInt(e.target.id)])
            } else if (arrayElectric === undefined) {
                setArrayElectric([parseInt(e.target.id)])
            } 
        } else {
            setArrayElectric(arrayElectric.filter(item => item !== parseInt(e.target.id)))
            setElectric(electric - parseInt(e.target.value));
        }
    }

    return (
        <div className="mt-2">
            <h3 className="h5 text-center">Electrodomésticos Eléctricos</h3>
            <fieldset className="comparador-electrodomestico">
                {array.map((item, index) => (
                    <div className="checkbox" key={index}>
                        <label className="comparador-electrodomestico-wrapper">
                            <input type="checkbox"
                                   name="electric[]"
                                   className="comparador-electrodomestico-input"
                                   id={item.id}
                                   value={item.kwh}
                                    onChange={handleChange}
                                    defaultChecked={isChecked(item.id)}
                                   />
                            <span className="comparador-electrodomestico-tile">
                                <span className="comparador-electrodomestico-icon">
                                    <img src={item.icon} alt={item.name} />
                                </span>
                                <span className="comparador-electrodomestico-label">{item.name}</span>
                            </span>
                        </label>
                    </div>
                ))}
            </fieldset>
        </div>
    )
}

export default ElectricalAppliances;