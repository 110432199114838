import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PageSolar from '../../pages/solar/PageSolar'
import PageResults from '../../pages/solar/PageResults';
import HeaderSolar from '../layouts/HeaderSolar';
import Footer from '../layouts/Footer';


function RouteComparator() {

    return (
        <>
            <HeaderSolar />
            <Routes>
                <Route path='/' element={<PageSolar />} />
                <Route path='/resultados' element={<PageResults />} />
            </Routes>
            <Footer />
        </>
    )
}

export default RouteComparator;