import React, { useEffect, useState } from "react";
import historico_pvp from "../../../assets/img/historico_pvp.svg";
import Swal from 'sweetalert2'

export default function CardResults({ offer }) {
    const [img, setImg] = useState("");

    const rounded = (num) => {
        return Math.round(num * 100) / 100;
    }

    useEffect(() => {
        if (offer.img) {
            if (offer.img) {
                setImg(`data:image/jpeg;base64,${offer.img}`);
            } else {
                setImg(historico_pvp);
            }
        } else if (offer.img) {
            setImg(`data:image/jpeg;base64,${offer.img}`);
        } else {
            setImg(historico_pvp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [offer.img])

    const handleView = (offer) => {
        Swal.fire({
            title: offer.oferta,
            text: offer?.detalle?.caracteristicas && offer?.detalle?.caracteristicas.caracteristicas,
            imageUrl: img,
            imageWidth: 100,
            imageAlt: 'Custom image',
            confirmButtonText: 'Cerrar',
            confirmButtonColor: '#002A57',
        })
    }

    const onClickOffer = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Solicitar oferta: ' + offer?.id,
            html:
                '<input id="swal-input1" class="swal2-input w-100" placeholder="Nombre y Apellido">' +
                '<input id="swal-input2" class="swal2-input w-100" placeholder="Email">' +
                '<input id="swal-input3" class="swal2-input w-100" placeholder="Dirección">' +
                '<input id="swal-input4" class="swal2-input w-100" placeholder="Código Postal">',
            focusConfirm: false,
            confirmButtonText: 'Enviar',
            confirmButtonColor: '#002A57',
            preConfirm: () => {
                const name = Swal.getPopup().querySelector('#swal-input1').value
                const email = Swal.getPopup().querySelector('#swal-input2').value
                const address = Swal.getPopup().querySelector('#swal-input3').value
                const postalCode = Swal.getPopup().querySelector('#swal-input4').value
                if (!name || !email || !address || !postalCode) {
                    Swal.showValidationMessage(`Por favor, rellene todos los campos`)
                }

                return { name: name, email: email, address: address, postalCode: postalCode }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                // TODO: peticion
                Swal.fire({
                    title: '¡Gracias!',
                    text: 'En breve nos pondremos en contacto contigo',
                    icon: 'success',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: '#002A57',
                })
            }
        })
    }

    const onClickContact = (e) => {
        e.preventDefault()
        Swal.fire({
            title: 'Contactar Asesor',
            text: 'Te vamos a solicitar el siguiente identificador de oferta: ' + offer.id,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No',
            confirmButtonColor: '#002A57',
        }).then((result) => {
            if (result.isConfirmed) {
                const phoneNumber = '+1234567890';
                const phoneLink = 'tel:' + phoneNumber;
                window.location.href = phoneLink;
            }
        })
    }


    return (
        <div className="d-flex justify-content-center row">
            <div className="col-md-12">
                <div className="row p-2 bg-white border rounded align-items-center">
                    <div className="col-md-2 mt-1">
                        <img className="img-fluid img-responsive rounded product-image"
                            src={img}
                            alt={offer.comercializadora} />
                    </div>

                    <div className="col-md-7 mt-1">
                        <h2>{offer.oferta}</h2>
                        <p className="comercializadora">{offer.comercializadora}</p>
                        <div className="texto">
                            {offer?.detalle?.caracteristicas &&
                                <p><span>Validez: </span> {offer?.detalle?.caracteristicas.periodoValidez}</p>
                            }
                            <p><span>Tipo de oferta: </span>{offer.validez}</p>
                            {offer?.detalle?.caracteristicas?.potenciaMaximaElectricidad && (
                                <div className="mt-1 mb-1">
                                    <p><span>Potencia máxima: </span>{offer?.detalle?.caracteristicas.potenciaMaximaElectricidad} kW</p>
                                    <p className="permanencia"> <span>{offer?.detalle?.caracteristicas.condicionesPenalizacion}</span></p>
                                    {offer.verde && <p className="verde"> <span>Energía Verde</span></p>}
                                </div>
                            )}
                        </div>
                        <div className="informacion">
                            <button className="btn btn-primary btn-sm" type="button" onClick={(e) => { handleView(offer) }}> Información General </button>
                        </div>
                    </div>
                    <div className="align-items-center align-content-center col-md-3 mt-1">
                        <div className="precios">
                            <span>{rounded(offer.importePrimerAnio / 12)} €</span>
                            <span>{offer.importePrimerAnio} €/año </span>
                            <p className="badge text-bg-secondary">precio con IVA</p>
                        </div>
                        <div className="botones">
                            <button className="btn btn-primary btn-sm" type="button" onClick={onClickOffer}>Solicitar oferta</button>
                            <button className="btn btn-outline-primary btn-sm mt-2" type="button" onClick={onClickContact}>Contactar Asesor</button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}
