import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import Tooltip from '@mui/material/Tooltip';


export default function RatingWaterHeaterSolarDevices({item}) {
    return (
        <section className="detalle container">
            <div className="marca mb-5">
                <Tooltip title="Marca" placement="top">
                    <h1>{item?.supplierOrTrademark}</h1>
                </Tooltip>
                <Tooltip title="Modelo" placement="top">
                    <p>{item?.modelIdentifier}</p>
                </Tooltip>
                <hr/>
            </div>
            <div className="row">
                <div className="col-md-6 col-12">
                    <Tooltip title="Tamaño del colector solar" placement="top">
                        <div className="d-flex flex-column align-items-center">
                            <span className="solarTankRating mb-2"></span>
                            <p><span className="fw-bold fs-5 " >{item?.solarCollectorSize}</span></p>
                        </div>
                    </Tooltip>
                </div>

                <div className="col-md-6 col-12">
                    <Tooltip title="Volumen de almacenamiento" placement="top">
                        <div className="d-flex flex-column align-items-center">
                            <i className="bi bi-arrows-fullscreen fs-4"></i>
                            <p><span className="fw-bold fs-5" >{item?.storageVolume}</span> </p>
                        </div>
                    </Tooltip>
                </div>

                <div className="col-md-6 col-12">
                    <Tooltip title="Coeficiente de primer orden" placement="top">
                        <div className="d-flex flex-column align-items-center">
                            <i className="bi bi-info-lg fs-3"></i>
                            <p><span className="fw-bold fs-5" >{item?.firstOrderCoefficient}</span> <span className="fs-10"> W/(m2 K)</span></p>
                        </div>
                    </Tooltip>
                </div>

                <div className="col-md-6 col-12">
                    <Tooltip title="Coeficiente de primer orden" placement="top">
                        <div className="d-flex flex-column align-items-center">
                            <i className="bi bi-info-lg fs-3"></i>
                            <p><span className="fw-bold fs-5" >{item?.secondOrderCoefficient}</span><span className="fs-10"> W/(m2 K)</span></p>
                        </div>
                    </Tooltip>
                </div>
            </div>
            
        </section>
    )
}