import React from "react";
import Characteristics from "../characteristics/Characteristics.js";
import MeasurementTooltip from '../globals/results/resultsLists/MeasurementTooltip.jsx';

export default function ComparatorCharacteristics(item) {
    const checkData = (data) => {
        if (data) {
            return data;
        } else {
            return false;
        }
    }

     const formatClass = (item) => {
        switch (item) {
            case "APPP":
                return "A+++";
            case "APP":
                return "A++";
            case "AP":
                return "A+";
            case "A":
                return "A";
            case "B":
                return "B";
            case "C":
                return "C";
            case "D":
                return "D";
            case "E":
                return "E";
            case "F":
                return "F";
            case "G":
                return "G";
            default:
                return item;
        }
    }

    return (
        <div className="tabla-caracteristicas">
            {/* eslint-disable-next-line */}
            {Characteristics.map((i, index) => {
                if (i.length === 3) {
                    if (checkData(item?.item?.[i[0].name])) {
                        return (
                            <div key={index}>
                                <p>{item?.item?.[i[0].name]} x {item?.item?.[i[1].name]} x {item?.item?.[i[2].name]}</p>
                                <p className="title">{i[0].title} x {i[1].title} x {i[2].title}</p>
                            </div>
                        )
                    }
                } else if ((i[0]?.name === "energyClass" || i[0]?.name === "noiseClass" || i[0]?.name === "noiseLevel" || i[0]?.name === "energyClassWash" || i[0]?.name === "energyClassWashAndDry" || i[0]?.name === "spinClass" || i[0]?.name === "heatingEnergyClass" || i[0]?.name === "coolingEnergyClass")) {
                    if (checkData(item?.item?.[i[0].name])) {
                        return (
                            <div key={index}>
                                <p>{formatClass(item?.item?.[i[0].name])}</p>
                                <p className="title">{i[0].title} <MeasurementTooltip measurement={i?.measurement}  />  </p>
                            </div>
                        )
                    }
                } 
                
                
                else if (checkData(item?.item?.[i.name])) {
                    return (
                        <div key={index}>
                            <p>{item?.item?.[i.name]}  <span> <MeasurementTooltip measurement={i?.measurement}  /></span></p>
                            <p className="title">{i.title}   </p>
                        </div>
                    )
                }

            })}

        </div>
    )
}
