import React, { useState } from 'react';
import ResultsList from './ResultsList';
import ResultsListMobile from './ResultsListMobile'
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function TableResults({ items, filtered, characteristics, productGroup, order, setOrder }) {
    const navigate = useNavigate();
    const [item1, setItem1] = useState(null)
    const [item2, setItem2] = useState(null)
    const [selectedItems, setSelectedItems] = useState([]);

    const arrayCharacteristics = characteristics.slice(0, 5).map((characteristic) => {
        return { value: characteristic.name, label: characteristic.nameShort, measurement: characteristic.measurement }
    })

    function onClickButton() {
        if (selectedItems.length === 2) {
            let item1 = null
            let item2 = null
            selectedItems.forEach((item, i) => {
                if (i === 0) {
                    item1 = item
                } else if (i === 1) {
                    item2 = item
                }
            })
            navigate('/electrodomesticos/comparador', { state: { item1, item2, filtered, productGroup } })
        } else {
            Swal.fire({
                icon: 'info',
                text: 'Selecciona dos productos para comparar',
                confirmButtonColor: '#013D0B',
            })
        }
    }


    return (
        <div className='resultsList' >
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" onClick={() => { setOrder('supplierOrTrademark') }}>Marca  {order === 'supplierOrTrademark' && <ArrowDropDownIcon />} </th>
                        <th scope="col" onClick={() => { setOrder('modelIdentifier') }}>Modelo {order === 'modelIdentifier' && <ArrowDropDownIcon />} </th>

                        {arrayCharacteristics.map((characteristic, i) => {
                            return <th scope='col' key={i} onClick={() => { setOrder(characteristic.value) }}> {characteristic.label}
                                {characteristic.value === order && <ArrowDropDownIcon />}
                            </th>
                        })}
                        <th scope='col'>
                            <button className='btn btn-compare' onClick={onClickButton}>Comparar</button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, i) => {
                        return <ResultsList key={i} item={item} filtered={filtered} arrayCharacteristics={arrayCharacteristics}
                            item1={item1} setItem1={setItem1}
                            item2={item2} setItem2={setItem2}
                            selectedItems={selectedItems} setSelectedItems={setSelectedItems}
                            productGroup={productGroup}
                        />
                    }
                    )}
                </tbody>
            </table>


            <section>
                {items.map((item, i) => {
                    return <ResultsListMobile key={i} item={item} filtered={filtered} arrayCharacteristics={arrayCharacteristics}
                        item1={item1} setItem1={setItem1}
                        item2={item2} setItem2={setItem2}
                        selectedItems={selectedItems} setSelectedItems={setSelectedItems}
                        productGroup={productGroup}
                    />
                })}
            </section>
        </div>
    )
}