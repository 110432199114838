import React from "react";

export default function LightDate({date, setDate}) {
    const today = new Date().toISOString().slice(0, 10);

    const handleChange = (e) => {
        let date = e.target.value;
        setDate(date);
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <label htmlFor="fecha" className="col-auto form-label mt-2">Fecha</label>
                <input className="col-auto form-control w-auto" type="date" id="fecha" name="fecha" defaultValue={date} max={today} onChange={handleChange} />
            </div>
        </div>
    );
}