import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Polygon, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';


function First({ handlePrev, handleNext }) {
  const [indication, setIndication] = useState('')
  const [isBtnDisable, setIsBtnDisable] = useState(true);
  const storedLocation = JSON.parse(localStorage.getItem("center"));
  const defaultLocation = { lat: 40.463667, lng: -3.74922 };

  const isValidLocation = storedLocation && typeof storedLocation.lat === 'number' && typeof storedLocation.lng === 'number';

  const [location, setLocation] = useState(isValidLocation ? { lat: storedLocation.lat, lng: storedLocation.lng } : defaultLocation);

  const [coords, setCoords] = useState(JSON.parse(localStorage.getItem("coords")) ? JSON.parse(localStorage.getItem("coords")) : []);
  const [center, setCenter] = useState(JSON.parse(localStorage.getItem("center")) ? JSON.parse(localStorage.getItem("center")) : { lat: 0, lng: 0 });
  const [address, setAddress] = useState(JSON.parse(localStorage.getItem("address")) ? JSON.parse(localStorage.getItem("address")) : "");
  const [street, setStreet] = useState(JSON.parse(localStorage.getItem("street")) ? JSON.parse(localStorage.getItem("street")) : "")
  const [number, setNumber] = useState(JSON.parse(localStorage.getItem("number")) ? JSON.parse(localStorage.getItem("number")) : "")
  const [city, setCity] = useState(JSON.parse(localStorage.getItem("city")) ? JSON.parse(localStorage.getItem("city")) : "")
  const mapRef = useRef(null); // Referencia a la instancia de MapContainer
  // eslint-disable-next-line
  const mapOptions = {
    center: [location.lat, location.lng],
    zoom: 23
  };
  const markerIcon = new L.Icon({ // Objeto de icono personalizado para el marcador
    iconUrl: 'https://cdn.jsdelivr.net/leaflet/1.0.0-rc.3/images/marker-icon.png',
    shadowUrl: 'https://cdn.jsdelivr.net/leaflet/1.0.0-rc.3/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  // Función para buscar la dirección ingresada por el usuario y actualizar la ubicación en el mapa
  const handleAddressSearch = async (event) => {
    event.preventDefault();
    setCenter(
      { lat: 0, lng: 0 }
    )
    setCoords([])
    if (street === '' || city === '' || number === '') {
      alert('Por favor, ingrese una dirección válida');
      return;
    }
    const address = `${street} ${number}, ${city}`;
    setAddress(address);
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${address}&format=json&limit=1`);
      const data = await response.json();
      if (data.length > 0 && data[0].lat && data[0].lon) {
        const lat = parseFloat(data[0].lat);
        const lng = parseFloat(data[0].lon);
        setLocation({ lat: lat, lng: lng, zoom: 18 });
        mapRef?.current?.flyTo([lat, lng], 18); // Actualizar la vista del mapa al centro de la dirección ingresada
        setIndication('Marca con 4 puntos la sección de tu casa ')
      } else {
        console.error('No se encontraron coordenadas válidas');
      }
    } catch (error) {
      console.error(error);
    }
  };

  function addCoord(coord) {
    if (coords.length < 4) {
      setCoords([...coords, coord]);
      const latitudes = [...coords, coord].map(coord => coord[0]);
      const longitudes = [...coords, coord].map(coord => coord[1]);
      const lat = (Math.min(...latitudes) + Math.max(...latitudes)) / 2;
      const lng = (Math.min(...longitudes) + Math.max(...longitudes)) / 2;
      setCenter({ lat: lat, lng: lng });
    }
  }

  function DrawPolygon() {
    useMapEvents({
      click(e) {
        if (coords.length < 4) { // solo permitir añadir puntos si el polígono no está completo
          addCoord([e.latlng.lat, e.latlng.lng]);
        }

      },
    });

    if (coords.length > 0) { // mostrar el polígono a partir del primer clic
      // si hay menos de 3 puntos, repetir el último punto para formar un rectángulo
      const positions = coords.length < 3 ? [...coords, coords[coords.length - 1]] : coords;
      return <Polygon positions={positions} />;
    } else {
      return null;
    }
  }

  useEffect(() => {
    if (coords.length === 4) {
      setIsBtnDisable(false);
      setIndication('')
      localStorage.setItem("address", JSON.stringify(address));
      localStorage.setItem("center", JSON.stringify({ lat: center.lat, lng: center.lng }));
      localStorage.setItem("coords", JSON.stringify(coords));
      localStorage.setItem("street", JSON.stringify(street));
      localStorage.setItem("number", JSON.stringify(number));
      localStorage.setItem("city", JSON.stringify(city));
    }
  }, [coords, setIsBtnDisable, address, center, street, number, city]);

  function resetCoords() {
    setCoords([]);
    setCenter({ lat: 0, lng: 0 });
    localStorage.removeItem("center")
    localStorage.removeItem("address")
    localStorage.removeItem("coords")
    localStorage.removeItem("street")
    localStorage.removeItem("number")
    localStorage.removeItem("city")

    setIsBtnDisable(true)
  }

  function handleCenterChange(e) {
    const { name, value } = e.target;
    setCenter(prevCenter => {
      return { ...prevCenter, [name]: parseFloat(value) };
    });
  }
  useEffect(() => {
    if (mapRef.current) {
      const newCenter = [location.lat, location.lng];
      mapRef.current.setView(newCenter, mapOptions.zoom);
    }
    // eslint-disable-next-line
  }, [location, mapOptions]);

  return (
    <div>
      <h2>Para comparar las necesidades de energía solar ponga su dirección</h2>
      <section className='container mapa-container'>
        <div className="row justify-content-between align-items-center">
          <div className="col-md-8 mapa">
            <MapContainer ref={mapRef} center={[location.lat, location.lng]} zoom={23} style={{ width: '100%', height: '100%' }}>
              <TileLayer
                url="http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                attribution="<a href='https://maps.google.com'>Google Maps</a>"
              />
              <Marker position={[location.lat, location.lng]} icon={markerIcon}>
                <Popup>Ubicación seleccionada</Popup>
              </Marker>
              <DrawPolygon />
            </MapContainer>
          </div>
          <div className="col-md-4 formulario container">
            <div className='direccion row justify-content-between align-items-center'>
              <div className='col-md-9 col-8'>
                <label htmlFor="street" className='form-label'>Calle:</label>
                <input id="street" name="street" type="text" value={street} onChange={(e) => { setStreet(e.target.value) }} className='form-control' required />
              </div>
              <div className='col-md-3 col-4 px-0'>
                <input id="number" name="number" type="number" placeholder='Número' value={number} onChange={(e) => { setNumber(e.target.value) }} className='form-control' required />
              </div>
              <div className='col-md-9 col-12'>
                <label htmlFor="city" className='form-label'>Población:</label>
                <input id="city" name="city" type="text" value={city} onChange={(e) => { setCity(e.target.value) }} className='form-control' required />
              </div>
              <button onClick={handleAddressSearch} className='btn btn-primary col-md-3 col-12'>Buscar</button>
            </div>
            <div className='lat'>
              <div className='me-md-2'>
                <label htmlFor="lat" className='form-label'>Latitud</label>
                <input id='lat' type="number" name="lat" value={center.lat} onChange={handleCenterChange} className='form-control' />
              </div>
              <div className='me-md-2'>
                <label htmlFor='lng' className='form-label'>Longitud</label>
                <input id='lng' type="number" name="lng" value={center.lng} onChange={handleCenterChange} className='form-control' />
              </div>
              <div>
                <button className='btn btn-dark' onClick={resetCoords} >Reiniciar</button>
              </div>
            </div>
          </div>
        </div>
        <div className='indications'>
          {indication !== '' &&
            <div>
              {indication}
            </div>
          }
        </div>
      </section>

      <div className='btn-step'>
        <button className='btn btn-secondary' disabled={true} onClick={handlePrev}>Anterior</button>
        <button className='btn btn-primary' disabled={isBtnDisable} onClick={handleNext}>Siguiente</button>
      </div>
    </div>
  );

}

export default First;
