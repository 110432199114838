import React, { useEffect, useState } from "react";
import dataPostalCodeFilter from "./FilterPostalCode";

function PostalCode({ postalCode, setPostalCode }) {
    const [dataPostalCode, setDataPostalCode] = useState([]);
   
    useEffect(() => {
        const postalCodeStorage = localStorage.getItem("postalCode");
        if (postalCodeStorage) {
            setPostalCode(postalCodeStorage);
        }
    }, [setPostalCode])


    const searchPostalCode = (e) => {
        const value = e.target.value;
        const filteredData = dataPostalCodeFilter().filter((postalCode) => {
            return postalCode.cp.includes(value);
        }
        );
        setDataPostalCode(filteredData.slice(0, 5));

        if (value.length <= 5 && filteredData.some((postalCode) => postalCode.cp === value)) {
            setPostalCode(e.target.value);
            localStorage.setItem("postalCode", e.target.value);
        } else {
            setPostalCode("");
            return
        }
    }

    return (
        <div className="codigo-postal">
            <div className="row align-items-center">
                <label htmlFor="postalCode" className="col-md-4 offset-md-2 col-6 col-form-label">Código Postal</label>
                <div className="col-md-3 col-6">
                    <input type="number" className="form-control" list="postalCodeList" id="postalCode" onChange={searchPostalCode} defaultValue={postalCode} />
                    <datalist id="postalCodeList">
                        {dataPostalCode.map((postalCode, i) => (
                            <option key={i} value={postalCode.cp}>
                               {postalCode.town} ({postalCode.province})
                            </option>
                        ))}
                    </datalist>
                </div>
            </div>
        </div>
    )

}

export default PostalCode;