import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import RatingResidentialVentilation from "../ratingEnergyEfficient/RatingResidentialVentilation";
import { useNavigate } from "react-router-dom";


export default function ResidentialVentilation({item, filtered}) {
    const navigate = useNavigate();

    return (
        <div className="row align-items-baseline">
            <h1 className="text-center mb-4">Unidades de ventilación residencial</h1>
            <section className="col-md-8 tabla-detalles">
                <table>
                    <thead>
                        <tr>
                            <th colSpan="2" className="fw-bold fs-4">Información General</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Potencia de entrada específica</td>
                            <td>{item?.specificPowerInput} W/(m3/h)</td>
                        </tr>
                        <tr>
                            <td>Potencia eléctrica utilizada en el accionamiento del ventilador</td>
                            <td>{item?.fanDrivePowerInput} W</td>
                        </tr>
                        <tr>
                            <td>Flujo de aire de referencia</td>
                            <td>{item?.referenceFlowRate} m3/s</td>
                        </tr>
                        <tr>
                            <td>Diferencia de presión de referencia</td>
                            <td>{item?.referencePressureDifference} Pa</td>
                        </tr>
                        <tr>
                            <td>Consumo anual de electricidad</td>
                            <td>{item?.energyAnnual}  kWh electricidad/año</td>
                        </tr>

                        <tr>
                            <td>Nivel de potencia acústica</td>
                            <td>{item?.soundPowerLevel} dB</td>
                        </tr>
                        <tr>
                            <td>Ahorro anual en calefacción en clima frío</td>
                            <td>{item?.annualHeatingSavedWarmClimate} kWh de energía primaria/año</td>
                        </tr>
                        <tr>
                            <td>Ahorro anual de calefacción en clima templado</td>
                            <td>{item?.annualHeatingSavedWarmClimate} kWh de energía primaria/año</td>
                        </tr>
                        <tr>
                            <td>Ahorro anual de calefacción en clima cálido</td>
                            <td>{item?.annualHeatingSavedColdClimate} kWh de energía primaria/año</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section className="col-md-4">
                <RatingResidentialVentilation item={item}/>
            </section>
            <button className="col-12 mt-5 btn btn-primary" onClick={() => navigate(`/electrodomesticos/comparador`, {state: {item, filtered}})}> Comparar </button>


        </div>

    )
}