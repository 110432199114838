import React from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


export default function Permanence({permanence, setPermanence}) {
    const onSwitchAction = () => {
        setPermanence(!permanence);
    };

    const stylesSwitch = {
        "& .MuiSwitch-track": {
            backgroundColor: permanence ? "var(--naranja) !important" : "var(--azul) !important" 
        },
        "& .MuiSwitch-thumb": {
            background: "rgb(230, 126, 34) !important",
            // eslint-disable-next-line
            background: "radial-gradient(circle, rgba(230, 126, 34, 1) 27%, rgba(0, 42, 87, 1) 27%) !important"
        },
        "& .MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: "var(--naranja) !important"
        },
        "& .MuiFormControlLabel-root .MuiFormControlLabel-label": {
            fontFamily: "Jost, sans-serif !important"
        },
        "& .MuiTypography-root": {
            fontFamily: "Jost, sans-serif !important",
            fontSize: ".9em !important"
        },
    }

    return (
        <FormGroup>
            <FormControlLabel control={
            <Switch color="primary" defaultValue={permanence} />} label="Permanencia" onChange={onSwitchAction} checked={permanence ? true : false} sx={stylesSwitch} />
        </FormGroup>
    )
}