import React, {useEffect, useState} from 'react';
import { getTerms } from '../../services/content.service';

export default function PageTerms() {
    const [data, setData] = useState([]);

    useEffect(() => {
        getTerms()
            .then((response) => {
                setData(response);
            })
    }, [])

    const formatDate = (date) => {
        const newDate = new Date(date);
        const day = newDate.getDate();
        const month = newDate.getMonth();
        const year = newDate.getFullYear();
        return `${day}/${month}/${year}`;
    }

    return (
        <main className='container contenido-generado min-vh-100'>
            <h1>Términos y condiciones</h1>
            <p className='created_at'>Created At: {formatDate(data.created_at)}</p>
        
            <div dangerouslySetInnerHTML={{__html: data.content}}></div>
        </main>     
    )
}