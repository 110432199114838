import React from "react";
import rating from "../../../../assets/img/rating.svg";
import "bootstrap-icons/font/bootstrap-icons.css";
import ImgEnergyClass from "./ImgEnergyClass";
import Tooltip from '@mui/material/Tooltip';

export default function RatingAirconditioners({item}) {
    return (
        <section className="detalle container">
                <div className="marca">
                    <Tooltip title="Marca" placement="top">
                        <h1>{item?.supplierOrTrademark}</h1>
                    </Tooltip>    
                    <Tooltip title="Modelo" placement="top">
                        <p>{item?.modelIdentifier}</p>
                    </Tooltip>
                </div>
            <div className="row">
                <div className="col-12 mb-3 energyClass justify-content-between">
                    <Tooltip title="Clases de eficiencia energética" placement="top" className="clase-energetica-tooltip">
                        <img src={rating} alt="rating" />
                    </Tooltip>
                    <ImgEnergyClass energyClass={item?.energyClass} />
                </div>
                <hr/>
                <div className="col-md-6 col-12 mb-2">
                    <Tooltip title="Carga de diseño de calefacción" placement="top">
                        <div className="text-center d-flex justify-content-center">
                            <i className="bi bi-sun-fill fs-5 me-2"></i>
                            <p className="mb-0"> <span className="fw-bold fs-5" >{item?.heatingDesignLoad}</span> </p>
                        </div>
                    </Tooltip>
                </div>

                <div className="col-md-6 col-12 mb-2">
                    <Tooltip title="Carga de diseño de refrigeración" placement="top">
                        <div className="text-center d-flex justify-content-center align-items-center">
                            <i className="bi bi-snow2 fs-5 me-2"></i>
                            <p className="mb-0"> <span className="fw-bold fs-5" >{item?.coolingDesignLoad}</span>  </p>
                        </div>
                    </Tooltip>
                </div>
                <div className="col-md-6 col-12">
                    <Tooltip title="Nombre del refrigerante" placement="top">
                        <div className="text-center d-flex justify-content-center align-items-center">
                            <i className="bi bi-info fs-3"></i>
                            <p className="mb-0"> <span className="fw-bold fs-5" >{item?.refrigerantName}</span> </p>
                        </div>
                    </Tooltip>
                    
                </div>
                <div className="col-md-6 col-12 ">
                    <Tooltip title="GWP del refrigerante" placement="top">
                        <div className="text-center d-flex justify-content-center align-items-center">
                            <i className="bi bi-info fs-3"></i>
                            <p className="mb-0"> <span className="fw-bold fs-5" >{item?.refrigerantGwp}</span> </p>
                        </div>
                    </Tooltip>
                </div>
                <hr/>
                    <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                    <Tooltip title="Clase energética de calefacción " placement="top">
                        <i className="bi bi-thermometer-sun fs-3"></i>
                    </Tooltip>
                    {item?.heatingEnergyClass === "APPP" &&
                        <p><span className="fw-bold fs-5" >A+++</span> B C D E F G  </p>
                    }  
                    {item?.heatingEnergyClass === "APP" &&
                        <p><span className="fw-bold fs-5" > A++ </span> B C D E F G </p>
                    }   
                    {item?.heatingEnergyClass === "AP" &&
                        <p><span className="fw-bold fs-5" > A+ </span> B C D E F G </p>
                    } 
                    {item?.heatingEnergyClass === "A" &&
                        <p><span className="fw-bold fs-5" >A</span> B C D E F G</p>
                    }   
                    {item?.heatingEnergyClass === "B" &&
                        <p> A <span className="fw-bold fs-5" >B</span> C D E F G </p>
                    }
                    {item?.heatingEnergyClass === "C" &&
                        <p> A B <span className="fw-bold fs-5" >C</span> D E F G</p>
                    }
                    {item?.heatingEnergyClass === "D" &&
                        <p> A B C <span className="fw-bold fs-5" >D E F G  </span></p>
                    }
                    {item?.heatingEnergyClass === "E" &&
                        <p> A B C D <span className="fw-bold fs-5" >E F G</span></p>
                    }
                    {item?.heatingEnergyClass === "F" &&
                        <p> A B C D E <span className="fw-bold fs-5" >F G</span></p>
                    }
                    {item?.heatingEnergyClass === "G" &&
                        <p> A B C D E F <span className="fw-bold fs-5" >G </span></p>
                    }

                </div>
                <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                    <Tooltip title="Clase energética de refrigeración" placement="top">
                        <i className="bi bi-thermometer-snow fs-3"></i>
                    </Tooltip>  
                    {item?.coolingEnergyClass === "APPP" &&
                        <p><span className="fw-bold fs-5" >A+++</span> B C D E F G  </p>
                    }  
                    {item?.coolingEnergyClass === "APP" &&
                        <p><span className="fw-bold fs-5" > A++ </span> B C D E F G </p>
                    }   
                    {item?.coolingEnergyClass === "AP" &&
                        <p><span className="fw-bold fs-5" > A+ </span> B C D E F G </p>
                    } 
                    {item?.coolingEnergyClass === "A" &&
                        <p><span className="fw-bold fs-5" >A</span> B C D E F G</p>
                    }   
                    {item?.coolingEnergyClass === "B" &&
                        <p> A <span className="fw-bold fs-5" >B</span> C D E F G </p>
                    }
                    {item?.coolingEnergyClass === "C" &&
                        <p> A B <span className="fw-bold fs-5" >C</span> D E F G</p>
                    }
                    {item?.coolingEnergyClass === "D" &&
                        <p> A B C <span className="fw-bold fs-5" >D E F G  </span></p>
                    }
                    {item?.coolingEnergyClass === "E" &&
                        <p> A B C D <span className="fw-bold fs-5" >E F G</span></p>
                    }
                    {item?.coolingEnergyClass === "F" &&
                        <p> A B C D E <span className="fw-bold fs-5" >F G</span></p>
                    }
                    {item?.coolingEnergyClass === "G" &&
                        <p> A B C D E F <span className="fw-bold fs-5" >G </span></p>
                    }
                </div>
            

        
            </div>
        </section>
    )
}