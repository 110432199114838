import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import LightHome from "../home/Light";
import GasHome from "../home/Gas";
import LightGasHome from "../home/LightGas";
import LighyCompany from "../company/Light";
import GasCompany from "../company/Gas";
import LightGasCompany from "../company/LightGas";
import { CircularProgress } from "@mui/material";


export default function Comparator() {
    const location = useLocation();    
    const [typeUser, setTypeUser] = useState(location?.state?.data?.typeUser);
    const [service, setService] = useState(location?.state?.data?.service);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [loading]);

    function handleService(e) {
        setService(e.target.value);
    }

    function handleTypeUser(e) {
        setTypeUser(e.target.value);
    }

    return(
        <>
            {loading &&
                <div className="text-center mt-5">
                    <h3 className="h5">Estamos bucando las mejores ofertas para ti</h3>
                    <div className="text-center mt-5">
                        <CircularProgress color="inherit" />
                    </div>
                </div>
            }

            {!loading &&
                <section>
                    <div className="typeUser">
                        <label className={typeUser === 'hogar' ? 'checked' : 'noChecked' }>
                            <input type="radio" name="user" value='hogar' onChange={handleTypeUser}
                            checked={typeUser === 'hogar'}/> Hogar
                        </label>
                        <label className={typeUser === 'negocio' ? 'checked' : 'noChecked' }>
                            <input type="radio" name="user" value='negocio' onChange={handleTypeUser}
                            checked={typeUser === 'negocio'}/> Negocio
                        </label>
                    </div>

                    <div className="typeService">
                        <label className={service === 'luz' ? 'checked' : 'noChecked' }>
                            <input type="radio" name="service" value='luz' onChange={handleService}
                            checked={service === 'luz'}/> Luz
                        </label>
                        <label className={service === 'gas' ? 'checked' : 'noChecked' }>
                            <input type="radio" name="service" value='gas' onChange={handleService}
                            checked={service === 'gas'}/> Gas
                        </label>
                        <label className={service === 'luzGas' ? 'checked' : 'noChecked' }>
                            <input type="radio" name="service" value='luzGas' onChange={handleService}
                            checked={service === 'luzGas'}/> Luz + Gas
                        </label>
                    </div>

                    {typeUser === 'hogar' && service === 'luz' &&
                        <LightHome 
                            setLoading={setLoading}    
                            data={location.state.data} 
                            isResult={true}

                        />
                    }
                    {typeUser === 'hogar' && service === 'gas' &&
                        <GasHome 
                            setLoading={setLoading}    
                            data={location.state.data} 
                            isResult={true}
                        />
                    }
                    {typeUser === 'hogar' && service === 'luzGas' &&
                        <LightGasHome 
                            setLoading={setLoading}    
                            data={location.state.data} 
                            isResult={true}

                        />
                    }

                    {typeUser === 'negocio' && service === 'luz' &&
                        <LighyCompany 
                            setLoading={setLoading}    
                            data={location.state.data} 
                            isResult={true}

                        />
                    }
                    {typeUser === 'negocio' && service === 'gas' &&
                        <GasCompany 
                            setLoading={setLoading}    
                            data={location.state.data} 
                            isResult={true}

                        />
                    }
                    {typeUser === 'negocio' && service === 'luzGas' &&
                        <LightGasCompany 
                            setLoading={setLoading}    
                            data={location.state.data} 
                            isResult={true}

                        />
                    }
                </section>
            }

        </>

    )

}