import React from "react";
import rating from "../../../../assets/img/rating.svg";
import "bootstrap-icons/font/bootstrap-icons.css";
import ImgEnergyClass from "./ImgEnergyClass";
import Tooltip from '@mui/material/Tooltip';

export default function SolidFuelBoilersPackages({item}) {
    return (
        <section className="detalle container">
            <div className="marca">
                <Tooltip title="Marca" placement="top">
                    <h1>{item?.supplierOrTrademark}</h1>
                </Tooltip>
                <Tooltip title="Modelo" placement="top">
                    <p>{item?.modelIdentifier}</p>
                </Tooltip>
            </div>
            <div className="row">
                <div className="col-12 energyClass justify-content-between">
                    <Tooltip title="Clases de eficiencia energética" placement="top" className="clase-energetica-tooltip">
                        <img src={rating} alt="rating" />
                    </Tooltip>
                    <ImgEnergyClass energyClass={item?.energyClass} />
                </div>
                <div className="col-12 text-center">
                    <hr/>
                    <Tooltip title="Índice de eficiencia energética (IEE)" placement="top">
                        <div>
                            <span className="fw-bold fs-5" >{item?.energyEfficiencyIndex}  </span>
                                (IEE)
                        </div>
                    </Tooltip>
                    <hr/>
                </div>
            
                <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                    <Tooltip title="Clasificación del depósito solar" placement="top">
                        <span className="solarTankRating"></span>
                    </Tooltip>
                    {item?.solarTankRating === "APPP" &&
                        <p><span className="fw-bold fs-5" >A+++</span> B C D E F G  </p>
                    }  
                    {item?.solarTankRating === "APP" &&
                        <p><span className="fw-bold fs-5" > A++ </span> B C D E F G </p>
                    }   
                    {item?.solarTankRating === "AP" &&
                        <p><span className="fw-bold fs-5" > A+ </span> B C D E F G </p>
                    } 
                    {item?.solarTankRating === "A" &&
                        <p><span className="fw-bold fs-5" >A</span> B C D E F G</p>
                    }   
                    {item?.solarTankRating === "B" &&
                        <p> A <span className="fw-bold fs-5" >B</span> C D E F G </p>
                    }
                    {item?.solarTankRating === "C" &&
                        <p> A B <span className="fw-bold fs-5" >C</span> D E F G</p>
                    }
                    {item?.solarTankRating === "D" &&
                        <p> A B C <span className="fw-bold fs-5" >D E F G  </span></p>
                    }
                    {item?.solarTankRating === "E" &&
                        <p> A B C D <span className="fw-bold fs-5" >E F G</span></p>
                    }
                    {item?.solarTankRating === "F" &&
                        <p> A B C D E <span className="fw-bold fs-5" >F G</span></p>
                    }
                    {item?.solarTankRating === "G" &&
                        <p> A B C D E F <span className="fw-bold fs-5" >G </span></p>
                    }

                </div>
                <div className="col-md-6 col-12 ">
                    <Tooltip title="Índice de eficiencia energética del paquete" placement="top">
                        <div className="d-flex flex-column align-items-center">
                            <span className="energyEfficiency"></span>
                            <p><span className="fw-bold fs-5" >{item?.packageEnergyEfficiencyIndex}</span> </p>
                        </div>
                    </Tooltip>
                   
                </div>

                <div className="col-md-6 col-12">
                    <Tooltip title="Tamaño del colector solar" placement="top">
                        <div className="d-flex flex-column align-items-center">
                            <i className="bi bi-brightness-high-fill fs-4"></i>
                            <p><span className="fw-bold fs-5" >{item?.solarCollectorSize}</span> m2</p>
                        </div>
                    </Tooltip>
                </div>

                <div className="col-md-6 col-12">
                    <Tooltip title="Eficiencia del colector solar" placement="top">
                        <div className="d-flex flex-column align-items-center">
                            <i className="bi bi-percent fs-4"></i>
                            <p><span className="fw-bold fs-5" >{item?.collectorEfficiency}</span> %</p>
                        </div>
                    </Tooltip>
                </div>
            </div>
            
        </section>
    )
}