import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import LightConsumption from "./LightConsumption";
import GasConsumption from "../globals/GasConsumption";
import PostalCode from "../globals/PostalCode";
import PostalCodeResult from "../results/PostalCode";
import Permanence from "../globals/Permanence";
import AdditionalServices from "../globals/AdditionalServices";
import * as ComparatorService from "../../../services/comparator.service";

function LightGas({setLoading, data, isResult}) {
    const navigate = useNavigate();
    const [lightConsumption, setLightConsumption] = useState(0);
    const [p1, setP1] = useState(0);
    const [p2, setP2] = useState(0);
    const [p3, setP3] = useState(0);
    const [p4, setP4] = useState(0);
    const [p5, setP5] = useState(0);
    const [p6, setP6] = useState(0);
    const [potencia, setPotencia] = useState(0);
    const [potencia1, setPotencia1] = useState(0);
    const [potencia2, setPotencia2] = useState(0);
    const [potencia3, setPotencia3] = useState(0);
    const [potencia4, setPotencia4] = useState(0);
    const [potencia5, setPotencia5] = useState(0);
    const [potencia6, setPotencia6] = useState(0);
    const [gasConsumption, setGasConsumption] = useState(0);
    const [postalCode, setPostalCode] = useState("");
    const [error, setError] = useState("");
    const [additionalServices, setAdditionalServices] = useState(true);
    const [permanence, setPermanence] = useState(true);

    useEffect(() => {
        if (data !== undefined) {
            setLightConsumption(data.consumoAnualE);
            setGasConsumption(data.consumoAnualG);
            setPostalCode(data.codigoPostal);
            setP1(data.consumoPrimeraFranja);
            setP2(data.consumoSegundaFranja);
            setP3(data.consumoTerceraFranja);
            setP4(data.consumoCuartaFranja);
            setP5(data.consumoQuintaFranja);
            setP6(data.consumoSextaFranja);
            setPotencia(data.potencia);
            setPotencia1(data.potenciaPrimeraFranja);
            setPotencia2(data.potenciaSegundaFranja);
            setPotencia3(data.potenciaTerceraFranja);
            setPotencia4(data.potenciaCuartaFranja);
            setPotencia5(data.potenciaQuintaFranja);
            setPotencia6(data.potenciaSextaFranja);
            setAdditionalServices(data.serviciosAdicionales);
            setPermanence(data.permanencia);

        }
    }, [data]);

    function onSubmit(e) {
        e.preventDefault();
        setLoading(true);

        if (postalCode === 0 || postalCode === "") {
            setError("Debes introducir un código postal");
            setLoading(false);

            return;
        } else if (lightConsumption <= 0) {
            setError("Debes introducir un consumo de luz");
            setLoading(false);

            return;
        } else if (gasConsumption <= 0) {
            setError("Debes introducir un consumo de gas");
            setLoading(false);

            return;
        }  

        setError("");
        let data = {
            tipoSuministro:"C",
            codigoPostal:postalCode,
            potencia:potencia,
            consumoAnualE:lightConsumption,
            tarifa:4,
            consumoAnualG:gasConsumption,
            serviciosAdicionales:1,
            permanencia:1,
            vivienda:true,
            potenciaPrimeraFranja:potencia1,
            potenciaSegundaFranja:potencia2,
            potenciaTerceraFranja:potencia3,
            potenciaCuartaFranja:potencia4,
            potenciaQuintaFranja:potencia5,
            potenciaSextaFranja:potencia6,
            consumoPrimeraFranja: p1,
            consumoSegundaFranja: p2,
            consumoTerceraFranja: p3,
            consumoCuartaFranja: p4,
            consumoQuintaFranja: p5,
            consumoSextaFranja: p6,
            typeUser: 'negocio',
            service: 'luzGas'
        };
        
        ComparatorService.getOfferLightGas(data)
        .then((res) => {
            let ofertas = []
            let ofertasLuz = res?.resultadoComparadorConjuntas?.electricidad;
            let ofertasGas = res?.resultadoComparadorConjuntas?.gas;
            let ofertasLuzGas = res?.resultadoComparadorConjuntas?.ofertasConjuntas;
          
            ofertas.push(ofertasLuz);
            ofertas.push(ofertasGas);

            ofertasLuzGas.forEach((item) => {
                ofertas.push(item);
            })

            ofertas.forEach((item, index) => {
                ComparatorService.getDetailOfferLightGas(item.id, data)
                .then((detalle) => {
                    ofertas[index].detalle = detalle;
                    ComparatorService.getImgOffer(item.id)
                    .then((img) => {
                        if(img.length === 0) {
                            ofertas[index].img = 0
                        } else {
                            ofertas[index].img = img;
                        }
                        setLoading(false);
                        navigate("/comparador/resultados", {state: {data: data, results: ofertas, service: 'luzGas'}});
                    })
                })
            })
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
            setError("Ha ocurrido un error, inténtalo de nuevo más tarde");
        }
        );
    }


    return (
        <div className="luz negocio">

            <div>
                {isResult ? (
                    <PostalCodeResult postalCode={postalCode} setPostalCode={setPostalCode} />
                ) : (
                    <PostalCode postalCode={postalCode} setPostalCode={setPostalCode} />
                )}   
                <LightConsumption
                    lightConsumption={lightConsumption}
                    setLightConsumption={setLightConsumption}
                    p1={p1}
                    setP1={setP1}
                    p2={p2}
                    setP2={setP2}
                    p3={p3}
                    setP3={setP3}
                    p4={p4}
                    setP4={setP4}
                    p5={p5}
                    setP5={setP5}
                    p6={p6}
                    setP6={setP6}
                    potencia={potencia}
                    setPotencia={setPotencia}
                    potencia1={potencia1}
                    setPotencia1={setPotencia1}
                    potencia2={potencia2}
                    setPotencia2={setPotencia2}
                    potencia3={potencia3}
                    setPotencia3={setPotencia3}
                    potencia4={potencia4}
                    setPotencia4={setPotencia4}
                    potencia5={potencia5}
                    setPotencia5={setPotencia5}
                    potencia6={potencia6}
                    setPotencia6={setPotencia6}
                />

                <GasConsumption 
                    gasConsumption={gasConsumption}
                    setGasConsumption={setGasConsumption}
                />
            </div>

            <div className="adicionales">
                <div>
                    <Permanence permanence={permanence} setPermanence={setPermanence} />
                </div>
                <div>
                    <AdditionalServices additionalServices={additionalServices} setAdditionalServices={setAdditionalServices} />
                </div>
            </div>

           
            {error !== "" &&
                <div className="alert alert-danger text-center" role="alert">
                    {error}
                </div>
            }
             <div className="form-enviar">
                <button className="btn btn-secondary" type="submit" onClick={onSubmit}>Comparar</button>
            </div>
        </div>
    )
}

export default LightGas;