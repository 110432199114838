import React from 'react';
import slugify from 'slugify';

export default function ImgBrand({ brand }) {
    const slug = slugify(brand.toString(), { 
        lower: true,
        replacement: '-',
        remove: /[*+~.()'"!:@]/g,
    });

    return <img src={`https://hirestadium.com/${slug}.jpg`} alt={brand} className='img-brand' />;
}