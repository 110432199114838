import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HeaderStar from '../layouts/HeaderStar';
import Footer from '../layouts/Footer';

import PageHomeAppliances from '../../pages/homeAppliances/PageHomeAppliances';
import Airconditioners from '../homeAppliances/categories/Airconditioners';
import Dishwashers from '../homeAppliances/categories/Dishwashers';
import ResidentialVentilation from '../homeAppliances/categories/ResidentialVentilation';
import SolidFuelBoilers from '../homeAppliances/categories/SolidFuelBoilers';
import SolidFuelBoilersPackages from '../homeAppliances/categories/SolidFuelBoilersPackages';
import SpaceHeaterTemperatureControl from '../homeAppliances/categories/SpaceHeaterTemperatureControl';
import WasherDryers from '../homeAppliances/categories/WasherDryers';
import WashingMachines from '../homeAppliances/categories/WashingMachines';
import WaterHeaterSolarDevices from '../homeAppliances/categories/WaterHeaterSolarDevices';
import Details from '../homeAppliances/details/Details';
import PageComparator from '../../pages/homeAppliances/PageComparator';

function RouteHomeAppliances() {

    return (
        <>
            <HeaderStar />
            <Routes>
                <Route path='/' element={<PageHomeAppliances />} />
                <Route path='acondicionadores' element={<Airconditioners />} />
                <Route path='lavavajillas' element={<Dishwashers />} />
                <Route path='ventilacion-residencial' element={<ResidentialVentilation />} />
                <Route path='calderas' element={<SolidFuelBoilers />} />
                <Route path='equipos-calderas' element={<SolidFuelBoilersPackages />} />
                <Route path='controles-temperatura' element={<SpaceHeaterTemperatureControl />} />
                <Route path='lavadoras-secadoras' element={<WasherDryers />} />
                <Route path='lavadoras' element={<WashingMachines />} />
                <Route path='calentadores-agua' element={<WaterHeaterSolarDevices />} />
                <Route path='detalle' element={<Details />} />
                <Route path='comparador' element={<PageComparator />} />
            </Routes>
            <Footer />
        </>
    )
}

export default RouteHomeAppliances;