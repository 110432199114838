import React from "react";
import RoundSlider from "./plugins/RoundSlider";

function GasConsumption ({gasConsumption, setGasConsumption}) {

    function handleChangeGas(e){
        const value = e.value;
        setGasConsumption(value)
    }

    return (
        <div className="mt-1 row d-flex align-items-center justify-content-start border-negocio">
            <section className="col-md-3 col-12 text-center" id="gas-potencia">
                <h3>Gas</h3>
                <RoundSlider
                    handleShape= "dot"
                    sliderType= "min-range"
                    width= {7}
                    showTooltip= {true}
                    value= {gasConsumption}
                    min= {99}
                    max= {0}
                    svgMode= {true}
                    pathColor= "#eee"
                    borderWidth= {0}
                    circleShape= "custom-quarter"
                    startAngle={230}
                    lineCap="round"
                    radius={70}
                    startValue={0}
                    rangeColor= "#e67e22"
                    update= {handleChangeGas}
                />

            </section>
            <section className="col-md-5 ms-md-5 col-12 text-center">
                <p className="consumoGas ms-md-2">Consumo de gas: <span className="fw-bold">{gasConsumption}</span>  m3 </p>
            </section>
        </div>

    )
}

export default GasConsumption;