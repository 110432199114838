import React from "react";
import rating from "../../../../assets/img/rating.svg";
import "bootstrap-icons/font/bootstrap-icons.css";
import ImgEnergyClass from "./ImgEnergyClass";
import Tooltip from '@mui/material/Tooltip';


export default function RatingDishwashers({item}) {
    
    return (
        <section className="detalle container">
            <div className="marca">
                <Tooltip title="Marca" placement="top">
                    <h1>{item?.supplierOrTrademark}</h1>
                </Tooltip>
                <Tooltip title="Modelo" placement="top">
                    <p>{item?.modelIdentifier}</p>
                </Tooltip>
            </div>
            <div className="row">
                <div className="col-12 energyClass justify-content-between">
                    <Tooltip title="Clases de eficiencia energética" placement="top" className="clase-energetica-tooltip">
                        <img src={rating} alt="rating" />
                    </Tooltip>
                    <ImgEnergyClass energyClass={item?.energyClass} />
                </div>
            
                <div className="col-12 text-center">
                    <hr/>
                    <Tooltip title="Consumo de energía por 100 ciclos" placement="top">
                        <div>
                            <span className="fw-bold fs-5" >{item?.energyCons100}  </span>
                            kW/h <span className="per100cyclesIcon"></span>
                        </div>
                    </Tooltip>    
                    <hr/>
                </div>
            
                <div className="col-md-6 col-12">
                    <Tooltip title="Capacidad nominal" placement="top">
                        <div className="text-center d-flex flex-column align-items-center">
                            <span className="ratedCapacityDishW"></span>
                            <p> <span className="fw-bold fs-5" >{item?.ratedCapacity}</span>  kg</p>
                        </div>
                    </Tooltip>
                </div>

                <div className="col-md-6 col-12">
                    <Tooltip title="Consumo de agua" placement="top">
                        <div className="text-center d-flex flex-column align-items-center">
                            <span className="waterConsumptionIcon"></span>
                            <p> <span className="fw-bold fs-5" >{item?.waterCons}</span>  L</p>
                        </div>
                    </Tooltip>
                </div>

                <div className="col-md-6 col-12 mt-2 d-flex flex-column align-items-center">
                    <Tooltip title="Clase de ruido" placement="top">
                        <span className="noiseClass"></span>
                    </Tooltip>
                    {item?.noiseClass === "APPP" &&
                        <p><span className="fw-bold fs-5" >A+++</span> B C D E F G  </p>
                    }  
                    {item?.noiseClass === "APP" &&
                        <p><span className="fw-bold fs-5" > A++ </span> B C D E F G </p>
                    }   
                    {item?.noiseClass === "AP" &&
                        <p><span className="fw-bold fs-5" > A+ </span> B C D E F G </p>
                    } 
                    {item?.noiseClass === "A" &&
                        <p><span className="fw-bold fs-5" >A</span> B C D E F G</p>
                    }   
                    {item?.noiseClass === "B" &&
                        <p> A <span className="fw-bold fs-5" >B</span> C D E F G </p>
                    }
                    {item?.noiseClass === "C" &&
                        <p> A B <span className="fw-bold fs-5" >C</span> D E F G</p>
                    }
                    {item?.noiseClass === "D" &&
                        <p> A B C <span className="fw-bold fs-5" >D E F G  </span></p>
                    }
                    {item?.noiseClass === "E" &&
                        <p> A B C D <span className="fw-bold fs-5" >E F G</span></p>
                    }
                    {item?.noiseClass === "F" &&
                        <p> A B C D E <span className="fw-bold fs-5" >F G</span></p>
                    }
                    {item?.noiseClass === "G" &&
                        <p> A B C D E F <span className="fw-bold fs-5" >G </span></p>
                    }
                </div>

                <div className="col-md-6 col-12">
                    <Tooltip title="Duración del programa" placement="top">
                        <div className="d-flex flex-column align-items-center">
                            <i className="bi bi-clock-history fs-4"></i>
                            <p><span className="fw-bold fs-5" >{item?.programmeDuration}</span> min</p>
                        </div>
                    </Tooltip>
                </div>
            </div>
            
        </section>

    )
}