async function getOfferLight(data) {

    const response = await fetch(
        `https://hirestadium.com/get/light-offers?codigoPostal=${data.codigoPostal}&potencia=${data.potencia}&consumoAnualE=${data.consumoAnualE}&tarifa=${data.tarifa}&consumoAnualG=${data.consumoAnualG}&serviciosAdicionales=${data.serviciosAdicionales}&permanencia=${data.permanencia}&vivienda=${data.vivienda}&potenciaPrimeraFranja=${data.potenciaPrimeraFranja}&potenciaSegundaFranja=${data.potenciaSegundaFranja}&potenciaTerceraFranja=${data.potenciaTerceraFranja}&potenciaCuartaFranja=${data.potenciaCuartaFranja}&potenciaQuintaFranja=${data.potenciaQuintaFranja}&potenciaSextaFranja=${data.potenciaSextaFranja}&consumoPrimeraFranja=${data.consumoPrimeraFranja}&consumoSegundaFranja=${data.consumoSegundaFranja}&consumoTerceraFranja=${data.consumoTerceraFranja}&consumoCuartaFranja=${data.consumoCuartaFranja}&consumoQuintaFranja=${data.consumoQuintaFranja}&consumoSextaFranja=${data.consumoSextaFranja}` 
        ,{ method: 'GET',
    })
    return await response.json()
}

async function getDetailOfferLight(id, data) {
    const response = await fetch(`https://hirestadium.com/get/light-offer?codigoPostal=${data.codigoPostal}&potencia=${data.potencia}&consumoAnualE=${data.consumoAnualE}&tarifa=${data.tarifa}&consumoAnualG=${data.consumoAnualG}&serviciosAdicionales=${data.serviciosAdicionales}&permanencia=${data.permanencia}&vivienda=${data.vivienda}&potenciaPrimeraFranja=${data.potenciaPrimeraFranja}&potenciaSegundaFranja=${data.potenciaSegundaFranja}&potenciaTerceraFranja=${data.potenciaTerceraFranja}&potenciaCuartaFranja=${data.potenciaCuartaFranja}&potenciaQuintaFranja=${data.potenciaQuintaFranja}&potenciaSextaFranja=${data.potenciaSextaFranja}&consumoPrimeraFranja=${data.consumoPrimeraFranja}&consumoSegundaFranja=${data.consumoSegundaFranja}&consumoTerceraFranja=${data.consumoTerceraFranja}&consumoCuartaFranja=${data.consumoCuartaFranja}&consumoQuintaFranja=${data.consumoQuintaFranja}&consumoSextaFranja=${data.consumoSextaFranja}&idOferta=${id}`, {
        method: 'GET',
    })
    return await response.json()
}

async function getOfferGas(data) {
    const response = await fetch(
        `https://hirestadium.com/get/gas-offers?codigoPostal=${data.codigoPostal}&potencia=${data.potencia}&consumoAnualE=${data.consumoAnualE}&tarifa=${data.tarifa}&consumoAnualG=${data.consumoAnualG}&serviciosAdicionales=${data.serviciosAdicionales}&permanencia=${data.permanencia}&vivienda=${data.vivienda}&potenciaPrimeraFranja=${data.potenciaPrimeraFranja}&potenciaSegundaFranja=${data.potenciaSegundaFranja}&potenciaTerceraFranja=${data.potenciaTerceraFranja}&potenciaCuartaFranja=${data.potenciaCuartaFranja}&potenciaQuintaFranja=${data.potenciaQuintaFranja}&potenciaSextaFranja=${data.potenciaSextaFranja}&consumoPrimeraFranja=${data.consumoPrimeraFranja}&consumoSegundaFranja=${data.consumoSegundaFranja}&consumoTerceraFranja=${data.consumoTerceraFranja}&consumoCuartaFranja=${data.consumoCuartaFranja}&consumoQuintaFranja=${data.consumoQuintaFranja}&consumoSextaFranja=${data.consumoSextaFranja}` 
        ,{ method: 'GET',
    })
    return await response.json()
}

async function getDetailOfferGas(id, data) {
    const response = await fetch(`https://hirestadium.com/get/gas-offer?codigoPostal=${data.codigoPostal}&potencia=${data.potencia}&consumoAnualE=${data.consumoAnualE}&tarifa=${data.tarifa}&consumoAnualG=${data.consumoAnualG}&serviciosAdicionales=${data.serviciosAdicionales}&permanencia=${data.permanencia}&vivienda=${data.vivienda}&potenciaPrimeraFranja=${data.potenciaPrimeraFranja}&potenciaSegundaFranja=${data.potenciaSegundaFranja}&potenciaTerceraFranja=${data.potenciaTerceraFranja}&potenciaCuartaFranja=${data.potenciaCuartaFranja}&potenciaQuintaFranja=${data.potenciaQuintaFranja}&potenciaSextaFranja=${data.potenciaSextaFranja}&consumoPrimeraFranja=${data.consumoPrimeraFranja}&consumoSegundaFranja=${data.consumoSegundaFranja}&consumoTerceraFranja=${data.consumoTerceraFranja}&consumoCuartaFranja=${data.consumoCuartaFranja}&consumoQuintaFranja=${data.consumoQuintaFranja}&consumoSextaFranja=${data.consumoSextaFranja}&idOferta=${id}`, {
        method: 'GET',
    })
    return await response.json()
}

async function getOfferLightGas(data) {
    const response = await fetch(
        `https://hirestadium.com/get/combined-offers?codigoPostal=${data.codigoPostal}&potencia=${data.potencia}&consumoAnualE=${data.consumoAnualE}&tarifa=${data.tarifa}&consumoAnualG=${data.consumoAnualG}&serviciosAdicionales=${data.serviciosAdicionales}&permanencia=${data.permanencia}&vivienda=${data.vivienda}&potenciaPrimeraFranja=${data.potenciaPrimeraFranja}&potenciaSegundaFranja=${data.potenciaSegundaFranja}&potenciaTerceraFranja=${data.potenciaTerceraFranja}&potenciaCuartaFranja=${data.potenciaCuartaFranja}&potenciaQuintaFranja=${data.potenciaQuintaFranja}&potenciaSextaFranja=${data.potenciaSextaFranja}&consumoPrimeraFranja=${data.consumoPrimeraFranja}&consumoSegundaFranja=${data.consumoSegundaFranja}&consumoTerceraFranja=${data.consumoTerceraFranja}&consumoCuartaFranja=${data.consumoCuartaFranja}&consumoQuintaFranja=${data.consumoQuintaFranja}&consumoSextaFranja=${data.consumoSextaFranja}` 
        ,{ method: 'GET',
    })
    return await response.json()
}

async function getDetailOfferLightGas(id, data) {
    const response = await fetch(`https://hirestadium.com/get/combined-offer?codigoPostal=${data.codigoPostal}&potencia=${data.potencia}&consumoAnualE=${data.consumoAnualE}&tarifa=${data.tarifa}&consumoAnualG=${data.consumoAnualG}&serviciosAdicionales=${data.serviciosAdicionales}&permanencia=${data.permanencia}&vivienda=${data.vivienda}&potenciaPrimeraFranja=${data.potenciaPrimeraFranja}&potenciaSegundaFranja=${data.potenciaSegundaFranja}&potenciaTerceraFranja=${data.potenciaTerceraFranja}&potenciaCuartaFranja=${data.potenciaCuartaFranja}&potenciaQuintaFranja=${data.potenciaQuintaFranja}&potenciaSextaFranja=${data.potenciaSextaFranja}&consumoPrimeraFranja=${data.consumoPrimeraFranja}&consumoSegundaFranja=${data.consumoSegundaFranja}&consumoTerceraFranja=${data.consumoTerceraFranja}&consumoCuartaFranja=${data.consumoCuartaFranja}&consumoQuintaFranja=${data.consumoQuintaFranja}&consumoSextaFranja=${data.consumoSextaFranja}&idOferta=${id}&`, {
        method: 'GET',
    })
    return await response.json()
}

async function getImgOffer(id) {
    let response = await fetch(`https://hirestadium.com/get/offer-logo/${id}`, {
        method: 'GET',
        contentType: 'text/plain',
    })
    //retorna la imagen en base64
    return await response.text()
}

export {
    getOfferLight,
    getDetailOfferLight,
    getOfferGas,
    getDetailOfferGas,
    getOfferLightGas,
    getDetailOfferLightGas,
    getImgOffer,
}