import React from 'react';
import notificaciones from "../../assets/img/notificaciones-precio-luz.svg";


export default function Notifications() {
    const [telefono, setTelefono] = React.useState(0);
    const [error, setError] = React.useState("");


    const onFormSubmit = (event) => {
        event.preventDefault();
        let tel = document.getElementById("telefono").value;
        if (tel !== 0 && tel.length !== 0) {
            setTelefono(tel)
            setError("");
            window.open('https://www.google.com.ar/', '_blank')
        } else {
            setError("Ingrese un número de teléfono válido");
        }
    }

    return(
        <section className="notificaciones row">
            <div className="col-md-8 align-contents-end">
                <h2 className="h2">¿Quieres recibir los precios de la luz para poner la lavadora?</h2>
                <p className='mb-0'>Recibe el precio de la luz en tu teléfono</p>
                <form>
                    <div className="d-block">
                        <label className="visually-hidden" htmlFor="telefono">Teléfono</label>
                        <input className="form-control" type="tel" id="telefono" name={telefono} placeholder="Teléfono" required 
                        onChange={e => setTelefono(e.target.value)} />
                        {error && <p className="text-danger">{error}</p>}
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={onFormSubmit}>Enviar</button>

                </form>
            </div>

            <div className="col-md-4 text-left">
                <img src={notificaciones} alt="Notificaciones del precio de la luz en tu celular" />
            </div>
        </section>
    )
}