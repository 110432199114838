import React, { useEffect, useState } from "react";

export default function Ranges({ dayPrices }) {
    const [rangePunta, setRangePunta] = useState([])
    const [rangeValle, setRangeValle] = useState([])
    const [rangeLlano, setRangeLlano] = useState([])

    useEffect(() => {
        const puntaRanges = [];
        const valleRanges = [];
        const llanoRanges = [];

        const punta = dayPrices.slice(0, 24)
        punta.forEach((price, index) => {
            if (price.color === "#D93D3D") {
                if (puntaRanges.length === 0 || puntaRanges[puntaRanges.length - 1].end !== index - 1) {
                    puntaRanges.push({ start: index, end: index });
                } else {
                    puntaRanges[puntaRanges.length - 1].end = index;
                }
            } else if (price.color === "#1C8C4D") {
                if (valleRanges.length === 0 || valleRanges[valleRanges.length - 1].end !== index - 1) {
                    valleRanges.push({ start: index, end: index });
                } else {
                    valleRanges[valleRanges.length - 1].end = index;
                }
            } else if (price.color === "#FFC108") {
                if (llanoRanges.length === 0 || llanoRanges[llanoRanges.length - 1].end !== index - 1) {
                    llanoRanges.push({ start: index, end: index });
                } else {
                    llanoRanges[llanoRanges.length - 1].end = index;
                }
            }
        });

        setRangePunta(puntaRanges);
        setRangeValle(valleRanges);
        setRangeLlano(llanoRanges);

    }, [dayPrices]);

    return (
        <div className="ranges">
            <div className="range-llano">
                <h3>Franja horaria Llano</h3>
                <ul>
                    <li>
                        {rangeLlano.map((range, index) => (
                            <span key={index}>
                                {range.start === range.end ? (
                                    <span><i className="bi bi-dash-lg"></i> {range.start}  -  {range.start + 1} </span>
                                ) : (
                                    <span><i className="bi bi-dash-lg"></i> {range.start}  -  {range.end} </span>
                                )
                                }
                            </span>
                        ))}

                    </li>
                </ul>
            </div>

            <div className="range-valle">
                <h3>Franja horaria Valle</h3>
                <ul>
                    <li >
                        {rangeValle.map((range, index) => (
                            <span key={index}>
                                {range.start === range.end ? (
                                    <span> <i className="bi bi-caret-down-fill"></i>{range.start}  -  {range.start + 1}  </span>
                                ) : (
                                    <span> <i className="bi bi-caret-down-fill"></i>{range.start}  -  {range.end} </span>
                                )
                                }
                            </span>
                        ))}

                    </li>

                </ul>
            </div>

            <div className="range-punta">
                <h3>Franja horaria Punta</h3>
                <ul>
                    <li> 
                        {rangePunta.map((range, index) => (
                            <span key={index}>
                                {range.start === range.end ? (
                                    <span><i className="bi bi-caret-up-fill"></i>{range.start}  -  {range.start + 1} </span>
                                ) : (
                                    <span><i className="bi bi-caret-up-fill"></i>{range.start}  -  {range.end}</span>
                                )
                                }
                            </span>
                        ))}

                    </li>
                </ul>
            </div>

        </div>

    )

}