import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import ImgBrand from './ImgBrand';
import MeasurementTooltip from './MeasurementTooltip';
import A from '../../../../../assets/img/energyClass/a.svg';
import APPP from '../../../../../assets/img/energyClass/appp.svg';
import APP from '../../../../../assets/img/energyClass/app.svg';
import AP from '../../../../../assets/img/energyClass/ap.svg';
import B from '../../../../../assets/img/energyClass/b.svg';
import C from '../../../../../assets/img/energyClass/c.svg';
import D from '../../../../../assets/img/energyClass/d.svg';
import E from '../../../../../assets/img/energyClass/e.svg';
import F from '../../../../../assets/img/energyClass/f.svg';
import G from '../../../../../assets/img/energyClass/g.svg';


function ResultsList({ item, filtered, arrayCharacteristics, item1, setItem1, item2, setItem2, selectedItems, setSelectedItems, productGroup }) {
    const navigate = useNavigate();
    function handleItem() {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((itemSelected) => itemSelected !== item))
            if (item1 === item) {
                setItem1(null)
            } else if (item2 === item) {
                setItem2(null)
            }
        } else {
            if (selectedItems.length < 2) {
                setSelectedItems([...selectedItems, item])
                if (item1 === null) {
                    setItem1(item)
                } else if (item2 === null) {
                    setItem2(item)
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Solo puedes comparar 2 productos',
                    confirmButtonColor: '#013D0B',
                })
            }
        }
    }

    useEffect(() => {
        if (selectedItems.length === 2) {
            Swal.fire({
                title: 'Producto seleccionado',
                text: `Has seleccionado ${selectedItems.length === 1 ? 'un producto' : selectedItems.length + ' productos'} para comparar.`,
                icon: 'success',
                confirmButtonColor: '#013D0B',
                confirmButtonText: 'Ir al comparador',
            }).then((result) => {
                if (result.isConfirmed) {
                    let item1 = null
                    let item2 = null
                    selectedItems.forEach((item, i) => {
                        if (i === 0) {
                            item1 = item
                        } else if (i === 1) {
                            item2 = item
                        }
                    })
                    navigate('/electrodomesticos/comparador', { state: { item1, item2, filtered, productGroup } })
                }
            });
        } else if (selectedItems.length > 0) {
            Swal.fire({
                title: 'Producto seleccionado',
                text: `Has seleccionado ${selectedItems.length === 1 ? 'un producto' : selectedItems.length + ' productos'} para comparar.`,
                icon: 'success',
                confirmButtonColor: '#013D0B',
                confirmButtonText: 'Cerrar',
            });
        }
        // eslint-disable-next-line
    }, [selectedItems])


    return (
        <tr>
            <td> <ImgBrand brand={item?.organisation?.organisationTitle} /></td>
            <td>
                <p className='fw-bold'>{item?.supplierOrTrademark}</p>
                <p>{item?.modelIdentifier}</p>
            </td>
            {arrayCharacteristics.map((characteristic, i) => {
                if (characteristic.value === "energyClass") {
                    switch (item[characteristic.value]) {
                        case "APPP":
                            return <td key={i} className='table-min-width'> <img src={APPP} alt="Clase energética A+++" /> </td>
                        case "APP":
                            return <td key={i} className='table-min-width'> <img src={APP} alt="Clase energética A++" /> </td>
                        case "AP":
                            return <td key={i} className='table-min-width'> <img src={AP} alt="Clase energética A+" /> </td>
                        case "A":
                            return <td key={i} className='table-min-width'> <img src={A} alt="Clase energética A" /> </td>
                        case "B":
                            return <td key={i} className='table-min-width'> <img src={B} alt="Clase energética B" /> </td>
                        case "C":
                            return <td key={i} className='table-min-width'> <img src={C} alt="Clase energética C" /> </td>
                        case "D":
                            return <td key={i} className='table-min-width'> <img src={D} alt="Clase energética D" /> </td>
                        case "E":
                            return <td key={i} className='table-min-width'> <img src={E} alt="Clase energética E" /> </td>
                        case "F":
                            return <td key={i} className='table-min-width'> <img src={F} alt="Clase energética F" /> </td>
                        case "G":
                            return <td key={i} className='table-min-width'> <img src={G} alt="Clase energética G" /> </td>
                        default:
                            return <td key={i} className='table-min-width'> {item[characteristic.value]} </td>

                    }
                } else {
                    return <td key={characteristic.value}>
                        <span className='fw-semibold'>{item[characteristic.value]}</span> {"   "}

                        <MeasurementTooltip measurement={characteristic.measurement} />
                    </td>
                }

            })}
            <td className='py-0'>
                <div className='d-flex flex-column justify-content-between botones-star align-items-center'>
                    <button type='button' className='btn btn-primary' onClick={() => navigate(`/electrodomesticos/detalle`, { state: { item, filtered } })}> Ver Detalles </button>
                    <button type='button' onClick={() => handleItem()} className={selectedItems.includes(item) ? 'btn btn-grey' : 'btn btn-secondary'}>
                        {selectedItems.includes(item) ? 'Deseleccionar' : 'Seleccionar'}
                    </button>
                </div>
            </td>
        </tr>
    )
}

export default ResultsList