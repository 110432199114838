import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import RatingSolidFuelBoilers from "../ratingEnergyEfficient/RatingSolidFuelBoilers";
import { useNavigate } from "react-router-dom";


export default function SolidFuelBoilers({item, filtered}) {
    const navigate = useNavigate();
    return (
        <div className="row align-items-baseline">
            <h1 className="text-center mb-4">Calderas de combustible sólido</h1>
            <section className="col-md-8 tabla-detalles">
                <table>
                    <thead>
                        <tr>
                            <th colSpan="2" className="fw-bold fs-4">Información General</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Potencia calorífica nominal</td>
                            <td>{item?.ratedHeatOutput} kW</td>
                        </tr>
                        <tr>
                            <td>Índice de eficiencia energética (IEE)</td>
                            <td>{item?.energyEfficiencyIndex}</td>
                        </tr>
                        <tr>
                            <td>Eficiencia energética estacional de calefacción de espacios</td>
                            <td>{item?.seasonalHeatingEnergyEfficiency} %</td>
                        </tr>
                        
                    </tbody>
                </table>
            </section>
            <section className="col-md-4">
                <RatingSolidFuelBoilers item={item} />
            </section>
            <button className="col-12 mt-5 btn btn-primary" onClick={() => navigate(`/electrodomesticos/comparador`, {state: {item, filtered}})}> Comparar </button>

        </div>
    )
}