import React, {useState,useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import KnowConsumption from "../globals/KnowConsumption";
import ElectricalAppliances from "./ElectricalAppliances";
import GasAppliances from "./GasAppliances";
import SelectorHome from "./SelectorHome";
import LightConsumption from "./LightConsumption";
import GasConsumption from "../globals/GasConsumption";
import PostalCode from "../globals/PostalCode";
import PostalCodeResult from "../results/PostalCode";
import Permanence from "../globals/Permanence";
import AdditionalServices from "../globals/AdditionalServices";
import * as ComparatorService from "../../../services/comparator.service";


function LightGas({setLoading, data, isResult}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [knowConsumption, setKnowConsumption] = useState(data?.switchConsumo);
    const [postalCode, setPostalCode] = useState("");
    const [isSwitchOn, setIsSwitchOn] = useState(data?.switchConsumo);
    const [electric, setElectric] = useState(0);
    const [gas, setGas] = useState(0);
    const [valueFamily, setValueFamily] = useState(data?.valueFamilyData);
    const [valueMetros, setValueMetros] = useState(data?.valueMetrosData);
    const [lightConsumption, setLightConsumption] = useState(0);
    const [consumoPunta, setConsumoPunta] = useState(0);
    const [consumoLlano, setConsumoLlano] = useState(0);
    const [consumoValle, setConsumoValle] = useState(0);
    const [gasConsumption, setGasConsumption] = useState(0);
    const [arrayElectric, setArrayElectric] = useState(data?.valueArrayElectric);
    const [arrayGas, setArrayGas] = useState(data?.valueArrayGas);
    const [error, setError] = useState("");
    const [additionalServices, setAdditionalServices] = useState(true);
    const [permanence, setPermanence] = useState(true);
    

    useEffect(() => {
        if(data !== undefined) {
            setKnowConsumption(data.switchConsumo);
            setPostalCode(data.codigoPostal);
            setPermanence(data.permanencia);
            setAdditionalServices(data.serviciosAdicionales);
            setLightConsumption(data.consumoAnualE);
            setConsumoPunta(data.consumoPrimeraFranja);
            setConsumoLlano(data.consumoSegundaFranja);
            setConsumoValle(data.consumoTerceraFranja);
            setGasConsumption(data.consumoAnualG);
            setArrayElectric(data.valueArrayElectric)
            setArrayGas(data.valueArrayGas);
            setValueFamily(data.valueFamilyData);
            setValueMetros(data.valueMetrosData);
            setElectric(data.consumoPrimeraFranja + data.consumoTerceraFranja + data.consumoSegundaFranja)
            setGas(data.consumoAnualG);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, location.state]);

    useEffect(() => {
        setKnowConsumption(isSwitchOn);
    }, [isSwitchOn]);

    function onSubmit(e) {
        e.preventDefault();
        setLoading(true);
        if(postalCode === "" || postalCode.length !== 5 || postalCode <= 0){
            setError("Coloca un código postal válido");
            setLoading(false);

            return;
        }

        setError("");

        if(knowConsumption) {
            if(lightConsumption <= 0 || lightConsumption === ""){
                setError("El consumo total debe ser mayor a 0");
                setLoading(false);

                return;
            } else if(consumoPunta <= 0 || consumoPunta === ""){
                setError("El consumo en punta debe ser mayor a 0");
                setLoading(false);

                return;
            } else if(consumoLlano <= 0 || consumoLlano === ""){
                setError("El consumo en llano debe ser mayor a 0");
                setLoading(false);

                return;
            } else if(consumoValle <= 0 || consumoValle === ""){
                setError("El consumo en valle debe ser mayor a 0");
                setLoading(false);

                return;
            } else if(gasConsumption <= 0 || gasConsumption === ""){
                setError("El consumo total debe ser mayor a 0");
                setLoading(false);

                return;
            }

            setError("");
            let data = {
                tipoSuministro:"C",
                codigoPostal:postalCode,
                potencia:3.3,
                consumoAnualE:lightConsumption,
                tarifa:4,
                consumoAnualG:gasConsumption,
                serviciosAdicionales:1,
                permanencia:1,
                vivienda:true,
                potenciaPrimeraFranja:3.3,
                potenciaSegundaFranja:3.3,
                potenciaTerceraFranja:3.3,
                potenciaCuartaFranja:3.3,
                potenciaQuintaFranja:3.3,
                potenciaSextaFranja:3.3,
                consumoPrimeraFranja:consumoPunta,
                consumoSegundaFranja:consumoLlano,
                consumoTerceraFranja:consumoValle,
                consumoCuartaFranja:0,
                consumoQuintaFranja:0,
                consumoSextaFranja:0,
                switchConsumo:true,
                valueArrayElectric: arrayElectric,
                valueArrayGas: arrayGas,
                valueFamilyData: valueFamily,
                valueMetrosData: valueMetros,
                typeUser: 'hogar',
                service: 'luzGas'



            }
            ComparatorService.getOfferLightGas(data)
            .then((res) => {
                let ofertas = []
                let ofertasLuz = res.resultadoComparadorConjuntas.electricidad;
                let ofertasGas = res.resultadoComparadorConjuntas.gas;
                let ofertasLuzGas = res.resultadoComparadorConjuntas.ofertasConjuntas;
              
                ofertas.push(ofertasLuz);
                ofertas.push(ofertasGas);

                ofertasLuzGas.forEach((item) => {
                    ofertas.push(item);
                })

                ofertas.forEach((item, index) => {
                    ComparatorService.getDetailOfferLightGas(item.id, data)
                    .then((detalle) => {
                        ofertas[index].detalle = detalle;
                        ComparatorService.getImgOffer(item.id)
                        .then((img) => {
                            if(img.length === 0) {
                                ofertas[index].img = 0
                            } else {
                                ofertas[index].img = img;
                            }
                            setLoading(false);
                            navigate("/comparador/resultados", {state: {data: data, results: ofertas, service: 'luzGas'}});
                        })
                    })
                })
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setError("Ha ocurrido un error, inténtalo de nuevo más tarde");
            });

        } else if (!knowConsumption) {
            if(electric === 0){
                setError("Tiene que haber al menos un electrodoméstico eléctrico");
                setLoading(false);

                return;
            } else if(gas === 0){
                setError("Tiene que haber al menos un electrodoméstico de gas");
                setLoading(false);

                return;
            }
            setError("");

            const primeraFranja = Math.round( (28.6 / 100) * electric);
            const segundaFranja = Math.round( (24.6 / 100) * electric);
            const terceraFranja = Math.round( (46.8 / 100) * electric);
            const consumoAnualG = gas;

            let data = {
                tipoSuministro:"C",
                codigoPostal:postalCode,
                potencia:3.3,
                consumoAnualE:lightConsumption,
                tarifa:4,
                consumoAnualG:consumoAnualG,
                serviciosAdicionales:1,
                permanencia:1,
                vivienda:true,
                potenciaPrimeraFranja:3.3,
                potenciaSegundaFranja:3.3,
                potenciaTerceraFranja:3.3,
                potenciaCuartaFranja:3.3,
                potenciaQuintaFranja:3.3,
                potenciaSextaFranja:3.3,
                consumoPrimeraFranja:primeraFranja,
                consumoSegundaFranja:segundaFranja,
                consumoTerceraFranja:terceraFranja,
                consumoCuartaFranja:0,
                consumoQuintaFranja:0,
                consumoSextaFranja:0,
                switchConsumo:false,
                valueArrayElectric: arrayElectric,
                valueArrayGas: arrayGas,
                valueFamilyData: valueFamily,
                valueMetrosData: valueMetros,
                typeUser: 'hogar',
                service: 'luzGas'
                
            };
            
            ComparatorService.getOfferLightGas(data)
            .then((res) => {
                let ofertas = []
                let ofertasLuz = res.resultadoComparadorConjuntas.electricidad;
                let ofertasGas = res.resultadoComparadorConjuntas.gas;
                let ofertasLuzGas = res.resultadoComparadorConjuntas.ofertasConjuntas;
              
                ofertas.push(ofertasLuz);
                ofertas.push(ofertasGas);

                ofertasLuzGas.forEach((item) => {
                    ofertas.push(item);
                })

                ofertas.forEach((item, index) => {
                    ComparatorService.getDetailOfferLightGas(item.id, data)
                    .then((detalle) => {
                        ofertas[index].detalle = detalle;
                        ComparatorService.getImgOffer(item.id)
                        .then((img) => {
                            if(img.length === 0) {
                                ofertas[index].img = 0
                            } else {
                                ofertas[index].img = img;
                            }
                            setLoading(false);
                            navigate("/comparador/resultados", {state: {data: data, results: ofertas, service: 'luzGas'}});
                        })
                    })
                })
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setError("Ha ocurrido un error, inténtalo de nuevo más tarde");
            });
        }
    }

    return (
        <div className="luz">
            <div className="container">
                
                <div className="row d-flex align-items-center">
                    <div className="col-md-5 px-0">
                        <KnowConsumption isSwitchOn={isSwitchOn} setIsSwitchOn={setIsSwitchOn} />
                    </div>
                    {isResult ?
                        (
                            <div className="col-md-7 align-self-center px-0">
                                <PostalCodeResult postalCode={postalCode} setPostalCode={setPostalCode} />
                            </div>
                        ) : (
                            <div className="col-md-7 align-self-center px-0">
                                <PostalCode postalCode={postalCode} setPostalCode={setPostalCode} />
                            </div>
                    )}   
                </div>
            </div>

            {knowConsumption && (
                <div>
                    <LightConsumption
                        lightConsumption={lightConsumption}
                        setLightConsumption={setLightConsumption}
                        consumoPunta={consumoPunta}
                        setConsumoPunta={setConsumoPunta}
                        consumoLlano={consumoLlano}
                        setConsumoLlano={setConsumoLlano}
                        consumoValle={consumoValle}
                        setConsumoValle={setConsumoValle}

                    />
                    <GasConsumption
                        gasConsumption={gasConsumption}
                        setGasConsumption={setGasConsumption}
                    />


                </div>
            )}
            {!knowConsumption && (
                <div>
                    <ElectricalAppliances 
                        electric={electric}
                        setElectric={setElectric}
                        arrayElectric={arrayElectric}
                        setArrayElectric={setArrayElectric}
                    />
                    <GasAppliances 
                        gas={gas} 
                        setGas={setGas}
                        arrayGas={arrayGas}
                        setArrayGas={setArrayGas}

                    />
                    <SelectorHome 
                        valueFamily={valueFamily}
                        valueMetros={valueMetros}
                        setValueFamily={setValueFamily} 
                        setValueMetros={setValueMetros} 
                        valueFamilyData={data?.valueFamilyData}
                        valueMetrosData={data?.valueMetrosData}
                    />
                </div>
            )}

            <div className="adicionales">
                <div>
                    <Permanence permanence={permanence} setPermanence={setPermanence} />
                </div>
                <div>
                    <AdditionalServices additionalServices={additionalServices} setAdditionalServices={setAdditionalServices} />
                </div>
            </div>

            {error !== "" &&
                <div className="alert alert-danger text-center" role="alert">
                    {error}
                </div>
            }
            <div className="form-enviar">
                <button className="btn btn-secondary" type="submit" onClick={onSubmit}>Comparar</button>
            </div>
        </div>
    )
}

export default LightGas;