import React from "react";
import RoundSlider from "../globals/plugins/RoundSlider";


function LightConsumption({ lightConsumption, setLightConsumption, consumoPunta, setConsumoPunta, consumoLlano, setConsumoLlano, consumoValle, setConsumoValle }) {

    const handleChangeLuz = (e) => {
        const valorLuz = e.value;
        setLightConsumption(valorLuz);
        setConsumoPunta(Math.round((28.6 / 100) * valorLuz));
        setConsumoLlano(Math.round((24.6 / 100) * valorLuz));
        setConsumoValle(Math.round((46.8 / 100) * valorLuz));

    }

    const handleChangePunta = (e) => {
        const valorPunta = e.target.value;
        setConsumoPunta(valorPunta);
        setLightConsumption(Number(valorPunta) + Number(consumoLlano) + Number(consumoValle));
    }

    const handleChangeLlano = (e) => {
        const valorLlano = e.target.value;
        setConsumoLlano(valorLlano);
        setLightConsumption(Number(consumoPunta) + Number(valorLlano) + Number(consumoValle));

    }

    const handleChangeValle = (e) => {
        const valorValle = e.target.value;
        setConsumoValle(valorValle);
        setLightConsumption(Number(consumoPunta) + Number(consumoLlano) + Number(valorValle));
    }

    return (
        <div className="my-4 row consumo-luz  border-negocio">
            <div className="col-md-3 col-12">
                <div className=" text-center" id="luz-potencia">
                    <h3>Luz</h3>
                    <RoundSlider
                        handleShape="dot"
                        sliderType="min-range"
                        width={7}
                        showTooltip={true}
                        value={lightConsumption}
                        min={99}
                        max={0}
                        svgMode={true}
                        pathColor="#eee"
                        borderWidth={0}
                        circleShape="custom-quarter"
                        startAngle={230}
                        lineCap="round"
                        radius={70}
                        startValue={0}
                        rangeColor="#e67e22"
                        update={handleChangeLuz}
                    />
                </div>
            </div>

            <div className="col-md-9 row">
                <div className="col-4 input-wrapper">
                    <label htmlFor="consumoPunta" className="form-label">Consumo Punta</label>
                    <input type="number" id="consumoPunta" value={consumoPunta} onChange={handleChangePunta} />
                    <div className="post_input">kW</div>
                </div>

                <div className="col-4 input-wrapper">
                    <label htmlFor="consumoLlano" className="form-label">Consumo Llano</label>
                    <input type="number" id="consumoLlano" value={consumoLlano} onChange={handleChangeLlano} />
                    <div className="post_input">kW</div>
                </div>

                <div className="col-4 input-wrapper">
                    <label htmlFor="consumoValle" className="form-label">Consumo Valle</label>
                    <input type="number" id="consumoValle" value={consumoValle} onChange={handleChangeValle} />
                    <div className="post_input">kW</div>
                </div>
            </div>





        </div>
    )
}

export default LightConsumption;