import React, { useState } from 'react';
import Select from 'react-select';
import Slider from '@mui/material/Slider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Tooltip } from '@mui/material';


const theme = createTheme({
    components: {
        MuiSlider: {
            styleOverrides: {
                root: {
                    color: 'white',
                    width: '80%',
                    margin: '0 auto',
                },
                valueLabelOpen: {
                    color: '#26435C',
                    backgroundColor: '#AFCEF3',
                },
                valueLabel: {
                    color: '#26435C',
                    backgroundColor: '#AFCEF3',
                },
                thumb: {
                    background: 'rgb(38,67,92) radial-gradient(circle, rgba(38,67,92,1) 27%, rgba(151,169,113,1) 27%) !important',
                },



            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#AFCEF3',
                    color: '#26435C',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                },
            },
        },
    },
});

export default function FilterMenu({ search, setSearch, items, setEnergyClass, setRangeCons, characteristics, order, setOrder }) {
    const energyClasses = items?.map((item) => item.energyClass)
    const uniqueEnergyClasses = [...new Set(energyClasses)]
    const filteredEnergyClasses = uniqueEnergyClasses.filter((energyClass) => energyClass !== "" && energyClass !== null)
    const sortedEnergyClasses = filteredEnergyClasses.sort((a, b) => {
        const energyClasses = ["APPP", "APP", "AP", "A", "B", "C", "D", "E", "F", "G"]
        return energyClasses.indexOf(a) - energyClasses.indexOf(b)
    });

    const arrayEnergyClasses = sortedEnergyClasses.map((energyClass) => {
        return { value: energyClass, label: energyClass.replace(/P/g, "+") }
    })

    const energyCons100 = items?.map((item) => item.energyCons100)
    const uniqueEnergyCons100 = [...new Set(energyCons100)]
    const filteredEnergyCons100 = uniqueEnergyCons100.filter((energyCons100) => energyCons100 !== "" && energyCons100 !== null)
    const valuesEnergyCons100 = filteredEnergyCons100.sort((a, b) => a - b)
    const maxEnergyCons100 = valuesEnergyCons100[valuesEnergyCons100.length - 1]
    const minEnergyCons100 = valuesEnergyCons100[0]
    const [value, setValue] = useState([minEnergyCons100, maxEnergyCons100]);

    const arrayCharacteristics = characteristics.map((characteristic) => {
        return { value: characteristic.name, label: characteristic.title }
    })
    arrayCharacteristics.unshift({ value: "supplierOrTrademark", label: "Marca" })

    const colourStylesEnergyClass = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? 0 : '1px solid hsl(0, 0%, 80%);',
            outline: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? '1px solid hsl(0, 0%, 80%);' : 0,
        }),
        option: (styles, { data }) => {
            let color = '';
            if (data.value === "APPP") color = '#1EC16B';
            if (data.value === "APP") color = '#00AB00';
            if (data.value === "AP") color = '#AAC902';
            if (data.value === "A") color = '#FFE446';
            if (data.value === "B") color = '#FFCD46';
            if (data.value === "C") color = '#FF7D03';
            if (data.value === "D") color = '#FE0000';
            if (data.value === "E") color = '#E20000';
            if (data.value === "F") color = '#C80000';
            if (data.value === "G") color = '#AE0000';


            return {
                ...styles,
                color: color,
                backgroundColor: 'white',
                ':hover': {
                    ...styles,
                    color: 'white',
                    backgroundColor: `${color}7F`,
                },
                ':active': {
                    ...styles[':active'],
                    color: 'white',
                    backgroundColor: `${color}CC`,
                },

            };
        },

        multiValue: (styles, { data }) => {
            let color = '';
            if (data.value === "APPP") color = '#1EC16B';
            if (data.value === "APP") color = '#00AB00';
            if (data.value === "AP") color = '#AAC902';
            if (data.value === "A") color = '#FFE446';
            if (data.value === "B") color = '#FFCD46';
            if (data.value === "C") color = '#FF7D03';
            if (data.value === "D") color = '#FE0000';
            if (data.value === "E") color = '#E20000';
            if (data.value === "F") color = '#C80000';
            if (data.value === "G") color = '#AE0000';
            return {
                ...styles,
                backgroundColor: color
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: 'white',
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: 'white',
            ':hover': {
                color: 'white',
            },
        }),
    };

    const stylesSelect = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? 0 : '1px solid hsl(0, 0%, 80%);',
            outline: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? '1px solid hsl(0, 0%, 80%);' : 0,
        }),
        option: (styles, { data }) => {
            return {
                ...styles,
                color: '#26435C',
                backgroundColor: 'white',
                ':hover': {
                    ...styles,
                    color: 'white',
                    backgroundColor: '#26435C',
                },
                ':active': {
                    ...styles[':active'],
                    color: 'white',
                    backgroundColor: '#26435C',
                },

            };
        }

    }




    function ValueLabelComponent(props) {
        const { children, value } = props;

        return (
            <ThemeProvider theme={theme}>
                <Tooltip enterTouchDelay={0} placement="bottom" title={value}  >
                    {children}
                </Tooltip>
            </ThemeProvider>
        );
    }




    const handleEnergyClassChange = (e) => {
        setEnergyClass(e)
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const handleChangeRange = (event, newValue) => {
        setValue(newValue);
        setRangeCons(newValue)
    }
    const handleOrderChange = (event) => {
        setOrder(event.value);
    };


    return (
        <>
            <div className="search col-md-3 col-12">
                <label htmlFor="search" className="visually-hidden">Buscar</label>
                <input className="form-control" id="search" type="text" placeholder="Buscar por marca - modelo" value={search} onChange={handleSearch} />
            </div>

            {characteristics.length > 0 && (
                <div className="search col-md-3 col-12">
                    <label htmlFor="characteristics" className="visually-hidden">Ordenar por</label>
                    <Select
                        id='characteristics'
                        name="characteristics"
                        options={arrayCharacteristics}
                        className="basic-single"
                        classNamePrefix="select"
                        onChange={handleOrderChange}
                        placeholder="Ordenar por"
                        isSearchable={true}
                        styles={stylesSelect}
                    />
                </div>

            )}

            {sortedEnergyClasses.length > 0 && (
                <div className="search col-md-3 col-12">
                    <label htmlFor="energyClass" className="visually-hidden">Clase de eficiencia energética</label>
                    <Select
                        id='energyClass'
                        isMulti
                        name="energyClass"
                        options={arrayEnergyClasses}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleEnergyClassChange}
                        placeholder="Filtrar por clase energética"
                        styles={colourStylesEnergyClass}
                    />
                </div>
            )}

            {minEnergyCons100 >= 0 && (
                <div className="search col-md-3 col-12 text-center mt--1 pt-0 pb-0">
                    <label htmlFor="energyCons100" className="fs-5 mb-2 form-text">Consumo energético (100 ciclos kWh)</label>
                    <ThemeProvider theme={theme}>
                        <Slider
                            value={value}
                            onChange={handleChangeRange}
                            valueLabelDisplay="auto"
                            min={minEnergyCons100}
                            max={maxEnergyCons100}
                            slots={{
                                valueLabel: ValueLabelComponent,
                            }}

                        />
                    </ThemeProvider>
                </div>
            )

            }






        </>
    )
}