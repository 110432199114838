import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css'

export default function Icons() {
    return(
        <div className="iconos">
            <p>Llano <i className="bi bi-dash-lg"></i></p>
            <p>Valle <i className="bi bi-caret-down-fill"></i></p>
            <p>Punta <i className="bi bi-caret-up-fill"></i></p>
        </div>

    )

}