import React, { useEffect } from "react";

function Pagination ({ postsPerPage, totalPosts, paginate, orden })  {
    const pageNumbers = [];

    const activePage = (e) => {
        const page = document.querySelectorAll(".page-link");
        page.forEach((item) => {
            item.classList.remove("active");
        });
        e.target.classList.add("active");
        window.scrollTo(0, 0)

    };

    useEffect(() => {
        const page = document.querySelectorAll(".page-link");
        page.forEach((item) => {
            item.classList.remove("active");
        });
        page[0].classList.add("active");
    }, [orden]);





  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="d-flex justify-content-center">
      <ul className='pagination '>
        {pageNumbers.map(number => (
          <li key={number} className="page-item">
            <button onClick={function(event) {
                paginate(number)
                activePage(event)
                
                }} className="page-link">
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Pagination