import React from 'react';
import Tooltip from '@mui/material/Tooltip';


export default function MeasurementTooltip({measurement}) {
    switch (measurement) {
        case "dB":
            return (
                <Tooltip title="Decibelios (dB) es la unidad de medida de la intensidad del sonido. Cuanto mayor sea el número de decibelios, mayor será la intensidad del sonido.">
                    <span>{measurement}</span>
                </Tooltip>
            )
        case "kWh":
            return (
                <Tooltip title="Kilowatt hora (kWh) es la unidad de medida de la energía eléctrica consumida por un aparato eléctrico. Cuanto mayor sea el número de kWh, mayor será el consumo de energía eléctrica.">
                    <span>{measurement}</span>
                </Tooltip>
            )
        case "L":
            return (
                <Tooltip title="Litros (L) es la unidad de medida del volumen de agua que puede contener un depósito. Cuanto mayor sea el número de litros, mayor será el volumen de agua que puede contener el depósito.">
                    <span>{measurement}</span>
                </Tooltip>
            )
        case "ps":
            return (

                <Tooltip title="Potencia (ps) es la unidad de medida de la fuerza que puede desarrollar un motor. Cuanto mayor sea el número de ps, mayor será la fuerza que puede desarrollar el motor.">
                    <span>{measurement}</span>
                </Tooltip>
            )
        case "W":
            return (
                <Tooltip title="Watt (W) es la unidad de medida de la potencia eléctrica consumida por un aparato eléctrico. Cuanto mayor sea el número de W, mayor será el consumo de energía eléctrica.">
                    <span>{measurement}</span>
                </Tooltip>
            )
        case "m2":
            return (
                <Tooltip title="Metros cuadrados (m2) es la unidad de medida de la superficie de un aparato. Cuanto mayor sea el número de m2, mayor será la superficie del aparato.">
                    <span>{measurement}</span>
                </Tooltip>
            )
        case "GWP":
            return (
                <Tooltip title="GWP (GWP) es la unidad de medida de la cantidad de gases de efecto invernadero que emite un aparato. Cuanto mayor sea el número de GWP, mayor será la cantidad de gases de efecto invernadero que emite el aparato.">
                    <span>{measurement}</span>
                </Tooltip>
            )

        default:
            return (
                <span>{measurement}</span>
            )
    }
}

            
        