import React, { useEffect, useState } from "react";
import RoundSlider from "../globals/plugins/RoundSlider";




function LightConsumption({ lightConsumption, setLightConsumption, p1, setP1, p2, setP2, p3, setP3, p4, setP4, p5, setP5, p6, setP6, potencia, setPotencia, potencia1, setPotencia1, potencia2, setPotencia2, potencia3, setPotencia3, potencia4, setPotencia4, potencia5, setPotencia5, potencia6, setPotencia6 }) {
    const [labelP1, setLabelP1] = useState(potencia > 16 ? "Potencia Punta" : "Potencia P1");
    const [labelP3, setLabelP3] = useState(potencia > 16 ? "Potencia Valle" : "Potencia P2");
    const [labelC1, setLabelC1] = useState(potencia > 16 ? "Consumo Punta" : "Consumo P1");
    const [labelC2, setLabelC2] = useState(potencia > 16 ? "Consumo Llano" : "Consumo P2");
    const [labelC3, setLabelC3] = useState(potencia > 16 ? "Consumo  Valle " : "Consumo P3");
    const [htmlForC1, setHtmlForC1] = useState(potencia > 16 ? "consumoPunta" : "c1");
    const [htmlForC2, setHtmlForC2] = useState(potencia > 16 ? "consumoLlano" : "c2");
    const [htmlForC3, setHtmlForC3] = useState(potencia > 16 ? "consumoValle" : "c3");

    const [className, setClassName] = useState(potencia > 16 ? "col-4 mb-3 input-wrapper" : "col-6 mb-3 input-wrapper");
    const [classNameConsumo, setClassNameConsumo] = useState(potencia > 16 ? "col-4 mb-3 input-wrapper" : "col-6 mb-3 input-wrapper");
    
    const P1 = 12.7
    const P2 = 14.0
    const P3 = 12.4
    const P4 = 14.3
    const P5 = 5.7
    const P6 = 40.9

    function handleChangeLuz(e) {
        const valorLuz = e.value;
        setLightConsumption(valorLuz)
        setP1(Math.round((P1 / 100) * valorLuz));
        setP2(Math.round((P2 / 100) * valorLuz));
        setP3(Math.round((P3 / 100) * valorLuz));
        setP4(Math.round((P4 / 100) * valorLuz));
        setP5(Math.round((P5 / 100) * valorLuz));
        setP6(Math.round((P6 / 100) * valorLuz));
    }

    const handleP1 = (e) => {
        const valor = e.target.value;
        setP1(valor);
        setLightConsumption(Number(valor) + Number(p2) + Number(p3) + Number(p4) + Number(p5) + Number(p6));
    }

    const handleP2 = (e) => {
        const valor = e.target.value;
        setP2(valor);
        setLightConsumption(Number(valor) + Number(p1) + Number(p3) + Number(p4) + Number(p5) + Number(p6))
    }

    const handleP3 = (e) => {
        const valor = e.target.value;
        setP3(valor);
        setLightConsumption(Number(valor) + Number(p1) + Number(p2) + Number(p4) + Number(p5) + Number(p6));
    }

    const handleP4 = (e) => {
        const valor = e.target.value;
        setP4(valor);
        setLightConsumption(Number(valor) + Number(p1) + Number(p2) + Number(p3) + Number(p5) + Number(p6));
    }

    const handleP5 = (e) => {
        const valor = e.target.value;
        setP5(valor);
        setLightConsumption(Number(valor) + Number(p1) + Number(p2) + Number(p3) + Number(p4) + Number(p6));
    }

    const handleP6 = (e) => {
        const valor = e.target.value;
        setP6(valor);
        setLightConsumption(Number(valor) + Number(p1) + Number(p2) + Number(p3) + Number(p4) + Number(p5));
    }

    const handleChangePotencia = (e) => {
        const valor = e.value;
        setPotencia(valor);
        setPotencia1(valor);
        setPotencia2(valor);
        setPotencia3(valor);
        setPotencia4(valor);
        setPotencia5(valor);
        setPotencia6(valor);
    }

    const handlePotencia1 = (e) => {
        const valor = e.target.value;
        if (valor <= 99.99) {
            setPotencia1(valor);
        } else {
            return
        }
    }

    const handlePotencia2 = (e) => {
        const valor = e.target.value;
        if (valor <= 99.99) {
            setPotencia2(valor);
        } else {
            return
        }
    }

    const handlePotencia3 = (e) => {
        const valor = e.target.value;
        if (valor <= 99.99) {
            setPotencia3(valor);
        } else {
            return
        }
    }

    const handlePotencia4 = (e) => {
        const valor = e.target.value;
        if (valor <= 99.99) {
            setPotencia4(valor);
        } else {
            return
        }
    }

    const handlePotencia5 = (e) => {
        const valor = e.target.value;
        if (valor <= 99.99) {
            setPotencia5(valor);
        } else {
            return
        }
    }

    const handlePotencia6 = (e) => {
        const valor = e.target.value;
        if (valor <= 99.99) {
            setPotencia6(valor);
        } else {
            return
        }
    }

    useEffect(() => {
        const P1 = document.getElementById('P1');
        const P2 = document.getElementById('P2');
        const P3 = document.getElementById('P3');
        const P4 = document.getElementById('P4');
        const P5 = document.getElementById('P5');
        const P6 = document.getElementById('P6');
        const C1 = document.getElementById('C1');
        const C2 = document.getElementById('C2');
        const C3 = document.getElementById('C3');
        const C4 = document.getElementById('C4');
        const C5 = document.getElementById('C5');
        const C6 = document.getElementById('C6');


        if (potencia < 16) {
            P1.style.display = 'block';
            P2.style.display = 'none';
            P3.style.display = 'block';
            P4.style.display = 'none';
            P5.style.display = 'none';
            P6.style.display = 'none';
            C1.style.display = 'block';
            C2.style.display = 'block';
            C3.style.display = 'block';
            C4.style.display = 'none';
            C5.style.display = 'none';
            C6.style.display = 'none';

            setLabelC1("Consumo Punta");
            setLabelC2("Consumo Llano");
            setLabelC3("Consumo Valle");
            setLabelP1("Potencia Punta");
            setLabelP3("Potencia Valle");

            setHtmlForC1("consumoPunta");
            setHtmlForC2("consumoLlano");
            setHtmlForC3("consumoValle");

            setClassName("col-6 mb-3 input-wrapper")
            setClassNameConsumo("col-4 mb-3 input-wrapper")



        } else {
            P1.style.display = 'block';
            P2.style.display = 'block';
            P3.style.display = 'block';
            P4.style.display = 'block';
            P5.style.display = 'block';
            P6.style.display = 'block';
            C1.style.display = 'block';
            C2.style.display = 'block';
            C3.style.display = 'block';
            C4.style.display = 'block';
            C5.style.display = 'block';
            C6.style.display = 'block';

            setLabelC1("P1");
            setLabelC2("P2");
            setLabelC3("P3");
            setLabelP1("P1");
            setLabelP3("P3");

            setHtmlForC1("c1");
            setHtmlForC2("c2");
            setHtmlForC3("c3");

            setClassName("col-2 mb-3 input-wrapper")
            setClassNameConsumo("col-2 mb-3 input-wrapper")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [potencia])



    return (
        <div>
            <div className="mt-1 row consumo-luz negocio  border-negocio">
                <div className="col-md-3 text-center" id="luz-potencia">
                    <h3>Potencia</h3>
                    <RoundSlider
                        handleShape="dot"
                        sliderType="min-range"
                        width={7}
                        showTooltip={true}
                        value={potencia}
                        min={99}
                        max={0}
                        svgMode={true}
                        pathColor="#eee"
                        borderWidth={0}
                        circleShape="custom-quarter"
                        startAngle={230}
                        lineCap="round"
                        radius={70}
                        startValue={0}
                        rangeColor="#e67e22"
                        update={handleChangePotencia}
                    />
                </div>
                <div className="col-md-9 row inputs">
                    <div className={className} id="P1">
                        <label htmlFor="potencia1" className="form-label"> {labelP1}</label>
                        <input type="number" id="potencia1" value={potencia1}
                            onChange={handlePotencia1} />

                        <div className="post_input_negocio">kW</div>
                    </div>
                    <div className="col-2 mb-3 input-wrapper" id="P2">
                        <label htmlFor="potencia2" className="form-label">P2</label>
                        <input type="number" id="p2" value={potencia2} onChange={handlePotencia2} />
                        <div className="post_input_negocio">kW</div>
                    </div>
                    <div className={className} id="P3">
                        <label htmlFor="potencia3" className="form-label">{labelP3}</label>
                        <input type="number" id="potencia3" value={potencia3} onChange={handlePotencia3} />
                        <div className="post_input_negocio">kW</div>
                    </div>
                    <div className="col-2 mb-3 input-wrapper" id="P4">
                        <label htmlFor="potencia4" className="form-label">P4</label>
                        <input type="number" id="potencia4" value={potencia4} onChange={handlePotencia4} />
                        <div className="post_input_negocio">kW</div>
                    </div>
                    <div className="col-2 mb-3 input-wrapper" id="P5">
                        <label htmlFor="potencia5" className="form-label">P5</label>
                        <input type="number" id="potencia5" value={potencia5} onChange={handlePotencia5} />
                        <div className="post_input_negocio">kW</div>
                    </div>
                    <div className="col-2 mb-3 input-wrapper" id="P6">
                        <label htmlFor="potencia6" className="form-label">P6</label>
                        <input type="number" id="potencia6" value={potencia6} onChange={handlePotencia6} />
                        <div className="post_input_negocio">kW</div>
                    </div>
                </div>


            </div>

            <div className="mt-1 row consumo-luz negocio  border-negocio">
                <div className="col-md-3 text-center mt-1" id="luz-consumo">
                    <h3>Consumo</h3>
                    <RoundSlider
                        handleShape="dot"
                        sliderType="min-range"
                        width={7}
                        showTooltip={true}
                        value={lightConsumption}
                        min={99}
                        max={0}
                        svgMode={true}
                        pathColor="#eee"
                        borderWidth={0}
                        circleShape="custom-quarter"
                        startAngle={230}
                        lineCap="round"
                        radius={70}
                        startValue={0}
                        rangeColor="#002a57"
                        update={handleChangeLuz}

                    />
                </div>
                <div className="col-md-9 row inputs">
                    <div className={classNameConsumo} id="C1" >
                        <label htmlFor={htmlForC1} className="form-label">{labelC1} </label>
                        <input type="number" id={htmlForC1} value={p1} onChange={handleP1} />
                        <div className="post_input_negocio">kW</div>
                    </div>
                    <div className={classNameConsumo} id="C2">
                        <label htmlFor={htmlForC2} className="form-label">{labelC2}</label>
                        <input type="number" id={htmlForC2} value={p2} onChange={handleP2} />
                        <div className="post_input_negocio">kW</div>
                    </div>
                    <div className={classNameConsumo} id="C3">
                        <label htmlFor={htmlForC3} className="form-label">{labelC3}</label>
                        <input type="number" id={htmlForC3} value={p3} onChange={handleP3} />
                        <div className="post_input_negocio">kW</div>
                    </div>
                    <div className="col-2 mb-3 input-wrapper" id="C4">
                        <label htmlFor="c4" className="form-label">P4</label>
                        <input type="number" id="c4" value={p4} onChange={handleP4} />
                        <div className="post_input_negocio">kW</div>
                    </div>
                    <div className="col-2 mb-3 input-wrapper" id="C5">
                        <label htmlFor="c5" className="form-label">P5</label>
                        <input type="number" id="c5" value={p5} onChange={handleP5} />
                        <div className="post_input_negocio">kW</div>
                    </div>
                    <div className="col-2 mb-3 input-wrapper" id="C6">
                        <label htmlFor="c6" className="form-label">P6</label>
                        <input type="number" id="c6" value={p6} onChange={handleP6} />
                        <div className="post_input_negocio">kW</div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default LightConsumption;