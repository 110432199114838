import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function SwitchTypeFee({typeFee, setTypeFee}) {
    const [label, setLabel] = React.useState("Tarifa Península e Islas");
    const handleChange = (event) => {
        if (event.target.checked) {
            setLabel("Tarifa Ceuta y Melilla");
        } else {
            setLabel("Tarifa Península e Islas");
        }
    }

    const onSwitchAction = (e) => {
        handleChange(e);
        setTypeFee(!typeFee);
    };


    const stylesSwitch = {
        "& .MuiSwitch-track": {
            backgroundColor: typeFee ? "var(--naranja) !important":"var(--azul) !important" 
        },
        "& .MuiSwitch-thumb": {
            background: "rgb(230, 126, 34) !important",
            // eslint-disable-next-line
            background: "radial-gradient(circle, rgba(230, 126, 34, 1) 27%, rgba(0, 42, 87, 1) 27%) !important"
        },
        "& .MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: "var(--naranja) !important"
        },
        "& .MuiFormControlLabel-root .MuiFormControlLabel-label": {
            fontFamily: "Jost, sans-serif !important"
        },
        "& .MuiTypography-root": {
            fontFamily: "Jost, sans-serif !important"
        },
    }


    return (
        <div className="adicionales m-1 mb-1">
            <div>
                <FormGroup>
                    <FormControlLabel control={<Switch color="primary" defaultValue={typeFee} />} label={label} onChange={onSwitchAction} sx={stylesSwitch}/>
                </FormGroup>
            </div>
        </div>
    );
}
