import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import RatingSolidFuelBoilersPackages from "../ratingEnergyEfficient/RatingSolidFuelBoilersPackages";
import { useNavigate } from "react-router-dom";


export default function SolidFuelBoilersPackages({item, filtered}) {
    const navigate = useNavigate();
    return (
        <div className="row align-items-baseline">
            <h1 className="text-center mb-4">Equipos de calderas de combustible sólido</h1>
            <section className="col-md-8 tabla-detalles">
                <table>
                    <thead>
                        <tr>
                            <th colSpan="2" className="fw-bold fs-4">Información General</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Índice de eficiencia energética (IEE)</td>
                            <td>{item?.energyEfficiencyIndex}</td>
                        </tr>
                        <tr>
                            <td>Índice de eficiencia energética del paquete</td>
                            <td>{item?.packageEnergyEfficiencyIndex}</td>
                        </tr>
                        <tr>
                            <td>Clase de control de temperatura</td> 
                            <td> {item?.temperatureControlContribution} </td>
                        </tr>
                        <tr>
                            <td>Tamaño del colector solar</td>
                            <td>{item?.solarCollectorSize} m2</td>
                        </tr>
                        <tr>
                            <td>Volumen del depósito solar</td>
                            <td>{item?.solarTankVolume} m3</td>
                        </tr>
                        <tr>
                            <td>Eficiencia del colector solar</td>
                            <td>{item?.collectorEfficiency} %</td>
                        </tr>
                        <tr>
                            <td>Clasificación del depósito solar</td>
                            <td>{item?.solarTankRating}</td>
                        </tr>
                        <tr>
                            <td>Contribución solar</td>
                            <td>{item?.solarContribution}</td>
                        </tr>

                        <tr>
                            <td>Valor de 294/(11 x Pr)</td>
                            <td>{item?.mathExprIII}</td>
                        </tr>

                        <tr>
                            <td>Valor de 115/(11 x Pr)</td>
                            <td>{item?.mathExprIV}</td>
                        </tr>


                        
                    </tbody>
                </table>
            </section>
            <section className="col-md-4">
                <RatingSolidFuelBoilersPackages item={item} />
            </section>
            <button className="col-12 mt-5 btn btn-primary" onClick={() => navigate(`/electrodomesticos/comparador`, {state: {item, filtered}})}> Comparar </button>

        </div>
    )
}