import React from "react";

import cocinaGas from "../../../assets/img/cocina_gas.svg";
import horno from "../../../assets/img/horno.svg";
import cocina from "../../../assets/img/cocina.svg";
import caldera from "../../../assets/img/caldera.svg";
import radiador from "../../../assets/img/radiador.svg";
import chimeneas from "../../../assets/img/chimeneas.svg";

function GasAppliances({gas, setGas, arrayGas, setArrayGas}) {
    let array = [
        {
            id: 9,
            name: "Cocina",
            icon: cocinaGas,
            kwh:125
        },
        {
            id: 10,
            name: "Horno",
            icon: horno,
            kwh:100
        },
        {
            id: 11,
            name: "Cocina",
            icon: cocina,
            kwh:120
        },
        {
            id: 12,
            name: "Caldera",
            icon: caldera,
            kwh:120
        },
        {
            id: 13,
            name: "Radiador",
            icon: radiador,
            kwh:120
        },
        {
            id: 14,
            name: "Chimeneas",
            icon: chimeneas,
            kwh:120
        }
    ];

    const isChecked = (id) => {
        if (arrayGas !== undefined && arrayGas.length > 0) {
            return arrayGas.includes(id);
        } else {
            return false;
        }
    }

    function handleChange(e) {
        if (e.target.checked) {
            setGas(gas + parseInt(e.target.value));
            if (arrayGas !== undefined) {
                setArrayGas([...arrayGas, parseInt(e.target.id)])
            } else if (arrayGas === undefined) {
                setArrayGas([parseInt(e.target.id)])
            }
        } else {
            setArrayGas(arrayGas.filter(item => item !== parseInt(e.target.id)))
            setGas(gas - parseInt(e.target.value));
        }

    }

    return (
        <div className="mb-1 mt-2 gas">
            <h3 className="h5 text-center">Electrodomésticos Gas</h3>
            <fieldset className="comparador-electrodomestico">
                {array.map((item, index) => (
                    <div className="checkbox" key={index}>
                        <label className="comparador-electrodomestico-wrapper">
                            <input type="checkbox"
                                   name="electrodomesticos[]"
                                   className="comparador-electrodomestico-input"
                                   id={item.id}
                                   value={item.kwh}
                                   onChange={handleChange}
                                   defaultChecked={isChecked(item.id)}
                                  />
                            <span className="comparador-electrodomestico-tile">
                                <span className="comparador-electrodomestico-icon">
                                    <img src={item.icon} alt={item.name} />
                                </span>
                                <span className="comparador-electrodomestico-label">{item.name}</span>
                            </span>
                        </label>
                    </div>
                ))}

            </fieldset>
        </div>
    )
}

export default GasAppliances;