import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import RatingWasherDryes from "../ratingEnergyEfficient/RatingWasherDryers";
import { useNavigate } from "react-router-dom";


export default function WasherDryers({item, filtered}) {
    const navigate = useNavigate();
    return (
        <div className="row align-items-baseline">
            <h1 className="text-center mb-4">Lavadoras secadoras</h1>
             <section className="col-md-8 tabla-detalles">
                <table>
                    <thead>
                        <tr>
                            <th colSpan="2" className="fw-bold fs-4">Información General</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Dimensiones totales</td>
                            <td>{item?.dimensionHeight} (altura) x {item?.dimensionWidth} (anchura) x {item?.dimensionDepth} (profundidad) cm </td>
                        </tr>
                        <tr className="washerDryers mt-5">
                            <td>{"           "}</td>
                            <td className="fw-bold">Cilo de lavado</td>
                            <td className="fw-bold">Cilo Completo</td>
                        </tr>
                        <tr className="washerDryers">
                            <td>Capacidad asignada</td>
                            <td>{item?.ratedCapacityWash} </td>
                            <td>{item?.ratedCapacityWashAndDry} kg</td>
                        </tr>
                        <tr className="washerDryers">
                            <td>Índice de eficiencia energética (IEE)</td>
                            <td>{item?.energyEfficiencyIndexWash} </td>
                            <td>{item?.energyEfficiencyIndexWashAndDry} </td>
                        </tr>
                        <tr className="washerDryers">
                            <td>Consumo de energía por ciclo</td>
                            <td>{item?.energyEfficiencyIndexWash}</td>
                            <td>{item?.energyEfficiencyIndexWashAndDry} kWh</td>
                        </tr>
                        <tr className="washerDryers">
                            <td>Consumo de energía por ciclo (por 100 ciclos)</td>
                            <td>{item?.energyConsumption100Wash}</td>
                            <td>{item?.energyConsumption100WashAndDry} kWh</td>
                        </tr>
                        <tr className="washerDryers">
                            <td>Consumo de agua</td>
                            <td>{item?.waterConsumptionWash}</td>
                            <td>{item?.waterConsumptionWashAndDry} L</td>
                        </tr>
                        <tr className="washerDryers">
                            <td>Medio lavado a temperatura máxima</td>
                            <td>{item?.maxTempHalfWash}</td>
                            <td>{item?.maxTempHalfWashAndDry} °C</td>
                        </tr>
                        <tr className="washerDryers">    
                            <td>Temperatura máxima dentro de la ropa tratada (mitad)</td>
                            <td>{item?.maxTempRatedWash}</td>
                            <td>{item?.maxTempRatedWashAndDry} °C</td>
                        </tr>
                        <tr className="washerDryers">
                            <td>Índice de eficiencia de lavado</td>
                            <td>{item?.washingEfficiencyIndexWash}</td>
                            <td>{item?.washingEfficiencyIndexWashAndDry}</td>
                        </tr>
                        <tr className="washerDryers">
                            <td>Eficacia de aclarado</td>
                            <td>{item?.rinsingEffectivenessWash}</td>
                            <td>{item?.rinsingEffectivenessWashAndDry} g/kg</td>
                        </tr>
                        <tr>
                            <td>Contenido de humedad residual ponderado</td>
                            <td>{item?.moisture} %</td>
                        </tr>
                        <tr>
                            <td>Clase de eficiencia de centrifugado</td>
                            <td>{item?.spinClass} (A-G)</td>
                        </tr>
                        <tr>
                            <td>Clase de ruido acústico aéreo</td>
                            <td>{item?.noiseClass} (A-G)</td>
                        </tr>
                        <tr>
                            <td>Ruido acústico aéreo</td>
                            <td>{item?.noise} dB(A) re 1 pW</td>
                        </tr>
                    </tbody>
                </table>

            </section>

            <section className="col-md-4">
                <RatingWasherDryes item={item}/>
            </section>
            <button className="col-12 mt-5 btn btn-primary" onClick={() => navigate(`/electrodomesticos/comparador`, {state: {item, filtered}})}> Comparar </button>
        </div>
        
    )
}