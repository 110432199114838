import React, { useEffect, useState } from 'react';
import Characteristics from './Characteristics.jsx';
import LightDate from './LightDate.jsx';
import Chart from './Chart.jsx';
import Icons from './Icons.jsx';
import SwitchTypeFee from './SwitchTypeFee.jsx';
import * as LightPriceService from '../../services/lightPrice.service';
import Ranges from './Ranges.jsx'

export default function LightPrice() {
  const [typeFee, setTypeFee] = useState(false);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [data, setData] = useState([]);
  const [minDayPrice, setMinDayPrice] = useState(0);
  const [maxDayPrice, setMaxDayPrice] = useState(0);
  const [betweenRange, setBetweenRange] = useState(0);
  const [dayPrices, setDayPrices] = useState([]);


  useEffect(() => {
    let fee = typeFee ? 'cym' : 'pcb';
    let dateFormated = date.split('-').reverse().join('-');
    getPrices(dateFormated, fee);
    //eslint-disable-next-line
  }, [typeFee, date]);


  function getPrices(date, fee) {
    LightPriceService.getLightPrice(date, fee)
      .then((response) => {
        setData(response);
      })
  }

  useEffect(() => {
    let dayPricesData = [];
    let fee = typeFee ? 'cym' : 'pcb';
    if (data.length !== 0) {
      data.forEach((item) => {
        dayPricesData.push(
          {
            price: parseFloat(item?.[fee].replace(',', '.')),
            day: item.dia.split('-').join('/'),
            time: item.hora
          }
        );
      })
      setDayPrices(dayPricesData);
      let minDayPrice = dayPricesData[0].price;
      let maxDayPrice = dayPricesData[0].price;
      dayPricesData.forEach((item) => {
        if (item.price < minDayPrice) {
          minDayPrice = item.price;
        }
        if (item.price > maxDayPrice) {
          maxDayPrice = item.price;
        }
      })
      setMinDayPrice(minDayPrice);
      setMaxDayPrice(maxDayPrice);
      setBetweenRange((maxDayPrice - minDayPrice) / 3);
    } else {
      setDayPrices([]);
      setMinDayPrice(0)
      setMaxDayPrice(0)
      setBetweenRange(0)

    }
    //eslint-disable-next-line
  }, [data]);

  return (
    <>
      <section className='container'>
        <div className='row d-flex justify-content-center'>
          <div className='col-md-3 col-12'>
            <SwitchTypeFee typeFee={typeFee} setTypeFee={setTypeFee} />
          </div>
          <div className='col-md-3 col-12'>
            <LightDate date={date} setDate={setDate} />
          </div>
        </div>
      </section>
      <Icons />
      <Chart dayPrices={dayPrices} minDayPrice={minDayPrice} maxDayPrice={maxDayPrice} betweenRange={betweenRange} />
      {dayPrices.length !== 0 &&
        <>
          <Ranges dayPrices={dayPrices} />
          <Characteristics dayPrices={dayPrices} minDayPrice={minDayPrice} maxDayPrice={maxDayPrice} />
        </>
      }
    </>
  );
}
