import React from "react";
import rating from "../../../../assets/img/rating.svg";
import "bootstrap-icons/font/bootstrap-icons.css";
import ImgEnergyClass from "./ImgEnergyClass";
import Tooltip from '@mui/material/Tooltip';

export default function RatingWasherDryers({item}) {
    return (
        <section className="detalle container">
           <div className="marca">
                <Tooltip title="Marca" placement="top">
                    <h1>{item?.supplierOrTrademark}</h1>
                </Tooltip>
                <Tooltip title="Modelo" placement="top">
                    <p>{item?.modelIdentifier}</p>
                </Tooltip>
            </div>
            <div className="row">
                <div className="col-12 energyClass justify-content-between">
                    <Tooltip title="Clases de eficiencia energética" placement="top" className="clase-energetica-tooltip">
                        <img src={rating} alt="rating" />
                    </Tooltip>
                    <ImgEnergyClass energyClass={item?.energyClass} />
                </div>
                <div className="col-md-6 col-12">
                    <hr/>
                    <Tooltip title="Consumo de lavado y secado por 100 ciclos" placement="top">
                        <div>
                            <i className="bi bi-droplet"></i>
                            <i className="bi bi-water me-2"></i>
                            <span className="fw-bold fs-5" >{item?.energyConsumption100WashAndDry}  </span>
                            kW/h <span className="per100cyclesIcon"></span>
                        </div>
                    </Tooltip>
                    <hr/>
                </div>
                <div className="col-md-6 col-12">
                    <hr/>
                    <Tooltip title="Consumo de lavado por 100 ciclos" placement="top">
                        <div>
                            <i className="bi bi-droplet"></i>
                            <span className="fw-bold fs-5" >{item?.energyConsumption100Wash}  </span>
                            kW/h <span className="per100cyclesIcon"></span>
                        </div>
                    </Tooltip>
                    <hr/>
                </div>

                <div className="col-12 text-center d-flex justify-content-center">
                    <Tooltip title="Capacidad nominal de lavado y secado" placement="top">
                        <p> <span className="fw-bold fs-5" >{item?.ratedCapacityWashAndDry}</span>  kg</p>
                    </Tooltip>
                    <span className="ratedCapacityIcon mx-3"></span>
                    <Tooltip title="Capacidad nominal de lavado" placement="top">
                        <p> <span className="fw-bold fs-5" >{item?.ratedCapacityWash}</span>  kg</p>
                    </Tooltip>
                </div>

                <div className="col-12 text-center d-flex justify-content-center">
                    <Tooltip title="Consumo de agua de lavado y secado" placement="top">
                        <p> <span className="fw-bold fs-5" >{item?.waterConsumptionWashAndDry}</span>  L</p>
                    </Tooltip>
                    <span className="waterConsumptionIcon mx-3"></span>
                    <Tooltip title="Consumo de agua de lavado" placement="top">
                        <p> <span className="fw-bold fs-5" >{item?.waterConsumptionWash}</span>  L</p>
                    </Tooltip>
                </div>
                <hr/>

                <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                    <Tooltip title="Clase de ruido acústico aéreo" placement="top">
                        <span className="noiseClass"></span>
                    </Tooltip>
                    {item?.noiseClass === "APPP" &&
                        <p><span className="fw-bold fs-5" >A+++</span> B C D E F G  </p>
                    }  
                    {item?.noiseClass === "APP" &&
                        <p><span className="fw-bold fs-5" > A++ </span> B C D E F G </p>
                    }   
                    {item?.noiseClass === "AP" &&
                        <p><span className="fw-bold fs-5" > A+ </span> B C D E F G </p>
                    } 
                    {item?.noiseClass === "A" &&
                        <p><span className="fw-bold fs-5" >A</span> B C D E F G</p>
                    }   
                    {item?.noiseClass === "B" &&
                        <p> A <span className="fw-bold fs-5" >B</span> C D E F G </p>
                    }
                    {item?.noiseClass === "C" &&
                        <p> A B <span className="fw-bold fs-5" >C</span> D E F G</p>
                    }
                    {item?.noiseClass === "D" &&
                        <p> A B C <span className="fw-bold fs-5" >D E F G  </span></p>
                    }
                    {item?.noiseClass === "E" &&
                        <p> A B C D <span className="fw-bold fs-5" >E F G</span></p>
                    }
                    {item?.noiseClass === "F" &&
                        <p> A B C D E <span className="fw-bold fs-5" >F G</span></p>
                    }
                    {item?.noiseClass === "G" &&
                        <p> A B C D E F <span className="fw-bold fs-5" >G </span></p>
                    }
                </div>

                <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                    <Tooltip title="Clase de eficiencia de centrifugado" placement="top">
                        <span className="spinClass"></span>
                    </Tooltip>
                    {item?.spinClass === "APPP" &&
                        <p><span className="fw-bold fs-5" >A+++</span> B C D E F G  </p>
                    }  
                    {item?.spinClass === "APP" &&
                        <p><span className="fw-bold fs-5" > A++ </span> B C D E F G </p>
                    }   
                    {item?.spinClass === "AP" &&
                        <p><span className="fw-bold fs-5" > A+ </span> B C D E F G </p>
                    } 
                    {item?.spinClass === "A" &&
                        <p><span className="fw-bold fs-5" >A</span> B C D E F G</p>
                    }   
                    {item?.spinClass === "B" &&
                        <p> A <span className="fw-bold fs-5" >B</span> C D E F G </p>
                    }
                    {item?.spinClass === "C" &&
                        <p> A B <span className="fw-bold fs-5" >C</span> D E F G</p>
                    }
                    {item?.spinClass === "D" &&
                        <p> A B C <span className="fw-bold fs-5" >D E F G  </span></p>
                    }
                    {item?.spinClass === "E" &&
                        <p> A B C D <span className="fw-bold fs-5" >E F G</span></p>
                    }
                    {item?.spinClass === "F" &&
                        <p> A B C D E <span className="fw-bold fs-5" >F G</span></p>
                    }
                    {item?.spinClass === "G" &&
                        <p> A B C D E F <span className="fw-bold fs-5" >G </span></p>
                    }
                </div>
            </div>
        </section>
        
    )
}