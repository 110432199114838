import React, { useEffect, useState } from 'react';
import SideBar from '../globals/results/SideBar';

import { CircularProgress } from "@mui/material";

export default function SpaceHeaterTemperatureControl() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const importAll = (r) => {
        let items = [];
        // eslint-disable-next-line array-callback-return
        r.keys().map((item, index) => { items[index] = r(item); });
        return items;
    }

    const json = importAll(require.context('../../../data/homeAppliances/spaceheatertemperaturecontrol', false, /\.(json)$/));
    useEffect(() => {
        const filteredItems = [];
        json.forEach((item) => {
            item?.hits?.forEach((hit) => {
                const isDuplicate = filteredItems.some((filteredItem) => (
                    filteredItem.modelIdentifier === hit.modelIdentifier &&
                    filteredItem.supplierOrTrademark === hit.supplierOrTrademark
                ));
                if (!isDuplicate) {
                    filteredItems.push(hit);
                }
            });
        });
        setItems(filteredItems);
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (items.length === 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [items]);


    return (
        <main className='container homeAppliences'>
            <h1>Compare controles de temperatura para aparatos de calefacción de espacios</h1>
            {loading &&
                <div className='loading'>
                    <p className='h4'>Estamos buscando todos los electrodomésticos</p>
                    <CircularProgress />
                </div>
            }
            {!loading &&
                <SideBar items={items} productGroup={'spaceheatertemperaturecontrol'}/>
            }
        </main>
    )
}
