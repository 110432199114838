import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import ImgBrand from './ImgBrand';
import MeasurementTooltip from './MeasurementTooltip';
import A from '../../../../../assets/img/energyClass/a.svg';
import APPP from '../../../../../assets/img/energyClass/appp.svg';
import APP from '../../../../../assets/img/energyClass/app.svg';
import AP from '../../../../../assets/img/energyClass/ap.svg';
import B from '../../../../../assets/img/energyClass/b.svg';
import C from '../../../../../assets/img/energyClass/c.svg';
import D from '../../../../../assets/img/energyClass/d.svg';
import E from '../../../../../assets/img/energyClass/e.svg';
import F from '../../../../../assets/img/energyClass/f.svg';
import G from '../../../../../assets/img/energyClass/g.svg';

export default function ResultsListMobile({ item, filtered, arrayCharacteristics, item1, setItem1, item2, setItem2, selectedItems, setSelectedItems, productGroup }) {
    const navigate = useNavigate();

    function handleItem() {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((itemSelected) => itemSelected !== item))
            if (item1 === item) {
                setItem1(null)
            } else if (item2 === item) {
                setItem2(null)
            }
        } else {
            if (selectedItems.length < 2) {
                setSelectedItems([...selectedItems, item])
                if (item1 === null) {
                    setItem1(item)
                } else if (item2 === null) {
                    setItem2(item)
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Solo puedes comparar 4 productos',
                    confirmButtonColor: '#013D0B',
                })
            }
        }
    }

    useEffect(() => {
        if (selectedItems.length === 2) {
            Swal.fire({
                title: 'Producto seleccionado',
                text: `Has seleccionado ${selectedItems.length === 1 ? 'un producto' : selectedItems.length + ' productos'} para comparar.`,
                icon: 'success',
                confirmButtonColor: '#013D0B',
                confirmButtonText: 'Ir al comparador',
            }).then((result) => {
                if (result.isConfirmed) {
                    let item1 = null
                    let item2 = null
                    selectedItems.forEach((item, i) => {
                        if (i === 0) {
                            item1 = item
                        } else if (i === 1) {
                            item2 = item
                        }
                    })
                    navigate('/electrodomesticos/comparador', { state: { item1, item2, filtered, productGroup } })
                }
            });
        } else if (selectedItems.length > 0) {
            Swal.fire({
                title: 'Producto seleccionado',
                text: `Has seleccionado ${selectedItems.length === 1 ? 'un producto' : selectedItems.length + ' productos'} para comparar.`,
                icon: 'success',
                confirmButtonColor: '#013D0B',
                confirmButtonText: 'Cerrar',
            });
        }
        // eslint-disable-next-line
    }, [selectedItems])

    return (
        <div className="d-flex justify-content-center row">
            <div className="col-md-12">
                <div className="row p-2 bg-white border rounded align-items-center">
                    <div className="col-md-2 mt-1">
                        <ImgBrand className="img-fluid img-responsive" brand={item?.organisation?.organisationTitle} />
                    </div>

                    <div className="col-md-7 mt-1">
                        <h2>{item?.supplierOrTrademark}</h2>
                        <p className="comercializadora">{item?.modelIdentifier}</p>
                        <div className="row align-items-center">
                            <div className="col-8">
                                {arrayCharacteristics.map((characteristic, i) => {
                                    if (characteristic.value === "energyClass") {
                                        return null
                                    } else {
                                        return <p key={i}>
                                            <span>{characteristic.label}: </span>
                                            <span className="fw-bold">{item[characteristic.value]}{"   "}</span>
                                            <MeasurementTooltip measurement={characteristic.measurement} />
                                        </p>
                                    }
                                })}
                            </div>
                            <div className="col-4">
                                {arrayCharacteristics.map((characteristic, i) => {
                                    if (characteristic.value === "energyClass") {
                                        switch (item[characteristic.value]) {
                                            case "APPP":
                                                return <img key={i} className="classEnergy" src={APPP} alt="Clase energética A+++" />
                                            case "APP":
                                                return <img key={i} className="classEnergy" src={APP} alt="Clase energética A++" />
                                            case "AP":
                                                return <img key={i} className="classEnergy" src={AP} alt="Clase energética A+" />
                                            case "A":
                                                return <img key={i} className="classEnergy" src={A} alt="Clase energética A" />
                                            case "B":
                                                return <img key={i} className="classEnergy" src={B} alt="Clase energética B" />
                                            case "C":
                                                return <img key={i} className="classEnergy" src={C} alt="Clase energética C" />
                                            case "D":
                                                return <img key={i} className="classEnergy" src={D} alt="Clase energética D" />
                                            case "E":
                                                return <img key={i} className="classEnergy" src={E} alt="Clase energética E" />
                                            case "F":
                                                return <img key={i} className="classEnergy" src={F} alt="Clase energética F" />
                                            case "G":
                                                return <img key={i} className="classEnergy" src={G} alt="Clase energética G" />
                                            default:
                                                return <p key={i}> {item[characteristic.value]} </p>

                                        }
                                    } else {
                                        return null
                                    }

                                })}

                            </div>


                        </div>
                    </div>
                    <div className="align-items-center align-content-center col-md-3 mt-1">
                        <div className='d-flex flex-column justify-content-center botones-star'>
                            <button type='button' className='btn btn-primary' onClick={() => navigate(`/electrodomesticos/detalle`, { state: { item, filtered } })}> Ver Detalles </button>
                            <button type='button' onClick={() => handleItem()} className={selectedItems.includes(item) ? 'btn btn-grey' : 'btn btn-secondary'}>
                                {selectedItems.includes(item) ? 'Deseleccionar' : 'Seleccionar'}
                            </button>
                        </div>
                    </div>



                </div>
            </div>
        </div>

    )


}