import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import Tooltip from '@mui/material/Tooltip';


export default function SpaceHeaterTemperatureControl({item}) {
    return (
        <section className="detalle container">
            <div className="marca">
                <Tooltip title="Marca" placement="top">
                    <h1>{item?.supplierOrTrademark}</h1>
                </Tooltip>
                <Tooltip title="Modelo" placement="top">
                    <p>{item?.modelIdentifier}</p>
                </Tooltip>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <hr/>
                    <p>Contribución del control de temperatura</p> 
                    <p><span className="fw-bold fs-5" >{item?.temperatureControlClass}  </span> </p> 
                </div>
            </div>
            
        </section>
    )
}